var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit } from 'vue-property-decorator';
import SearchHistoryDrawer from '@/modules/search/components/SearchHistoryDrawer.vue';
import { getSearchDrawerWidth, showNotify } from '@/shared/utils/helpers';
import { connect } from '@/overmind';
import { SearchHistoryStatus } from '@/overmind/search/state';
let SearchHistoryDrawerContainer = class SearchHistoryDrawerContainer extends Vue {
    remountHeaderKey() { }
    getWidth() {
        return getSearchDrawerWidth();
    }
    closeHistoryDrawer() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowHistory(false);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
    }
    async saveHistory(infoHistory) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowMakeDecision(true);
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        // @ts-ignore TS2349: property inexistent
        if (this.currentId == '') {
            showNotify('Antes de salvar uma nova busca, certifique-se de já ter realizado uma busca!!', 'warning');
        }
        else if (
        //@ts-ignore
        !!infoHistory.nameHistory &&
            //@ts-ignore
            typeof infoHistory.nameHistory === 'string') {
            // @ts-ignore TS2349: property inexistent
            const newSearchHistory = {
                //@ts-ignore TS2339: Property 'nameHistory' does not exist on type 'object'.
                name: infoHistory.nameHistory,
                // @ts-ignore TS2349: property inexistent
                totalResults: parseInt(this.summary.totalResult),
                // @ts-ignore TS2349: property inexistent
                totalEmployees: parseInt(
                //@ts-ignore
                this.advancedGraphics.totalDecisionMakers),
                // @ts-ignore TS2349: property inexistent
                searchId: this.currentId,
                //@ts-ignore
                selected_filters: infoHistory.totalSelcted,
            };
            // @ts-ignore TS2349: property inexistent
            await this.actionsSearch.searchHistory.createSearchHistory(newSearchHistory);
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.setSearchFromHistory(true);
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.setShowMakeDecision(false);
        }
    }
    async getSearchHistory() {
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searchHistory.getSearchHistory();
    }
    async deleteSearchHistory(id) {
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searchHistory.deleteSearchHistory(id);
        this.remountHeaderKey();
    }
    async getSearch(searchId) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchFromHistory(true);
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.getSearch({ id: searchId });
        this.$router
            .push({
            name: 'searchId',
            params: { searchId: searchId },
        })
            .catch(err => err);
    }
    hideCompanyDrawer(value) {
        const drawer = document.getElementsByClassName('search-company-detail--drawer')[0];
        if (drawer) {
            if (value) {
                drawer.setAttribute('style', 'display: none;');
            }
            else {
                drawer.setAttribute('style', 'display: flex;');
            }
        }
    }
    async mounted() {
        this.hideCompanyDrawer(true);
        // @ts-ignore TS2349: property inexistent
        if (this.showHistory) {
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
            // @ts-ignore TS2349: property inexistent
            await this.actionsSearch.searchHistory.getSearchHistory();
        }
    }
    beforeDestroy() {
        this.hideCompanyDrawer(false);
    }
};
__decorate([
    Emit('remountHeaderKey')
], SearchHistoryDrawerContainer.prototype, "remountHeaderKey", null);
SearchHistoryDrawerContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        showHistory: state.search.showHistory,
        actionsSearch: actions.search,
        currentId: state.search.currentSearchId,
        summary: state.search.summary,
        status: state.search.searchHistoryStatus,
        actionsFilters: actions.filters,
        stateSearch: state.search,
        advancedGraphics: state.search.advancedGraphics,
        histories: state.search.histories,
    }), {
        name: 'SearchHistoryDrawerContainer',
        components: {
            SearchHistoryDrawer,
        },
    }))
], SearchHistoryDrawerContainer);
export default SearchHistoryDrawerContainer;
