var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
let UseOfTechnology = class UseOfTechnology extends Vue {
    constructor() {
        super(...arguments);
        this.keys = json(this.useOfTechnology.map(item => this.handlerKey(item)));
        this.values = json(this.useOfTechnology.map(item => {
            return {
                name: this.handlerKey(item),
                y: item.y,
            };
        }));
        this.chartOptions = {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },
            xAxis: {
                title: '',
                categories: json(this.keys),
            },
            yAxis: {
                title: null,
                labels: {
                    formatter: function () {
                        // @ts-ignore TS2349: property inexistent
                        return this.value + '%';
                    },
                },
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    pointPadding: -0.2,
                },
            },
            tooltip: {
                headerFormat: '<span>{series.name}</span>',
                pointFormat: '<span style="font-weight: bold">{point.name}</span>: {point.y}%',
            },
            series: [
                {
                    name: '',
                    // @ts-ignore TS2349: property inexistent
                    data: json(this.values),
                    color: '#339FFF',
                },
            ],
            credits: false,
        };
    }
    useOfTechnologyChanged(current) {
        this.chartOptions.xAxis.categories = json(current).map(item => this.handlerKey(item));
        this.chartOptions.series[0].data = json(current);
    }
    handlerKey(value) {
        const newKey = value.name[0].toUpperCase() + value.name.slice(1).toLowerCase();
        return newKey.replace(/_/g, ' ');
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], UseOfTechnology.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], UseOfTechnology.prototype, "useOfTechnology", void 0);
__decorate([
    Prop({ default: 1 })
], UseOfTechnology.prototype, "page", void 0);
__decorate([
    Watch('useOfTechnology')
], UseOfTechnology.prototype, "useOfTechnologyChanged", null);
UseOfTechnology = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], UseOfTechnology);
export default UseOfTechnology;
