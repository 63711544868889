var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { json } from 'overmind';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
let SearchExportModalFilters = class SearchExportModalFilters extends Vue {
    constructor() {
        super(...arguments);
        this.toggle = json(this.exclusiveOption.filter.isSelected);
        this.toggle2 = false;
    }
    toggleExclusiveOption() {
        return this.exclusiveOption.index;
    }
    toggleExclusiveOptionTwo() {
        return this.toggle2;
    }
    onExclusiveOptionChange(currVal) {
        this.toggle = json(currVal.filter.isSelected);
    }
    getExportedCompanies() {
        return this.exportedCompanies === 1
            ? `${this.exportedCompanies} Empresa`
            : `${this.exportedCompanies} Empresas`;
    }
};
__decorate([
    Prop({ default: '' })
], SearchExportModalFilters.prototype, "exportType", void 0);
__decorate([
    Prop({
        default: () => { },
    })
], SearchExportModalFilters.prototype, "exclusiveOption", void 0);
__decorate([
    Prop({ default: 0 })
], SearchExportModalFilters.prototype, "exportedCompanies", void 0);
__decorate([
    Prop({ default: false })
], SearchExportModalFilters.prototype, "showAutoPlayButton", void 0);
__decorate([
    Emit('toggleExclusiveOption')
], SearchExportModalFilters.prototype, "toggleExclusiveOption", null);
__decorate([
    Emit('toggleExclusiveOptionTwo')
], SearchExportModalFilters.prototype, "toggleExclusiveOptionTwo", null);
__decorate([
    Watch('exclusiveOption')
], SearchExportModalFilters.prototype, "onExclusiveOptionChange", null);
SearchExportModalFilters = __decorate([
    Component
], SearchExportModalFilters);
export default SearchExportModalFilters;
