var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { json } from 'overmind';
import Slider from 'components/Slider.vue';
import Input from 'components/Input.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { differenceBetweenCnpjAndCompanyTooltip } from '@/shared/utils/helpers';
let Employees = class Employees extends Vue {
    constructor() {
        super(...arguments);
        this.selectedInputValue = json(this.selectedInputRange);
        this.sliderValue = json(this.stateSliderFiltersValues.employees);
        this.radioButtonValue = this.radioButton;
        this.showOptionInput = false;
        this.inputValueInitial = null;
        this.inputValueEnd = null;
        this.hasError = false;
    }
    setInputValueInitial(value) {
        this.inputValueInitial = Number(value) || null;
        if (this.$refs.employeeInputInitial) {
            //@ts-ignore
            this.$refs.employeeInputInitial.$refs.qinput.validate();
            //@ts-ignore
            this.$refs.employeeInputEnd.$refs.qinput.validate();
        }
        this.selectedInputValue[this.radioButton].value_start =
            this.inputValueInitial;
        this.selectedInputValue[this.radioButton].value_end = this.inputValueEnd;
        if (this.inputValueInitial <= this.inputValueEnd) {
            this.hasError = false;
            this.$emit('setInputRange', {
                value_start: this.inputValueInitial,
                value_end: this.inputValueEnd,
                queryField: this.radioButton,
            });
        }
        else {
            this.hasError = true;
        }
    }
    setInputValueEnd(value) {
        this.inputValueEnd = Number(value) || null;
        if (this.$refs.employeeInputEnd) {
            //@ts-ignore
            this.$refs.employeeInputInitial.$refs.qinput.validate();
            //@ts-ignore
            this.$refs.employeeInputEnd.$refs.qinput.validate();
        }
        this.selectedInputValue[this.radioButton].value_start =
            this.inputValueInitial;
        this.selectedInputValue[this.radioButton].value_end = this.inputValueEnd;
        if (this.inputValueInitial <= this.inputValueEnd) {
            this.hasError = false;
            this.$emit('setInputRange', {
                value_start: this.inputValueInitial,
                value_end: this.inputValueEnd,
                queryField: this.radioButton,
            });
        }
        else {
            this.hasError = true;
        }
    }
    setSelectedRadioButton(queryField, from) {
        if (this.selectedInputValue[queryField]) {
            this.selectedInputValue[queryField].value_start = this.inputValueInitial;
            this.selectedInputValue[queryField].value_end = this.inputValueEnd;
        }
        this.$emit('setSelectedRadioButton', { queryField, from });
        if (queryField === 'total_employees_r2016_company' ||
            queryField === 'total_employees_r2016_cnpj') {
            if (this.inputValueInitial && this.inputValueEnd) {
                this.$emit('setInputRange', {
                    value_start: this.selectedInputValue[queryField].value_start,
                    value_end: this.selectedInputValue[queryField].value_end,
                    queryField: queryField,
                });
            }
        }
    }
    validSliderFilter(event) {
        if (event.value[0] === event.value[1]) {
            this.$emit('setErrorSliderFilter', {
                type: event.groupTagFilter,
                action: 'error',
            });
            this.$emit('setSliderFilter', event);
        }
        else {
            this.$emit('setErrorSliderFilter', {
                type: event.groupTagFilter,
                action: 'success',
            });
            if (event.value[0] !== event.value[1] && event.value[1] !== '0') {
                this.inputValueInitial = null;
                this.inputValueEnd = null;
                if (event.value[1] === '+500')
                    event.value[1] = '1000000';
                if (event.queryField === 'total_employees_r2016_company' ||
                    event.queryField === 'total_employees_r2016_cnpj') {
                    this.$emit('setInputRange', {
                        value_start: event.value[0],
                        value_end: event.value[1],
                        queryField: this.radioButtonValue,
                    });
                }
                this.$emit('setSliderFilter', event);
            }
        }
    }
    get selectedInfo() {
        if (this.selected)
            if (!this.hasError) {
                if (this.inputValueInitial && this.inputValueEnd)
                    return `(selecionado: ${this.inputValueInitial} a ${this.inputValueEnd})`;
                else if (this.sliderValue.value.length > 0)
                    return `(selecionado: ${this.sliderValue.value[0]} a ${this.sliderValue.value[1]})`;
                return 'selecionado';
            }
            else
                return '<span class="font-12 text-red-8 text-weight-bold">Erro no filtro</span>';
        return '';
    }
    getSliderStyle() {
        if (this.errorSlider) {
            return {
                color: 'red',
            };
        }
    }
    tooltipCopy(label) {
        return differenceBetweenCnpjAndCompanyTooltip(label);
    }
    onSelectedInputRangeChange(curr, old) {
        if (curr) {
            const initialValue = this.getInitialSelectedInputRange(curr, 'value_start');
            const endValue = this.getInitialSelectedInputRange(curr, 'value_end');
            if (initialValue) {
                this.inputValueInitial = initialValue;
            }
            if (endValue) {
                this.inputValueEnd = endValue;
            }
            if (this.inputValueInitial && this.inputValueInitial)
                this.showOptionInput = true;
        }
    }
    getInitialSelectedInputRange(inputRange, valuePosition) {
        if (inputRange) {
            if (this.$refs.employeeInputInitial || this.$refs.employeeInputEnd) {
                //@ts-ignore
                this.$refs.employeeInputInitial.$refs.qinput.validate();
                //@ts-ignore
                this.$refs.employeeInputEnd.$refs.qinput.validate();
            }
            return Number(json(inputRange[this.radioButtonValue][valuePosition]));
        }
        else {
            return 0;
        }
    }
    toggleMode() {
        this.showOptionInput = !this.showOptionInput;
    }
    handleTrackingEmployees() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: () => { } })
], Employees.prototype, "filter", void 0);
__decorate([
    Prop({ default: () => { } })
], Employees.prototype, "stateSliderFiltersValues", void 0);
__decorate([
    Prop({ default: () => { } })
], Employees.prototype, "selectedInputRange", void 0);
__decorate([
    Prop({ default: '' })
], Employees.prototype, "filterName", void 0);
__decorate([
    Prop({ default: '' })
], Employees.prototype, "radioButton", void 0);
__decorate([
    Prop({ default: false })
], Employees.prototype, "errorSlider", void 0);
__decorate([
    Prop({ default: 0 })
], Employees.prototype, "selected", void 0);
__decorate([
    Watch('selectedInputValue', { immediate: true, deep: true })
], Employees.prototype, "onSelectedInputRangeChange", null);
Employees = __decorate([
    Component({
        components: {
            Slider,
            Input,
        },
    })
], Employees);
export default Employees;
