var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { formatCnpjsToSessionStorage, transformStringIntoArrayOfTerms, } from '@/shared/utils/helpers';
import { deburr, uniqBy, isEmpty, uniq, escapeRegExp } from 'lodash';
import Input from 'components/Input.vue';
import { json } from 'overmind';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { mixpanelTracking } from '@/services/mixpanel';
let ExcludeFromResultPanel = class ExcludeFromResultPanel extends Vue {
    constructor() {
        super(...arguments);
        this.filterOptions = this.filters.map(f => f.filterOptions);
        this.valueFilterOracle = json(this.removeFilterOracle);
        this.valueFilterCompanyName = json(this.removeFilterCompanyName);
        this.filteredFilters = [];
        this.currentFilterIndex = 0;
        this.searchTerm = '';
        this.errorMessage = '';
        this.selectedValues = [];
        this.options = [];
        this.toggleInput = false;
        this.selectedAll = false;
    }
    onChangeFilteredFilters(filteredFilters) {
        this.selectedAll = filteredFilters[this.currentFilterIndex].filterOptions.every(filter => filter.isSelected);
    }
    selectAllFilterOptions(val) {
        const filteredFilters = this.filteredFilters[this.currentFilterIndex].filterOptions.map(filter => ({ ...filter, isSelected: val }));
        return {
            filterOptions: filteredFilters,
            currentFilterIndex: this.currentFilterIndex,
            selectAll: val,
        };
    }
    mounted() {
        this.searchTerm = this.fullTextTerm;
        const cnpjs = sessionStorage.getItem('filters_deleted_cnpj');
        if (cnpjs !== null) {
            this.selectedValues = formatCnpjsToSessionStorage(cnpjs);
            this.options = this.selectedValues;
        }
        if (this.fullTextTerm !== '') {
            this.filters.forEach(f => {
                this.onSearchFilters(f, this.fullTextTerm);
                this.currentFilterIndex += 1;
            });
            this.currentFilterIndex -= 1;
        }
    }
    openInput() {
        if (this.toggleInput !== true)
            this.toggleInput = true;
    }
    addCnpj(text) {
        text.split(' ').forEach(spaceSplit => {
            spaceSplit.split(',').forEach(commaSplit => {
                commaSplit.split(';').forEach(semicolonSplit => {
                    semicolonSplit.split('\n').forEach(tag => {
                        const onlyNumbers = tag.replace(/\D+/g, '');
                        if (!onlyNumbers)
                            return false;
                        const cnpj = this.lJustify(onlyNumbers, 14, '0');
                        this.selectedValues.push(cnpj);
                    });
                });
            });
        });
        this.selectedValues = this.sanitizeCnpjs(this.selectedValues);
        this.options = this.selectedValues;
        if (this.selectedValues.length > 0)
            this.$emit('setCnpjsRemoved', true);
        sessionStorage.setItem('filters_deleted_cnpj', JSON.stringify(this.selectedValues));
    }
    lJustify(str, targetLength, paddingString = ' ') {
        let newString = str;
        while (newString.length < targetLength) {
            newString = paddingString + newString;
        }
        return newString;
    }
    removedCnpj(cnpj) {
        let cnpjs = sessionStorage.getItem('filters_deleted_cnpj');
        if (cnpjs !== null && cnpjs.length > 0) {
            cnpjs = formatCnpjsToSessionStorage(cnpjs);
            const index = cnpjs.findIndex(item => item === cnpj);
            if (index !== -1) {
                cnpjs.splice(index, 1);
                sessionStorage.setItem('filters_deleted_cnpj', JSON.stringify(cnpjs));
                this.selectedValues = cnpjs;
                this.options = cnpjs;
            }
        }
        if (this.selectedValues.length === 0) {
            this.$emit('setCnpjsRemoved', false);
        }
    }
    sanitizeCnpjs(cnpjs) {
        return uniq(cnpjs).filter(cnpj => !isEmpty(cnpj));
    }
    onFullTextSearchChange() {
        this.searchTerm = this.fullTextTerm;
        this.filters.forEach(f => {
            this.onSearchFilters(f, this.fullTextTerm);
            this.currentFilterIndex += 1;
        });
        this.currentFilterIndex -= 1;
    }
    async onFilterIndexChange(curr) {
        this.filterOptions[curr] =
            this.filters[this.currentFilterIndex].filterOptions;
    }
    onFiltersChange(curr) {
        if (curr) {
            // @ts-ignore
            this.filters = curr;
            this.filteredFilters = json(curr);
            this.filterOptions[this.currentFilterIndex] =
                this.filters[this.currentFilterIndex].filterOptions;
            if (this.searchTerm) {
                this.onSearchFilters({}, this.searchTerm);
            }
        }
    }
    selectedCount(filter) {
        switch (filter.queryType) {
            case 'multiSelect':
                return filter
                    ? filter.filterOptions.filter(option => option.isSelected).length
                    : 0;
            case 'multiText':
                if (filter.queryField === 'client_flags_array@where_not') {
                    return !isEmpty(this.removeFilterOracle) ? 1 : 0;
                }
                else {
                    return !isEmpty(this.removeFilterCompanyName) ? 1 : 0;
                }
            case 'inputText':
                return !isEmpty(this.selectedValues) ? 1 : 0;
            default:
                return 0;
        }
    }
    getTerms() {
        return transformStringIntoArrayOfTerms(this.searchTerm.replace(/\/|-/gi, ''));
    }
    onSearchFilters(filter, event) {
        if (event == null) {
            this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
            return;
        }
        const termList = this.getTerms();
        let filterOptions = json(this.filterOptions[this.currentFilterIndex].filter(o => {
            return termList.some(searchTerm => {
                const deburedTerm = escapeRegExp(deburr(searchTerm));
                if (o.value && o.value === searchTerm)
                    return true;
                if (o.value && termList.length > 1)
                    return false;
                if (o.tags) {
                    const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                    const deburedMatch = debured.match(new RegExp(deburedTerm + '\\b', 'gi'));
                    if (deburedMatch)
                        return deburedMatch;
                }
                const deburedLabel = o.label ? deburr(o.label) : undefined;
                return o.label && deburedLabel
                    ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                    : false;
            });
        }));
        filterOptions = uniqBy(filterOptions, 'value');
        this.filteredFilters[this.currentFilterIndex].filterOptions =
            json(filterOptions);
    }
    onHideFilter(filterIndex) {
        if (this.fullTextTerm === '') {
            this.filteredFilters[filterIndex].filterOptions = json(this.filters[filterIndex].filterOptions);
        }
    }
    onShowFilter(filterIndex, filterTitle) {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + filterTitle);
        this.currentFilterIndex = filterIndex;
        this.searchTerm = '';
    }
    setRemoveFilterOracle({ value }, type) {
        return { value, type };
    }
    setRemoveCompanyName({ value }, type) {
        return { value, type };
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        const option = this.filteredFilters[filterIndex].filterOptions.find(o => o.value === itemId);
        if (option) {
            this.filteredFilters[this.currentFilterIndex].filterOptions[filterOptionIndex].isSelected = value;
        }
        this.$emit('setCheckbox', filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId);
    }
    beforeDestroy() {
        this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
    }
};
__decorate([
    Prop({ default: () => [] })
], ExcludeFromResultPanel.prototype, "removeFilterCompanyName", void 0);
__decorate([
    Prop({ default: () => [] })
], ExcludeFromResultPanel.prototype, "removeFilterOracle", void 0);
__decorate([
    Prop({ default: () => [] })
], ExcludeFromResultPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: '' })
], ExcludeFromResultPanel.prototype, "fullTextTerm", void 0);
__decorate([
    Prop({ default: 1 })
], ExcludeFromResultPanel.prototype, "filterGroupId", void 0);
__decorate([
    Watch('filteredFilters', { deep: true })
], ExcludeFromResultPanel.prototype, "onChangeFilteredFilters", null);
__decorate([
    Emit('selectAllFilterOptions')
], ExcludeFromResultPanel.prototype, "selectAllFilterOptions", null);
__decorate([
    Watch('fullTextTerm')
], ExcludeFromResultPanel.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], ExcludeFromResultPanel.prototype, "onFilterIndexChange", null);
__decorate([
    Watch('filters', { deep: true, immediate: true })
], ExcludeFromResultPanel.prototype, "onFiltersChange", null);
__decorate([
    Emit('setRemoveFilterOracle')
], ExcludeFromResultPanel.prototype, "setRemoveFilterOracle", null);
__decorate([
    Emit('setRemoveCompanyName')
], ExcludeFromResultPanel.prototype, "setRemoveCompanyName", null);
ExcludeFromResultPanel = __decorate([
    Component({
        name: 'ExcludeFromResultPanel',
        components: {
            Input,
            Multiselect,
        },
    })
], ExcludeFromResultPanel);
export default ExcludeFromResultPanel;
