var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Countries from '@/modules/search/components/filterPanels/LocalizationPanel/Countries.vue';
import { connect } from '@/overmind';
let CountriesContainer = class CountriesContainer extends Vue {
    setCountriesCheckbox({ filterOptionIndex, value, itemId, }) {
        // @ts-ignore TS2349: property inexistent
        this.actions.updateFilterOption({
            filterGroupId: 2,
            filterId: 7,
            filterIndex: 4,
            filterOptionIndex,
            value,
            itemId,
            filterSpecial: this.filter.special,
        });
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
};
__decorate([
    Prop({ default: () => { } })
], CountriesContainer.prototype, "filter", void 0);
__decorate([
    Prop({ default: true })
], CountriesContainer.prototype, "canSearch", void 0);
CountriesContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        actions: actions.filters,
        actionsSearch: actions.search,
        searchTerm: state.searchTerm,
        selectedCountries: state.selectedCheckbox['co_municipio_ibge'],
    }), {
        components: {
            Countries,
        },
    }))
], CountriesContainer);
export default CountriesContainer;
