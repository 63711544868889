var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
import Tooltip from 'components/Tooltip.vue';
let SearchExportModalInfosetList = class SearchExportModalInfosetList extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.newInfosetName = '';
        this.loadingInfosetItems = false;
        this.currentInfosetPage = 1;
        this.infosetId = '';
        this.hasError = false;
        this.errorMessage = '';
        this.createInfosetIsOpen = true;
    }
    openCreateInfoset() {
        this.infosetId = '';
        this.newInfosetName = '';
        this.searchTerm = '';
        this.selectListItem();
        this.createInfosetIsOpen = !this.createInfosetIsOpen;
    }
    createInfoset() {
        if (this.newInfosetName)
            return this.newInfosetName;
    }
    searchInfosets(term) {
        this.loadingInfosetItems = true;
        this.currentInfosetPage = 1;
        if (this.$refs.infosetVirtualList)
            this.$refs.infosetVirtualList.refresh(0);
        this.infosetId = '';
        return term;
    }
    selectListItem() {
        if (this.createInfosetIsOpen && this.newInfosetName)
            return this.createInfoset();
        return this.infosetId;
    }
    onInfosetsChange(newInfosets) {
        this.loadingInfosetItems = false;
    }
    handleShowInfosetsList() {
        return ((this.infosets && this.infosets.length > 0) || this.loadingInfosetItems);
    }
    enforceMaxChars(newValue, oldValue) {
        if (newValue && newValue.length > 60) {
            this.newInfosetName = newValue.slice(0, 60);
        }
    }
    hasNoSpecialChars() {
        if (this.newInfosetName === '' || this.newInfosetName === null) {
            this.hasError = false;
            return;
        }
        const isValid = /^[a-zA-Z0-9\s]+$/.test(this.newInfosetName.trim());
        if (!isValid) {
            this.hasError = true;
            return 'Forneça um nome válido';
        }
        else {
            this.hasError = false;
            return;
        }
    }
    getNextInfosetPage(data) {
        if (data.direction === 'increase') {
            if (data.index === data.to && this.infosets.length < data.to) {
                this.loadingInfosetItems = true;
                this.currentInfosetPage += 1;
                this.$emit('getNextInfosetPage', this.currentInfosetPage, this.searchTerm);
            }
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], SearchExportModalInfosetList.prototype, "infosets", void 0);
__decorate([
    Emit('createInfoset')
], SearchExportModalInfosetList.prototype, "createInfoset", null);
__decorate([
    Emit('searchInfosets')
], SearchExportModalInfosetList.prototype, "searchInfosets", null);
__decorate([
    Emit('selectListItem')
], SearchExportModalInfosetList.prototype, "selectListItem", null);
__decorate([
    Watch('infosets')
], SearchExportModalInfosetList.prototype, "onInfosetsChange", null);
__decorate([
    Watch('newInfosetName')
], SearchExportModalInfosetList.prototype, "enforceMaxChars", null);
SearchExportModalInfosetList = __decorate([
    Component({
        components: {
            Input,
            Tooltip,
        },
    })
], SearchExportModalInfosetList);
export default SearchExportModalInfosetList;
