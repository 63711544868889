var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EmployeesQueryFieldOptions, RevenuesQueryFieldOptions, } from '@/interfaces/filters.interfaces';
import SimplePageSelectedFilters from '@/modules/search/components/SimplePageSelectedFilters.vue';
import { connect } from '@/overmind';
import { getCountTotalSelected, getFilterCep, getFilterCNPJsWherenot, getFilterDateFilter, getFilterNeighborhoods, getFilterOptionsByCNPJ, getRemoveFilterOracle, getRemoveFilterCompanyName, getSelectedCheckbox, getSelectedFilterOracle, getSelectedInputRange, getStateInputFilters, getStateSliderFilters, getSelectedFilterGPT, } from '@/shared/utils/SelectedFilters.utils';
import { concat } from 'lodash';
import { json } from 'overmind';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let SimplePageSelectedFiltersContainer = class SimplePageSelectedFiltersContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.totalSelectedFilters = json(this.totalSelected);
    }
    async removeExclusiveOptionSelected(selectedFilter) {
        //@ts-ignore TS2349: property inexistent
        this.actionsFilters.removeSelectedFilter(selectedFilter);
        await this.createNewSearch();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        this.removeFilterWhenRemoveCheckbox();
    }
    getResultTotalSelections() {
        // @ts-ignore TS2349: property inexistent
        return getCountTotalSelected(this.totalSelected);
    }
    getSelected() {
        return [
            ...getSelectedCheckbox(
            // @ts-ignore
            this.selectedCheckbox, 
            // @ts-ignore
            this.selectedFilteredType, true),
            // @ts-ignore
            ...getSelectedInputRange(this.selectedInputRange),
            // @ts-ignore
            ...getFilterDateFilter(this.dateFilter),
            // @ts-ignore
            ...getStateSliderFilters(this.stateSliderFilters, this.totalSelected),
            // @ts-ignore
            ...getSelectedFilterOracle(this.selectedFilterOracle),
            // @ts-ignore
            ...getRemoveFilterOracle(this.removeFilterOracle),
            // @ts-ignore
            ...getRemoveFilterCompanyName(this.removeFilterCompanyName),
            // @ts-ignore
            ...getStateInputFilters(this.stateInputFilters),
            ...getSelectedFilterGPT(
            // @ts-ignore
            this.stateInputFilters['additional_data_atlas.data_gpt.product_service']
                .value),
            ...getFilterOptionsByCNPJ(),
            // @ts-ignore
            ...getFilterNeighborhoods(this.selectedFilterNeighborhoods),
            // @ts-ignore
            ...getFilterCep(this.selectedFilterCeps),
            ...getFilterCNPJsWherenot(),
        ];
    }
    async removeSelectedFilterByFilterOptionModel({ filterOption, }) {
        // @ts-ignore
        const filterGroups = json(this.filterGroups);
        const { filterGroupsIndex, filterIndex, filterOptionIndex } = this.getIndexesOfFilters(filterOption);
        const filterGroup = filterGroups[filterGroupsIndex] || { filters: [] };
        const filter = filterGroup.filters[filterIndex] || { filterOptions: [] };
        const option = filter.filterOptions[filterOptionIndex];
        if (filterOption.queryField == 'Decisores lógica OU/E') {
            const decisorsFilter = this.getSelected().filter(f => f.queryField.match('decision_makers_lkd_m5'));
            decisorsFilter.forEach(f => {
                const decisorFilter = filterGroups.find(f => f.id == 701);
                const filterIndx = f.queryField == 'decision_makers_lkd_m5.department' ? 0 : 1;
                let filterOPt;
                decisorFilter.filters[filterIndx].filterOptions.find((filt, i) => {
                    if (filt.label == f.label)
                        filterOPt = i;
                });
                this.setCheckbox({
                    filterGroupId: 701,
                    filterId: f.queryField == 'decision_makers_lkd_m5.department' ? 2501 : 2502,
                    filterIndex: filterIndx,
                    filterOptionIndex: filterOPt,
                    value: false,
                    itemId: f.value,
                });
                // @ts-ignore TS2349: property inexistent
                this.actionsSearch.setSelectedFilteredType('');
            });
        }
        else if (filter.queryType === 'multiSelect') {
            this.setCheckbox({
                filterGroupId: filterGroup.id || 0,
                filterId: filter.id,
                filterIndex,
                filterOptionIndex,
                value: false,
                itemId: option.value,
            });
        }
        else if (filter.queryField === 'additional_data_atlas.data_gpt.product_service') {
            this.removeSelectedFilterGPT(filterOption.value);
        }
        else if (filter.queryField === 'client_flags_array' ||
            filter.queryField === 'client_flags_array@where_not') {
            this.removeSelectedFilterOracle({
                value: filterOption.value,
                wherenot: filter.queryField.includes('where_not'),
            });
        }
        else if (filter.queryField === 'no_empresa@where_not') {
            this.removeSelectedFilterCompanyName(filterOption.value);
        }
        else if (filterOption.queryField === 'nu_cnpj') {
            this.removeCnpj('cnpjs', 'cnpjs');
        }
        else if (filterOption.queryField === 'nu_cnpj@where_not') {
            this.removeCnpj(filterGroup.groupTag, filter.queryField, 'filters_deleted_cnpj');
        }
        else if (filter.queryType === 'rangeText') {
            this.setInputRange({
                groupTag: filterGroup.groupTag,
                queryField: filterOption.value,
                value_end: '',
                value_start: '',
            });
        }
        else if (filter.queryType === 'inputText') {
            this.setInputFilter({ queryField: filter.queryField, value: '' });
        }
        else if (filter.queryField === 'nu_cep') {
            // @ts-ignore
            this.actionsFilters.setSelectedFilterCep({
                value: filterOption.value,
                type: 'remove',
            });
        }
        else if (filter.queryField === 'no_bairro') {
            // @ts-ignore
            this.actionsFilters.setSelectedFilterNeighborhood({
                value: [filterOption.label],
                type: 'remove',
            });
        }
        else if (filterOption.queryField === 'cep_distance') {
            // @ts-ignore
            this.actionsFilters.clearDistanceFilter();
            // @ts-ignore
            this.actionsFilters.removeTotalSelected({
                groupTag: 'localização',
                queryField: 'cep_distance',
            });
        }
        else if (concat(EmployeesQueryFieldOptions, RevenuesQueryFieldOptions).includes(filter.queryField)) {
            // @ts-ignore
            this.actionsFilters.resetRangeFilterBy(filter.queryField);
        }
        else if (filter.queryType === 'rangeDate') {
            // @ts-ignore
            this.actionsFilters.setFilterDate({
                queryField: filter.queryField,
                inDate: '',
                toDate: '',
            });
        }
        // @ts-ignore TS2349: property inexistent
        await this.createNewSearch();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    async createNewSearch() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.searchState.currentSearchId },
        })
            .catch(err => err);
    }
    removeCnpj(groupTag = 'cnpjs', queryField = '', item = 'filters_cnpj') {
        sessionStorage.setItem(item, '[]');
        //@ts-ignore TS2349: property inexistent
        this.actionsFilters.removeTotalSelected({
            groupTag,
            queryField,
        });
    }
    getIndexesOfFilters(filterOption) {
        // @ts-ignore
        const filterGroups = json(this.filterGroups);
        const indexFilters = {
            filterGroupsIndex: -1,
            filterIndex: -1,
            filterOptionIndex: -1,
        };
        filterGroups.forEach((filterGroup, indexFilterGroup) => {
            filterGroup.filters.forEach((filter, indexFilter) => {
                filter.filterOptions.forEach((option, indexFilterOption) => {
                    if (filter.queryField === filterOption.queryField ||
                        option.queryField === filterOption.queryField ||
                        (option.queryField &&
                            option.queryField.includes('cnae') &&
                            filterOption.queryField.includes('cnae'))) {
                        indexFilters.filterGroupsIndex = indexFilterGroup;
                        indexFilters.filterIndex = indexFilter;
                        if (option.value === filterOption.value ||
                            filterOption.queryField === option.value ||
                            filter.filterOptions.length === 1) {
                            indexFilters.filterOptionIndex = indexFilterOption;
                        }
                    }
                });
            });
        });
        return indexFilters;
    }
    setInputRange(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputRange(value);
    }
    setInputFilter(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputFilters(event);
    }
    setCheckbox({ filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId, }) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.updateFilterOption({
            filterGroupId,
            filterId,
            filterIndex,
            filterOptionIndex,
            value,
            itemId,
        });
    }
    removeSelectedFilterOracle(event) {
        if (event.wherenot) {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setRemoveFilterOracle({
                value: event.value,
                type: 'remove',
            });
        }
        else {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setSelectedFilterOracle({
                value: event.value,
                type: 'remove',
            });
        }
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    removeSelectedFilterGPT(event) {
        if (event) {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setSelectedFilterGPT({
                value: event,
                type: 'remove',
            });
        }
    }
    removeSelectedFilterCompanyName(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setWhereNotNoEmpresa({
            value: value,
            type: 'remove',
        });
    }
    getExclusiveOptions() {
        // @ts-ignore
        return json(this.exclusiveOptions || []).filter(e => e.isSelected);
    }
    removeFilterWhenRemoveCheckbox() {
        window.localStorage.setItem('removed_filter', JSON.stringify(true));
    }
    created() {
        // @ts-ignore
        const removedFilter = JSON.parse(localStorage.getItem('removed_filter'));
        if (removedFilter) {
            // @ts-ignore
            this.actionsFilters.removeSelectedFilter({
                exclusiveOptionValue: 'ds_situacao_cnpj',
                groupTag: 'potentialResults',
            });
        }
    }
    beforeDestroy() {
        window.localStorage.removeItem('removed_filter');
    }
    openAllPageSelectedFilters() {
        return true;
    }
    getFilterHeight(filterHeightOffset) {
        // console.log(filterHeightOffset)
        return filterHeightOffset;
    }
};
__decorate([
    Prop({ default: 0 })
], SimplePageSelectedFiltersContainer.prototype, "offsetWidth", void 0);
__decorate([
    Emit('openAllPageSelectedFilters')
], SimplePageSelectedFiltersContainer.prototype, "openAllPageSelectedFilters", null);
__decorate([
    Emit('getFilterHeight')
], SimplePageSelectedFiltersContainer.prototype, "getFilterHeight", null);
SimplePageSelectedFiltersContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
        filterGroups: state.filters.filterGroups,
        totalSelected: state.filters.totalSelected,
        selectedCheckbox: state.filters.selectedCheckbox,
        selectedInputRange: state.filters.selectedInputRange,
        exclusiveOptions: state.filters.exclusiveOptions,
        dateFilter: state.filters.dateFilter,
        stateSliderFilters: state.filters.stateSliderFilters,
        selectedFilterOracle: state.filters.selectedFilterOracle,
        selectedGPTFilter: state.filters.selectedGPTFilter,
        removeFilterOracle: state.filters.removeFilterOracle,
        stateInputFilters: state.filters.stateInputFilters,
        selectedFilterCeps: state.filters.selectedFilterCeps,
        selectedFilterNeighborhoods: state.filters.selectedFilterNeighborhoods,
        removeFilterCompanyName: state.filters.removeEmpresa,
        selectedFilteredType: state.filters.selectedFilteredType,
        searchState: state.search,
    }), {
        components: {
            SimplePageSelectedFilters,
        },
    }))
], SimplePageSelectedFiltersContainer);
export default SimplePageSelectedFiltersContainer;
