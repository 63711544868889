var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CompanyStatus } from '@/overmind/search/state';
import { json } from 'overmind';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Tooltip from 'components/Tooltip.vue';
import { accentBrazilCity } from '@/shared/utils/AccentBrazilCity';
import { connect, overmind } from '@/overmind';
import IconEye from '@/components/Icons/iconEye.vue';
let SearchCompanyCard = class SearchCompanyCard extends Vue {
    constructor() {
        super(...arguments);
        this.active = false;
    }
    mounted() {
        overmind.reaction(({ search }) => search.companiesToExport, v => (this.active = v.some(r => r === this.company.cnpj)));
        //@ts-ignore
        this.active = this.companiesToExport.some(r => r === this.company.cnpj);
    }
    // this.selectedCompanies.some(r => r === this.company.cnpj)
    set selected(val) { }
    get selected() {
        return this.active;
    }
    get isConsultHitoryOpen() {
        //@ts-ignore
        return this.isConsultHistory;
    }
    handleBadges(company, type) {
        if (!company.badges) {
            return false;
        }
        else {
            const index = json(company.badges.available_data).findIndex(item => item === type);
            if (index !== -1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    toggleSelectCompany() {
        this.$emit('toggleSelectCompany', this.company, this.active);
    }
    handleAccentBrazilCity() {
        return accentBrazilCity(this.company.address.city);
    }
};
__decorate([
    Prop({ default: () => { } })
], SearchCompanyCard.prototype, "company", void 0);
__decorate([
    Prop({ default: false })
], SearchCompanyCard.prototype, "isActive", void 0);
__decorate([
    Prop({ default: [] })
], SearchCompanyCard.prototype, "selectedCompanies", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], SearchCompanyCard.prototype, "companyStatus", void 0);
SearchCompanyCard = __decorate([
    Component(connect(({ state, actions }) => ({
        isConsultHistory: state.search.isConsultHistory,
        companiesToExport: state.search.companiesToExport,
        actions: actions.search,
    }), {
        name: 'SearchCompanyCard',
        components: { Tooltip, IconEye },
    }))
], SearchCompanyCard);
export default SearchCompanyCard;
