var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { deburr, uniqBy, isEmpty, escapeRegExp } from 'lodash';
import { json } from 'overmind';
import Input from 'components/Input.vue';
import Tooltip from '@/components/Tooltip.vue';
import { transformStringIntoArrayOfTerms } from '@/shared/utils/helpers';
import AdvancedFiltersModalUnlockFilter from '@/components/AdvancedFiltersModal/UnlockFilter.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
import axios from 'axios';
Vue.prototype.$axios = axios.create();
let ActivityPanel = class ActivityPanel extends Vue {
    constructor() {
        super(...arguments);
        this.filteredFilters = [];
        this.radioButtonValue = json(this.radioButtonCnae);
        this.valueFilterOracle = json(this.filterOracle);
        this.valueFilterGPT = json(this.selectedGPT);
        this.filterOptions = json(this.filters).map(f => f.filterOptions);
        this.searchTerm = '';
        this.currentFilterIndex = 0;
        this.selectedAll = false;
        this.accountType = '';
        this.loaded = false;
        this.optionsKeyWords = [];
        this.keyWord = [];
    }
    async searchKeyWords(keyWord, update) {
        const cancelToken = await axios.CancelToken.source();
        if (keyWord === '')
            return;
        try {
            const res = await Vue.axios.get('searches/get_complete_words_products', {
                params: {
                    key_word: keyWord,
                },
                cancelToken: cancelToken.token,
            });
            setTimeout(() => {
                update(() => {
                    //@ts-ignore
                    this.optionsKeyWords = res.data;
                });
            }, 200);
        }
        catch (err) {
            console.log(err);
            update(() => {
                this.optionsKeyWords = [];
            });
        }
    }
    setSelectedGPTKeyWord({ value }, type) {
        this.valueFilterGPT = [];
        //@ts-ignore
        this.keyWord = '';
        return { value, type };
    }
    onChangeFilteredFilters(filteredFilters) {
        this.selectedAll = filteredFilters[this.currentFilterIndex].filterOptions.every(filter => filter.isSelected);
    }
    onFullTextSearchChange(curr, old) {
        this.searchTerm = this.fullTextTerm;
        this.filters.forEach(f => {
            this.onSearchFilters(f, this.fullTextTerm);
            this.currentFilterIndex += 1;
        });
        this.currentFilterIndex -= 1;
    }
    async onFilterIndexChange(curr, old) {
        this.filterOptions[curr] =
            this.filters[this.currentFilterIndex].filterOptions;
    }
    onFiltersChange(curr) {
        if (curr) {
            // @ts-ignore
            this.filters = curr;
            this.filteredFilters = json(curr);
            this.filterOptions[this.currentFilterIndex] =
                this.filters[this.currentFilterIndex].filterOptions;
            if (this.searchTerm) {
                this.onSearchFilters({}, this.searchTerm);
            }
        }
    }
    sortItemsBySelectedState(items) {
        return items.slice().sort((a, b) => {
            return b.isSelected - a.isSelected;
        });
    }
    selectedCount(filter) {
        if (filter.queryType === 'multiSelect') {
            return filter
                ? filter.filterOptions.filter(option => option.isSelected).length
                : 0;
        }
        if (filter.queryType === 'multiText') {
            if (!isEmpty(this.filterOracle) && Array.isArray(this.filterOracle)) {
                return this.filterOracle.length;
            }
            return 0;
        }
        return 0;
    }
    selectCountGPT(filter) {
        if (filter.queryType === 'multiText') {
            if (!isEmpty(this.selectedGPT) && Array.isArray(this.selectedGPT)) {
                return this.selectedGPT.length;
            }
            return 0;
        }
        return 0;
    }
    hasCnaeTypeFilterSelected(filter) {
        let hasCnae = filter.filterOptions.filter(option => option.subline === 'CNAE' && option.isSelected);
        if (hasCnae.length > 0)
            return true;
        return false;
    }
    getTerms() {
        return transformStringIntoArrayOfTerms(this.searchTerm.replace(/\/|-/gi, ''));
    }
    onSearchFilters(filter, event) {
        if (!event) {
            this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
            return;
        }
        const termList = this.getTerms();
        let filterOptions = json(this.filterOptions[this.currentFilterIndex].filter(o => {
            return termList.some(searchTerm => {
                const deburedTerm = deburr(searchTerm);
                if (o.value && o.value === searchTerm)
                    return true;
                if (o.value && termList.length > 1)
                    return false;
                if (o.tags) {
                    const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                    const deburedMatch = debured.match(new RegExp(escapeRegExp(deburedTerm) + '\\b', 'gi'));
                    if (deburedMatch)
                        return deburedMatch;
                }
                const deburedLabel = o.label ? deburr(o.label) : undefined;
                return o.label && deburedLabel
                    ? deburedLabel.match(new RegExp(escapeRegExp(deburedTerm), 'i'))
                    : false;
            });
        }));
        filterOptions = uniqBy(filterOptions, 'value');
        this.filteredFilters[this.currentFilterIndex].filterOptions =
            json(filterOptions);
    }
    selectAllFilterOptions(val) {
        const filteredFilters = this.filteredFilters[this.currentFilterIndex].filterOptions.map(filter => ({
            ...filter,
            isSelected: val,
        }));
        return {
            filterOptions: filteredFilters,
            currentFilterIndex: this.currentFilterIndex,
            selectAll: val,
        };
    }
    onHideFilter(filterIndex) {
        if (this.fullTextTerm === '') {
            this.filteredFilters[filterIndex].filterOptions = json(this.filters[filterIndex].filterOptions);
        }
    }
    onShowFilter(filterIndex, filterTitle) {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + filterTitle);
        this.currentFilterIndex = filterIndex;
        this.searchTerm = '';
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        const option = this.filteredFilters[filterIndex].filterOptions.find(o => o.value === itemId);
        if (option) {
            this.filteredFilters[this.currentFilterIndex].filterOptions[filterOptionIndex].isSelected = value;
        }
        this.$emit('setCheckbox', filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId);
    }
    formatLabel(label) {
        if (label == 'Varejo') {
            return 'Varejo e atacado';
        }
        return label;
    }
    setSelectedRadioButton(queryField, from) {
        return { queryField, from };
    }
    setSelectedFilterOracle({ value }, type) {
        return { value, type };
    }
    beforeDestroy() {
        this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
    }
    mounted() {
        this.searchTerm = this.fullTextTerm;
        if (this.fullTextTerm !== '') {
            this.filters.forEach(f => {
                this.onSearchFilters(f, this.fullTextTerm);
                this.currentFilterIndex += 1;
            });
            this.currentFilterIndex -= 1;
        }
        //@ts-ignore
        if (!this.userActions.getAuthPermissions())
            this.accountType = 'free';
        this.loaded = true;
    }
    showFilters(show) {
        if (show) {
            this.$refs.filtermenu[0].show();
        }
        else {
            this.$refs.filtermenu[0].hide();
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], ActivityPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: () => [] })
], ActivityPanel.prototype, "filterOracle", void 0);
__decorate([
    Prop({ default: () => [] })
], ActivityPanel.prototype, "selectedGPT", void 0);
__decorate([
    Prop({ default: 'idle' })
], ActivityPanel.prototype, "radioButtonState", void 0);
__decorate([
    Prop({ default: '' })
], ActivityPanel.prototype, "radioButtonCnae", void 0);
__decorate([
    Prop({ default: '' })
], ActivityPanel.prototype, "fullTextTerm", void 0);
__decorate([
    Prop({ default: 1 })
], ActivityPanel.prototype, "filterGroupId", void 0);
__decorate([
    Prop({ default: true })
], ActivityPanel.prototype, "canSearch", void 0);
__decorate([
    Emit('setSelectedGPTKeyWord')
], ActivityPanel.prototype, "setSelectedGPTKeyWord", null);
__decorate([
    Watch('filteredFilters', { deep: true })
], ActivityPanel.prototype, "onChangeFilteredFilters", null);
__decorate([
    Watch('fullTextTerm')
], ActivityPanel.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], ActivityPanel.prototype, "onFilterIndexChange", null);
__decorate([
    Watch('filters', { deep: true, immediate: true })
], ActivityPanel.prototype, "onFiltersChange", null);
__decorate([
    Emit('selectAllFilterOptions')
], ActivityPanel.prototype, "selectAllFilterOptions", null);
__decorate([
    Emit('setSelectedRadioButton')
], ActivityPanel.prototype, "setSelectedRadioButton", null);
__decorate([
    Emit('setSelectedFilterOracle')
], ActivityPanel.prototype, "setSelectedFilterOracle", null);
ActivityPanel = __decorate([
    Component(connect(({ actions }) => ({ userActions: actions.users }), {
        name: 'ActivityPanel',
        components: {
            Input,
            Tooltip,
            AdvancedFiltersModalUnlockFilter,
        },
    }))
], ActivityPanel);
export default ActivityPanel;
