var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';
import { connect } from '@/overmind';
import Input from 'components/Input.vue';
import OptionAutoPlayExportation from '@/components/Autoplay/OptionAutoPlayExportation.vue';
import OptionAutoPlayIntegration from 'src/components/Autoplay/OptionAutoPlayIntegration.vue';
import IconMoreBtn from 'src/components/Autoplay/icons/IconMoreBtn.vue';
import IconSubBtn from 'src/components/Autoplay/icons/IconSubBtn.vue';
import InputWithBtn from 'src/components/Autoplay/InputWithBtn.vue';
import IconSpeedioWtf from 'src/components/Autoplay/icons/IconSpeedioWtf.vue';
import IconSpeedioNormal from 'src/components/Autoplay/icons/IconSpeedioNormal.vue';
import IconSpeedioExclent from 'src/components/Autoplay/icons/IconSpeedioExclent.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import InsufficientCreditsModal from '@/components/Autoplay/InsufficientCreditsModal.vue';
let SettingsAutoPlayModal = class SettingsAutoPlayModal extends Vue {
    constructor() {
        super(...arguments);
        this.selectedCount = 0;
        this.expiredCredits = true;
        this.result = this.history.totalResults / this.selectedCount;
        this.ExportationsType = ['CSV', 'RD Station', 'HubSpot', 'Pipedrive'];
        this.dayExportation = 1;
        this.openSatisfation = false;
        this.rating = -1;
        this.rename = true;
        this.formatDate = '';
        this.dataAux = new Date();
        this.options = {
            weekday: 'long',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        this.day = '';
        this.dayCompare = {};
        this.sumCredits = 0;
        this.exportConfigs = {};
        this.exportOptions = [];
        this.configuredDataForExport = false;
        this.optionsExportSelected = [];
        this.credits = {};
        this.loading = true;
        this.resulted = {};
        this.creditsUser = 0;
        this.crm = ['pipedrive', 'rdstation', 'hubspot'];
        this.crms = [];
        this.load = false;
        this.targetDate = 0;
        this.message = 'avaliação feita';
        this.type = 'AutoPlay Satisfaction';
    }
    async mounted() {
        this.load = true;
        /* this.crm.forEach(async cr => {
          //@ts-ignore
          this.result = await this.actions.getCrmAutoplay(cr)
          //@ts-ignore
          if (this.result.data.token == 'User has not this CRM registered') {
            //@ts-ignore
            this.crms.push({
              //@ts-ignore
              name: cr,
              //@ts-ignore
              result: false,
            })
          } else {
            //@ts-ignore
            this.crms.push({
              //@ts-ignore
              name: cr,
              //@ts-ignore
              result: true,
            })
          }
        })*/
        mixpanelTracking('autoplay: Abriu modal de configurações do autoplay');
        //@ts-ignore
        let dateNow = new Date();
        //@ts-ignore
        let dateExpiration;
        //@ts-ignore
        this.resulted = await this.actions.getUserCreditsInformation();
        if (this.resulted) {
            //@ts-ignore
            this.credits = { ...this.resulted.credits } || {};
        }
        else {
            //msg erro
        }
        //@ts-ignore
        this.resulted.credits.filter(f => {
            //@ts-ignore
            dateExpiration = new Date(f.expiration);
            dateExpiration.setHours(dateExpiration.getHours() + 3);
            dateExpiration.setHours(0, 0, 0, 0);
            dateNow.setHours(0, 0, 0, 0);
            //@ts-ignore
            if (f.type == 'export' && dateExpiration >= dateNow) {
                //@ts-ignore
                this.creditsUser += f.remaining;
            }
            if (dateExpiration > dateNow) {
                var dataString = dateExpiration;
                // Crie um objeto de data a partir da string
                var data2 = new Date(dataString);
                // Extraia o dia, mês e ano da data2
                var dia = data2.getDate();
                var mes = data2.getMonth() + 1; // Adicione 1 ao mês, pois os meses em JavaScript são baseados em zero
                var ano = data2.getFullYear();
                // Formate a data2 no formato desejado (dd/mm/aaaa)
                var dataFormatada = `${dia.toString().padStart(2, '0')}/${mes
                    .toString()
                    .padStart(2, '0')}/${ano}`;
            }
        });
        //@ts-ignore
        const autoplay_id = this.history.autoplayId
            ? //@ts-ignore
                this.history.autoplayId
            : null;
        if (autoplay_id) {
            //@ts-ignore
            const result = await this.actions.getAutoplay(
            //@ts-ignore
            this.history.autoplayId);
            // @ts-ignore
            this.selectedCount = Number(result.data.autoplay.monthly_quantity);
            // @ts-ignore
            this.dayExportation = Number(result.data.autoplay.day);
            this.exportOptions = result.data.autoplay.exportation_options;
            this.exportConfigs = result.data.autoplay.exportation_configs;
            this.getData(result.data.autoplay.day);
            this.load = false;
            return;
        } //@ts-ignore
        else if (this.creditsUser > 0) {
            this.selectedCount =
                Number(this.history.totalResults) > this.creditsUser
                    ? this.creditsUser
                    : Number(this.history.totalResults);
            this.getData(1);
            if (this.valueSelectedCompanies) {
                this.selectedCount = this.valueSelectedCompanies;
            }
            this.load = false;
        }
        this.load = false;
    }
    creditsIsInsufficient() {
        return this.selectedCount > this.creditsUser;
    }
    handleDisable() {
        if (!this.dayExportation)
            return true;
        if (this.invalidSelectedDay)
            return true;
        const selectedCreditOptionEqualZero = 
        //@ts-ignore
        this.selectedCount === 0 || this.selectedCount === '';
        return (selectedCreditOptionEqualZero ||
            // this.handleIsNotAllowedCredits() ||
            this.optionsExportSelected.length == 0);
    }
    get invalidSelectedDay() {
        const currentDay = new Date().getDate();
        return this.dayExportation == currentDay;
    }
    handleIsNotAllowedCredits() {
        const creditSelectedMoreThanCurrentCredit = this.selectedCount > this.creditsUser;
        return creditSelectedMoreThanCurrentCredit;
    }
    selectedOptionExportation(name, value) {
        ;
        this.optionsExportSelected.push(name);
        this.configuredDataForExport = value;
    }
    getData(dayExportation) {
        if (this.selectedCount > Number(this.history.totalResults) &&
            Number(this.history.totalResults) < 10000) {
            this.selectedCount = Number(this.history.totalResults);
        }
        if (this.selectedCount > 5000)
            this.selectedCount = 5000;
        if (dayExportation > 31) {
            this.dayExportation = 1;
            dayExportation = 1;
        }
        else if (!dayExportation) {
            this.dayExportation = dayExportation != 0 ? dayExportation : '';
        }
        else if (dayExportation < 1) {
            dayExportation = 31;
            this.dayExportation = 31;
        }
        if (this.selectedCount < 0) {
            this.selectedCount = 0;
        }
        const currentDay = new Date();
        const curenteDayMonth = currentDay.getDate();
        if (Number(dayExportation) < Number(curenteDayMonth)) {
            //@ts-ignore
            this.day = new Date(currentDay.getFullYear(), currentDay.getMonth() + 1, dayExportation);
            //@ts-ignore
            this.day = this.day.toLocaleDateString('pt-BR', this.options);
            //@ts-ignore
            this.dayCompare = {
                year: currentDay.getFullYear(),
                month: currentDay.getMonth() + 1,
                day: dayExportation,
            };
        }
        else {
            //@ts-ignore
            this.day = new Date(currentDay.getFullYear(), currentDay.getMonth(), dayExportation);
            //@ts-ignore
            this.day = this.day.toLocaleDateString('pt-BR', this.options);
            this.dayCompare = {
                year: currentDay.getFullYear(),
                month: currentDay.getMonth(),
                day: dayExportation,
            };
        }
        this.sumValuesWithExpirationBeforeDayCompare();
        //@ts-ignore
        // this.selectedCount = this.selectedCount.replace(/\D/g, '')
    }
    getDateFromExpiration(expiration) {
        const [year, month, day] = expiration.split('-');
        return new Date(year, month - 1, day);
    }
    //@ts-ignore
    sumValuesWithExpirationBeforeDayCompare() {
        //@ts-ignore
        this.targetDate = new Date(
        //@ts-ignore
        this.dayCompare.year, 
        //@ts-ignore
        this.dayCompare.month, 
        //@ts-ignore
        this.dayCompare.day);
        //@ts-ignore
        let sum = 0;
        //@ts-ignore
        var i = 0;
        //@ts-ignore
        for (const item of this.resulted.credits) {
            i += 1;
            const expirationDate = this.getDateFromExpiration(item.expiration);
            //@ts-ignore
            if (expirationDate >= this.targetDate) {
                sum += item.remaining;
                if (item.remaining) {
                    var dia = expirationDate.getDate();
                    var mes = expirationDate.getMonth() + 1; // Os meses em JavaScript são baseados em zero, então adicionamos 1
                    var ano = expirationDate.getFullYear();
                    // Formatar a data no formato desejado (DD/MM/AAAA)
                    var dataFormatada = (dia < 10 ? '0' : '') +
                        dia +
                        '/' +
                        (mes < 10 ? '0' : '') +
                        mes +
                        '/' +
                        ano;
                    this.formatDate = dataFormatada;
                }
            }
            else if (item.remaining) {
                var dia2 = expirationDate.getDate();
                var mes2 = expirationDate.getMonth() + 1;
                var ano2 = expirationDate.getFullYear();
                var dataFormatada2 = (dia2 < 10 ? '0' : '') +
                    dia2 +
                    '/' +
                    (mes2 < 10 ? '0' : '') +
                    mes2 +
                    '/' +
                    ano2;
                this.formatDate =
                    this.dataAux < expirationDate ? dataFormatada2 : this.formatDate;
                this.dataAux = expirationDate;
            }
        }
        this.sumCredits = sum;
        if (this.sumCredits < this.selectedCount) {
            this.expiredCredits = false;
        }
        else {
            this.expiredCredits = true;
        }
    }
    closeSettingsAutoPlayModal() {
        mixpanelTracking('autoplay: fechou as configurações do autoplay');
        this.showSettingsAutoplay = false;
        this.openSatisfation = false;
        this.remountComponent();
        return false;
    }
    closeSatisfationModal() {
        this.showSettingsAutoplay = false;
        this.openSatisfation = false;
        let DateNow = new Date();
        //@ts-ignore TS2345: Argument of type 'Date' is not assignable to parameter of type 'string'.
        localStorage.setItem('AutoPlay_Data', DateNow);
        this.remountComponent();
    }
    addExportCount() {
        if (this.selectedCount < Number(this.history.totalResults) &&
            this.selectedCount < 9999) {
            this.selectedCount = Number(this.selectedCount) + 1;
        }
        this.getData(this.dayExportation);
    }
    subtractExportCount() {
        if (this.selectedCount > 0) {
            this.selectedCount = Number(this.selectedCount) - 1;
        }
        this.getData(this.dayExportation);
    }
    addDay() {
        this.dayExportation = Number(this.dayExportation) + 1;
        this.getData(this.dayExportation);
    }
    subtractDay() {
        if (this.dayExportation > 1) {
            this.dayExportation = Number(this.dayExportation) - 1;
            this.getData(this.dayExportation);
        }
        else {
            this.dayExportation = 31;
            this.getData(this.dayExportation);
        }
    }
    saveInformation(data) {
        //@ts-ignore TS2339: Property 'data' does not exist on type 'object'.
        const service = Object.keys(data)[0];
        //@ts-ignore
        this.exportOptions.push(service);
        this.exportConfigs = {
            ...this.exportConfigs,
            ...data,
        };
    }
    changeColor(bannerId) {
        if (bannerId == 'banner1') {
            let text = document.querySelector('.custom-text1');
            let text2 = document.querySelector('.custom-text2');
            let text3 = document.querySelector('.custom-text3');
            //@ts-ignore
            text2.style.color = '#95A5A6';
            //@ts-ignore
            text3.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            this.rating = 0;
        }
        else if (bannerId == 'banner2') {
            let text = document.querySelector('.custom-text2');
            let text1 = document.querySelector('.custom-text1');
            let text3 = document.querySelector('.custom-text3');
            //@ts-ignore
            text1.style.color = '#95A5A6';
            //@ts-ignore
            text3.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            this.rating = 1;
        }
        else {
            let text = document.querySelector('.custom-text3');
            let text1 = document.querySelector('.custom-text1');
            let text2 = document.querySelector('.custom-text2');
            //@ts-ignore
            text1.style.color = '#95A5A6';
            //@ts-ignore
            text2.style.color = '#95A5A6';
            //@ts-ignore
            text.style.color = '#1296FC';
            this.rating = 2;
        }
    }
    renameHistory() {
        this.rename = !this.rename;
        mixpanelTracking('autoplay: clicou em renomear busca');
    }
    renameSucessfull() {
        this.rename = true;
        mixpanelTracking('autoplay: busca renomeada com sucesso');
    }
    deselectOption(name) {
        this.optionsExportSelected = this.optionsExportSelected.filter((objeto) => objeto.name !== name);
        this.exportOptions.forEach((nameOption, i) => {
            if (name.replace(/\s/g, '').toLowerCase() == nameOption) {
                this.exportOptions.splice(i, 1);
            }
        });
    }
    async submitExperience() {
        const data = { type: this.type, rating: this.rating, message: this.message };
        if (this.rating == -1) {
            let dateNow = new Date();
            //@ts-ignore TS2345: Argument of type 'Date' is not assignable to parameter of type 'string'.
            localStorage.setItem('AutoPlay_Data', dateNow);
            this.remountComponent();
            this.closeSettingsAutoPlayModal();
            this.openSatisfation = false;
        }
        else {
            //@ts-ignore
            await this.actions.experienceUserHyperbolic(data);
            this.remountComponent();
            this.closeSettingsAutoPlayModal();
            this.openSatisfation = false;
        }
    }
    async saveAutoplayConfig() {
        const data = {
            monthly_quantity: Number(this.selectedCount),
            day: Number(this.dayExportation),
            exportation_options: [...new Set(this.exportOptions)],
            exportation_configs: this.exportConfigs,
            history_search_id: this.history.id,
        };
        data.exportation_configs = Object.fromEntries(Object.entries(data.exportation_configs)
            //@ts-ignore
            .filter(([nameConfig]) => data.exportation_options.includes(nameConfig)));
        //@ts-ignore
        await this.actions.saveAutoplay(data);
        const verificationOriginClick = this.originClick == 'toggle' ? false : true;
        this.$emit('toggleAutoplayActive', verificationOriginClick);
        //@ts-ignore
        await this.actionsSearch.searchHistory.getSearchHistory();
        //@ts-ignore TS2339: Property 'data' does not exist on type 'boolean'.
        if (this.showAvaliable.data.value) {
            if (localStorage.getItem('AutoPlay_Data')) {
                let dateNow = new Date();
                //@ts-ignore TS2769: No overload matches this call.
                let dateAgo = new Date(localStorage.getItem('AutoPlay_Data'));
                //@ts-ignore TS2362: The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
                let diferenceDays = Math.abs(dateNow - dateAgo);
                let threDays = 3 * 24 * 60 * 60 * 1000;
                if (diferenceDays > threDays) {
                    this.openSatisfation = true;
                    mixpanelTracking('autoplay: salvou as configurações do autoplay');
                }
                else {
                    this.closeSettingsAutoPlayModal();
                    this.remountComponent();
                }
            }
            else {
                this.openSatisfation = true;
                mixpanelTracking('autoplay: salvou as configurações do autoplay');
            }
        }
        else {
            this.closeSettingsAutoPlayModal();
            this.remountComponent();
        }
    }
    isSelected(nameSelected) {
        if (!this.exportOptions)
            return false;
        nameSelected = nameSelected.toLowerCase().replace(/\s/g, '');
        //@ts-ignore
        return this.exportOptions.includes(nameSelected);
    }
    crmConfigs(nameSelected) {
        if (!this.isSelected(nameSelected))
            return;
        nameSelected = nameSelected.toLowerCase().replace(/\s/g, '');
        return this.exportConfigs[nameSelected];
    }
    remountComponent() { }
};
__decorate([
    Prop({ default: {} })
], SettingsAutoPlayModal.prototype, "accountCredits", void 0);
__decorate([
    Prop({ default: () => { } })
], SettingsAutoPlayModal.prototype, "history", void 0);
__decorate([
    PropSync('show', { default: true })
], SettingsAutoPlayModal.prototype, "showSettingsAutoplay", void 0);
__decorate([
    Prop({ default: false })
], SettingsAutoPlayModal.prototype, "showAvaliable", void 0);
__decorate([
    Prop({ default: '' })
], SettingsAutoPlayModal.prototype, "originClick", void 0);
__decorate([
    Prop({ default: 0 })
], SettingsAutoPlayModal.prototype, "valueSelectedCompanies", void 0);
__decorate([
    Emit('closeSettingsAutoPlayModal')
], SettingsAutoPlayModal.prototype, "closeSettingsAutoPlayModal", null);
__decorate([
    Emit('remountComponent')
], SettingsAutoPlayModal.prototype, "remountComponent", null);
SettingsAutoPlayModal = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
        actionsSearch: actions.search,
    }), {
        name: 'SettingsAutoPlayModal',
        components: {
            Input,
            OptionAutoPlayExportation,
            OptionAutoPlayIntegration,
            IconMoreBtn,
            IconSubBtn,
            InputWithBtn,
            IconSpeedioWtf,
            IconSpeedioNormal,
            IconSpeedioExclent,
            IconSpeedioSpinner,
            InsufficientCreditsModal,
        },
    }))
], SettingsAutoPlayModal);
export default SettingsAutoPlayModal;
