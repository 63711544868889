var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import IconDoc from 'src/components/Autoplay/icons/IconDoc.vue';
import IconRdStation from 'src/components/Autoplay/icons/IconRdStation.vue';
import IconPipedrive from 'src/components/Autoplay/icons/IconPipedrive.vue';
import IconHubspot from 'src/components/Autoplay/icons/IconHubspot.vue';
import ExportListModalContent from '@/components/Autoplay/ExportListModalContent.vue';
let OptionAutoPlayExportation = class OptionAutoPlayExportation extends Vue {
    constructor() {
        super(...arguments);
        this.selected = false;
        this.selecteds = [];
        this.configExportModal = false;
        this.card = false;
        this.isSelectedCard = true;
        this.hover = false;
    }
    mounted() {
        this.selected = this.propSelected;
        if (this.selected)
            this.$emit('selectedOptionExportation', { name: this.name, value: true });
        //@ts-ignore
        this.crms.forEach(crm => {
            if (crm.name == this.name.replace(/\s/g, '').toLowerCase()) {
                this.isSelectedCard = false;
            }
        });
    }
    async handleOptionIsSelected() {
        if (this.selected == false) {
            this.configExportModal = true;
        }
        else {
            this.$emit('selectedOptionExportation', { name: this.name, value: false });
            this.selected = false;
            this.deselectOption();
        }
        // return this.selecteds.some(label => label === this.departament)
    }
    deselectOption() {
        return this.name;
    }
    saveInformation(value) {
        this.$emit('selectedOptionExportation', { name: this.name, value: true });
        this.configExportModal = false;
        this.selected = !this.selected;
        if (this.name == 'Excel' || (this.name == 'CSV' && this.selected == true)) {
            this.card = true;
        }
        return value;
    }
    cancelInformations() {
        this.configExportModal = false;
        this.$emit('selectedOptionExportation', { name: this.name, value: false });
    }
    visibleHover() {
        this.hover = true;
    }
    hideHover() {
        this.hover = false;
    }
    goIntegrations() {
        //@ts-ignore
        this.$router.push('/user/config/api-token-export');
    }
};
__decorate([
    Prop({ default: '' })
], OptionAutoPlayExportation.prototype, "name", void 0);
__decorate([
    Prop({ default: false })
], OptionAutoPlayExportation.prototype, "propSelected", void 0);
__decorate([
    Prop({ default: () => { } })
], OptionAutoPlayExportation.prototype, "configs", void 0);
__decorate([
    Prop({ default: () => { } })
], OptionAutoPlayExportation.prototype, "history", void 0);
__decorate([
    Prop({ default: {} })
], OptionAutoPlayExportation.prototype, "crms", void 0);
__decorate([
    Prop({ default: -1 })
], OptionAutoPlayExportation.prototype, "selectedCount", void 0);
__decorate([
    Emit('deselectOption')
], OptionAutoPlayExportation.prototype, "deselectOption", null);
__decorate([
    Emit('saveInformation')
], OptionAutoPlayExportation.prototype, "saveInformation", null);
OptionAutoPlayExportation = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
        actionsSearch: actions.search,
    }), {
        name: 'OptionAutoPlayExportation',
        components: {
            IconDoc,
            IconRdStation,
            IconPipedrive,
            IconHubspot,
            ExportListModalContent,
        },
    }))
], OptionAutoPlayExportation);
export default OptionAutoPlayExportation;
