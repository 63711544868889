export function accentBrazilCity(BrazilState) {
    function removeAccents(input) {
        return input
            .normalize('NFD') // Normaliza a string para a forma de decomposição canônica (NFD)
            .replace(/[\u0300-\u036f]/g, ''); // Remove os acentos usando regex
    }
    const normalizedBrazilState = removeAccents(BrazilState.toLowerCase());
    const brazilStatesMap = {
        'sao paulo': 'São Paulo',
        'rio de janeiro': 'Rio de Janeiro',
        'belo horizonte': 'Belo Horizonte',
        'porto alegre': 'Porto Alegre',
        florianopolis: 'Florianópolis',
        'sao luis': 'São Luís',
        'sao jose dos campos': 'São José dos Campos',
        'ribeirao preto': 'Ribeirão Preto',
        'sao bernardo do campo': 'São Bernardo do Campo',
        'sao goncalo': 'São Gonçalo',
        niteroi: 'Niterói',
        'joao pessoa': 'João Pessoa',
        'campo grande': 'Campo Grande',
        'caxias do sul': 'Caxias do Sul',
        'sao jose do rio preto': 'São José do Rio Preto',
        'sao joao de meriti': 'São João de Meriti',
        'sao caetano do sul': 'São Caetano do Sul',
        'sao vicente': 'São Vicente',
        'feira de santana': 'Feira de Santana',
        'sao leopoldo': 'São Leopoldo',
        'juiz de fora': 'Juiz de Fora',
        'mogi das cruzes': 'Mogi das Cruzes',
        'vila velha': 'Vila Velha',
        'ponta grossa': 'Ponta Grossa',
        camacari: 'Camaçari',
        itajai: 'Itajaí',
        'rio branco': 'Rio Branco',
        'santa cruz do sul': 'Santa Cruz do Sul',
    };
    return brazilStatesMap[normalizedBrazilState] || BrazilState;
}
