var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import SelectedLabelFilter from '@/components/SelectedLabelFilter.vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let PageAllFiltersSelected = class PageAllFiltersSelected extends Vue {
    constructor() {
        super(...arguments);
        this.exclusiveOptionNameFilter = 'Exibir apenas resultados com:';
    }
    closeAllSelectedFilters() {
        return false;
    }
    resetAllFilters() {
        return true;
    }
    handleLabelMessage() {
        return `"${this.selectedFilteredType}"`;
    }
    showLogicAndOrCopy(groupName) {
        return groupName == 'DECISORES' && this.departmentAndLevelIsSelected;
    }
    removeLogicAndOR() {
        return;
    }
    selectedCount(groupTag) {
        switch (groupTag) {
            case 'atividade':
                return this.totalSelectedFilters.activity.total;
            case 'localização':
                return this.totalSelectedFilters.localization.total;
            case 'porte':
                return this.totalSelectedFilters.porte.total;
            case 'legais':
                return this.totalSelectedFilters.legais.total;
            case 'where_not':
                return this.totalSelectedFilters.where_not.total;
            case 'cnpjs':
                return this.totalSelectedFilters.cnpjs.total;
            case 'decisores':
                return this.totalSelectedFilters.decisores.total;
            case 'management_and_administration':
                return this.totalSelectedFilters.management.total;
            case 'complaints':
                return this.totalSelectedFilters.complaints.total;
            case 'tecnology_and_tools':
                return this.totalSelectedFilters.technology.total;
            case 'potential_results':
                return this.totalSelectedFilters.potentialResults.total;
            default:
                return 0;
        }
    }
    removeSelectedFilter(exclusiveOptionValue) {
        const groupTag = 'potentialResults';
        return { groupTag, exclusiveOptionValue };
    }
    removeSelectedFilterByFilterOptionModel(filterOption, filter, filterGroup) {
        return { filterOption, filter, filterGroup };
    }
};
__decorate([
    Prop({ default: () => [] })
], PageAllFiltersSelected.prototype, "filterGroups", void 0);
__decorate([
    Prop({ default: () => { } })
], PageAllFiltersSelected.prototype, "totalSelectedFilters", void 0);
__decorate([
    Prop({ default: () => [] })
], PageAllFiltersSelected.prototype, "exclusiveOptions", void 0);
__decorate([
    Prop({ default: 0 })
], PageAllFiltersSelected.prototype, "resultTotalSelections", void 0);
__decorate([
    Prop({ default: '' })
], PageAllFiltersSelected.prototype, "selectedFilteredType", void 0);
__decorate([
    Prop({ default: false })
], PageAllFiltersSelected.prototype, "departmentAndLevelIsSelected", void 0);
__decorate([
    Emit('closeAllSelectedFilters')
], PageAllFiltersSelected.prototype, "closeAllSelectedFilters", null);
__decorate([
    Emit('resetAllFilters')
], PageAllFiltersSelected.prototype, "resetAllFilters", null);
__decorate([
    Emit('removeLogicAndOR')
], PageAllFiltersSelected.prototype, "removeLogicAndOR", null);
__decorate([
    Emit('removeSelectedFilter')
], PageAllFiltersSelected.prototype, "removeSelectedFilter", null);
__decorate([
    Emit('removeSelectedFilterByFilterOptionModel')
], PageAllFiltersSelected.prototype, "removeSelectedFilterByFilterOptionModel", null);
PageAllFiltersSelected = __decorate([
    Component({
        components: {
            SelectedLabelFilter,
        },
    })
], PageAllFiltersSelected);
export default PageAllFiltersSelected;
