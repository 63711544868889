var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
let ExpansiveListItem = class ExpansiveListItem extends Vue {
    constructor() {
        super(...arguments);
        this.expansive = false;
        this.checkboxValue = false;
        this.radioValue = true;
        this.selectedItems = [];
    }
    mounted() {
        this.$emit('selectedFilters', this.contents);
    }
    selectedItem(option) {
        this.radioValue = true;
        return option.type;
    }
    selectedFilters(label, value) {
        if (label == 'Selecionar todos') {
            this.contents.forEach(item => {
                if (item.label !== 'Razão Social') {
                    item.value = value;
                }
            });
        }
        else {
            this.contents.forEach(item => {
                if (item.label == label) {
                    item.value = value;
                    return;
                }
            });
        }
        return this.contents;
    }
    handleDisable(title) {
        return (this.decisors == 0 &&
            (title == 'Nível de decisores' || title == 'Área dos decisores'));
    }
};
__decorate([
    Prop({ default: false })
], ExpansiveListItem.prototype, "typeCheckbox", void 0);
__decorate([
    Prop({ default: () => [] })
], ExpansiveListItem.prototype, "contents", void 0);
__decorate([
    Prop({ default: '' })
], ExpansiveListItem.prototype, "title", void 0);
__decorate([
    Prop({ default: 0 })
], ExpansiveListItem.prototype, "decisors", void 0);
__decorate([
    Emit('selectedItem')
], ExpansiveListItem.prototype, "selectedItem", null);
__decorate([
    Emit('selectedFilters')
], ExpansiveListItem.prototype, "selectedFilters", null);
ExpansiveListItem = __decorate([
    Component({
        name: 'ExpansiveListItem',
    })
], ExpansiveListItem);
export default ExpansiveListItem;
