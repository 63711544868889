var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import SearchExportModalContainer from '@/modules/search/containers/SearchExportModal.container.vue';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { SearchStatus, ExportType } from '@/overmind/search/state';
import SearchStatsHeaderSkeleton from './SearchStatsHeaderSkeleton.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { overmind } from '@/overmind';
let SearchStatsHeader = class SearchStatsHeader extends Vue {
    constructor() {
        super(...arguments);
        this.toggle = false;
        this.emptyText = 'Sem dados para exibir';
        this.noCreditsText = 'Ops você não possui créditos suficientes';
        this.importationModal = false;
    }
    mounted() {
        overmind.reaction(({ search }) => search.stateModal, stateModal => (this.importationModal = stateModal));
    }
    disableBtn() {
        const sankhyaEmailDomain = this.userEmail.match('@sankhya.com.br');
        return ((sankhyaEmailDomain &&
            ['prospector', 'coordinator'].includes(this.userRole)) ||
            this.companies.length === 0);
    }
    userNoPermitted() { }
    onadvancedChange(currVal) {
        this.toggle = currVal;
    }
    setAdvancedCharts(value) {
        mixpanelTracking('search: Abriu gráficos avançados');
        return value;
    }
    setExportModal() {
        this.$emit('fetchAllInfosets');
        this.$emit('openExportModal', { value: true, type: ExportType.NORMAL });
    }
    formatNumber(number) {
        if (!number)
            return 0;
        return number.toLocaleString('pt-BR', { minimumIntegerDigits: 1 });
    }
};
__decorate([
    Prop({ default: () => [] })
], SearchStatsHeader.prototype, "infosets", void 0);
__decorate([
    Prop({ default: () => { } })
], SearchStatsHeader.prototype, "exclusiveOption", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "userEmail", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "userRole", void 0);
__decorate([
    Prop({ default: false })
], SearchStatsHeader.prototype, "emptyReturn", void 0);
__decorate([
    Prop({ default: () => { } })
], SearchStatsHeader.prototype, "summary", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchStatsHeader.prototype, "companies", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchStatsHeader.prototype, "selectedCompanies", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "currentInfosetId", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "advanced", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "credits", void 0);
__decorate([
    Prop({ default: '' })
], SearchStatsHeader.prototype, "exportType", void 0);
__decorate([
    Prop({ default: false })
], SearchStatsHeader.prototype, "noCredits", void 0);
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], SearchStatsHeader.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], SearchStatsHeader.prototype, "exportState", void 0);
__decorate([
    Prop({ default: 1 })
], SearchStatsHeader.prototype, "page", void 0);
__decorate([
    Prop({ default: 0 })
], SearchStatsHeader.prototype, "returnSize", void 0);
__decorate([
    Prop({ default: 0 })
], SearchStatsHeader.prototype, "exportedCompanies", void 0);
__decorate([
    Watch('advanced')
], SearchStatsHeader.prototype, "onadvancedChange", null);
__decorate([
    Emit('setAdvancedCharts')
], SearchStatsHeader.prototype, "setAdvancedCharts", null);
SearchStatsHeader = __decorate([
    Component({
        name: 'SearchStatsHeader',
        components: {
            SearchNotFoundHeader,
            SearchStatsHeaderSkeleton,
            SearchExportModalContainer,
        },
    })
], SearchStatsHeader);
export default SearchStatsHeader;
