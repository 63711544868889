var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import AdvancedFiltersModal from '../components/AdvancedFiltersModal.vue';
import { Component, Vue, PropSync, Emit, Watch } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { json } from 'overmind';
import { SearchStatus } from '@/overmind/search/state';
let AdvancedFilterContainer = class AdvancedFilterContainer extends Vue {
    constructor() {
        super(...arguments);
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.dispose = () => { };
    }
    newFiltersGroups() {
        // @ts-ignore TS2349: property inexistent
        return json(this.filterGroups);
    }
    onShowModalChange(val, oldVal) {
        if (val == false) {
            // @ts-ignore TS2349: property inexistent
            this.actions.resetFilterGroups();
            // @ts-ignore TS2349: property inexistent
            this.actions.setSearchTerm('');
        }
    }
    searchFilters(searchTerm) {
        // @ts-ignore TS2349: property inexistent
        this.actions.searchFilterGroups(searchTerm);
    }
    selectAllFilterOptions({ currentFilterIndex, filterOptions, filterGroupId, filterId, selectAll, }) {
        const selectedCheckboxGroup = json(
        // @ts-ignore TS2349: property inexistent
        this.selectedCheckbox.find(ch => ch.filterGroupId === filterGroupId)) || {};
        const selectedCheckboxValues = selectedCheckboxGroup.filterOptions || [];
        const filterOptionsNotSelected = filterOptions.filter(f => !selectedCheckboxValues.includes(f.value));
        const filtersInformations = filterOptionsNotSelected.map(f => ({
            filterGroupId,
            filterId,
            filterIndex: currentFilterIndex,
            filterOptionIndex: 0,
            value: f.isSelected,
            itemId: f.value,
            filterSpecial: f.special,
        }));
        // @ts-ignore
        this.selectFilters({ filters: filtersInformations, selectAll });
        // @ts-ignore
        this.actionsSearch.searches.buildPotentialResults();
    }
    async setCheckbox({ filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId, }) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actions.updateFilterOption({
            filterGroupId,
            filterId,
            filterIndex,
            filterOptionIndex,
            value,
            itemId,
            // @ts-ignore TS2349: property inexistent
            filterSpecial: this.canSearch,
        });
    }
    setSelectedFilteredType(selectedFilteredType) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSelectedFilteredType(selectedFilteredType);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    handleActiveModalToRemoveAdvancedFilters() {
        //@ts-ignore TS2349: property inexistent
        this.actionsSearch.showAdvancedFilterModal({ show: false });
    }
    async createSimilarSearch() {
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.createSimilarSearch();
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.stateSearch.currentSearchId },
        })
            .catch(err => err);
    }
    setSelectedRadioButton(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setRadioButtonState(SearchStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        this.actions.setSelectedRadioButton(event);
    }
    toggleExclusiveOption(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actions.toggleExclusiveOption(event);
    }
    clearFilters() {
        // @ts-ignore TS2349: property inexistent
        this.actions.clearFilters();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setCanSearch(true);
    }
    setCnpjs(value) {
        if (value) {
            // @ts-ignore TS2349: property inexistent
            if (this.totalSelected.cnpjs.total === 0) {
                // @ts-ignore TS2349: property inexistent
                this.actions.addTotalSelected({ groupTag: 'cnpjs', queryField: '' });
            }
            // @ts-ignore TS2349: property inexistent
        }
        else if (this.totalSelected.cnpjs.total >= 1) {
            // @ts-ignore TS2349: property inexistent
            this.actions.removeTotalSelected({ groupTag: 'cnpjs', queryField: '' });
        }
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    setCnpjsRemoved(value) {
        if (value) {
            // @ts-ignore TS2349: property inexistent
            if (this.totalSelected.where_not.cnpjs === 0) {
                // @ts-ignore TS2349: property inexistent
                this.actions.addTotalSelected({
                    groupTag: 'where_not',
                    queryField: 'cnpjs@where_not',
                });
            }
            // @ts-ignore TS2349: property inexistent
        }
        else if (this.totalSelected.where_not.cnpjs >= 1) {
            // @ts-ignore TS2349: property inexistent
            this.actions.removeTotalSelected({
                groupTag: 'where_not',
                queryField: 'cnpjs@where_not',
            });
        }
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    setRemoveCompanyName(event) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setWhereNotNoEmpresa(event);
        //@ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    setDateValue(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actions.setFilterDate(event);
    }
    async createSearch() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore TS2349: property inexistent
        // this.actionsSearch.setShowCompany(false)
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.stateSearch.currentSearchId },
        })
            .catch(err => err);
        // @ts-ignore TS2349: property inexistent
        this.actions.resetFilterGroups();
        // @ts-ignore TS2349: property inexistent
        this.actions.setSearchTerm('');
    }
    setSelectedGPTKeyWord(event) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setSelectedFilterGPT(event);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    setInputFilter(event) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setInputFilters(event);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    resetFilters(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actions.resetStateFilters('');
        // @ts-ignore TS2349: property inexistent
        this.actions.getFilters();
    }
    resetSpecialFilters() {
        // @ts-ignore
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore
        this.actions.resetStateSpecialFilters();
        // @ts-ignore TS2349: property inexistent
        this.actions.resetFilterGroups();
        const activity_tab = document.querySelector('[data-cy="search-advanced-filters-tabs-list"]');
        const selected = document.querySelector('[aria-selected="true"]');
        // @ts-ignore TS2349: property inexistent
        if (selected && selected.textContent.toLowerCase().match('localização')) {
            // @ts-ignore TS2349: property inexistent
            activity_tab.click();
            setTimeout(() => {
                // @ts-ignore TS2349: property inexistent
                selected.click();
            }, 100);
        }
        // @ts-ignore TS2349: property inexistent
        this.actions.setSearchTerm('');
    }
    setSelectedFilterOracle(event) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setSelectedFilterOracle(event);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    setRemoveFilterOracle(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actions.setRemoveFilterOracle(event);
    }
    closeModal() {
        this.handleActiveModalToRemoveAdvancedFilters();
        // @ts-ignore
        this.actions.setChangedFilters(true);
        return false;
    }
};
__decorate([
    PropSync('open', { default: false })
], AdvancedFilterContainer.prototype, "showModal", void 0);
__decorate([
    Watch('showModal')
], AdvancedFilterContainer.prototype, "onShowModalChange", null);
__decorate([
    Emit('closeModal')
], AdvancedFilterContainer.prototype, "closeModal", null);
AdvancedFilterContainer = __decorate([
    Component(connect(({ state: { filters: state, search: stateSearch }, actions }) => ({
        selectedRadioButton: state.selectedRadioButton,
        selectedCheckbox: state.selectedCheckbox,
        filterGroups: state.filteredFilterGroups,
        actionsSearch: actions.search,
        actions: actions.filters,
        totalSelected: state.totalSelected,
        stateSearch: stateSearch,
        cnpjs: state.cnpjs,
        listCnpjs: state.listCnpjs,
        selectedFilteredType: state.selectedFilteredType,
        cnpjsDeleted: state.cnpjsDeleted,
        listExcludeCnpj: state.listExcludeCnpj,
        exclusiveOptions: state.exclusiveOptions,
        resultCalcute: stateSearch.resultCalcute,
        loadingPotentialResults: stateSearch.loadingPotentialResults,
        searchTerm: state.searchTerm,
        stateInputFilters: state.stateInputFilters,
        loadingFilter: stateSearch.loadingFilter,
        dateFilter: state.dateFilter,
        filterOracle: state.selectedFilterOracle,
        selectedGPTFilter: state.selectedGPTFilter,
        removeFilterOracle: state.removeFilterOracle,
        removeFilterCeps: state.removeFilterCeps,
        filterState: state.filterState,
        currentTab: stateSearch.currentTab,
        radioButtonState: stateSearch.radioButtonState,
        selectFilters: actions.filters.selectFilters,
        removeFilterCompanyName: state.removeEmpresa,
        canSearch: stateSearch.canSearch,
    }), {
        components: {
            AdvancedFiltersModal,
        },
        mounted() {
            this.dispose = this.overmind.reaction(({ filters: state }) => state.filteredFilterGroups, filteredFilterGroups => {
                /* this.newFiltersGroups = json(filteredFilterGroups); */
            }, { nested: true });
        },
        beforeDestroy() {
            this.dispose();
        },
    }))
], AdvancedFilterContainer);
export default AdvancedFilterContainer;
