var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
import IconCancel from '@/components/Icons/IconCancel.vue';
let InsufficientCreditsModal = class InsufficientCreditsModal extends Vue {
    closeModal() {
        return true;
    }
};
__decorate([
    Prop({ default: 0 })
], InsufficientCreditsModal.prototype, "credits", void 0);
__decorate([
    Emit('closeModal')
], InsufficientCreditsModal.prototype, "closeModal", null);
InsufficientCreditsModal = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.search,
    }), {
        name: 'InsufficientCreditsModal',
        components: {
            IconCancel,
        },
    }))
], InsufficientCreditsModal);
export default InsufficientCreditsModal;
