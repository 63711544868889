var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { json } from 'overmind';
let SearchNotFoundResults = class SearchNotFoundResults extends Vue {
    constructor() {
        super(...arguments);
        this.termNotFound = json(this.searchTerm);
        this.filtersSelectedNotFound = json(this.filtersSelected);
    }
    onFiltersSelectedChange(newTotal, _oldTotal) {
        this.filtersSelectedNotFound = newTotal;
    }
    createNewSearch() {
        return true;
    }
    openModalAdvancedFilters() {
        return true;
    }
    get handleTermNotFound() {
        return this.termNotFound.length > 20
            ? this.termNotFound.slice(0, 20).concat('...')
            : this.termNotFound;
    }
    get handleFiltersSelectedNotFound() {
        return this.filtersSelectedNotFound > 1
            ? 'redefina os filtros!'
            : 'redefina o filtro!';
    }
    get messageFilterNotFound() {
        if (this.filtersSelectedNotFound === 1)
            return '"redefina o filtro selecionado!"';
        else
            return '"redefina os filtros selecionados!"';
    }
    get messageFiltersNotFound() {
        if (this.filtersSelectedNotFound === 1)
            return `No momento há ${this.filtersSelectedNotFound} filtro selecionado, por favor`;
        else
            return `No momento estão selecionados ${this.filtersSelectedNotFound} filtros, por favor`;
    }
};
__decorate([
    Prop({ default: '' })
], SearchNotFoundResults.prototype, "message", void 0);
__decorate([
    Prop({ default: false })
], SearchNotFoundResults.prototype, "image", void 0);
__decorate([
    Prop({ default: '500px' })
], SearchNotFoundResults.prototype, "widthImg", void 0);
__decorate([
    Prop({ default: '' })
], SearchNotFoundResults.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: 0 })
], SearchNotFoundResults.prototype, "filtersSelected", void 0);
__decorate([
    Watch('filtersSelected')
], SearchNotFoundResults.prototype, "onFiltersSelectedChange", null);
__decorate([
    Emit('createNewSearch')
], SearchNotFoundResults.prototype, "createNewSearch", null);
__decorate([
    Emit('openModalAdvancedFilters')
], SearchNotFoundResults.prototype, "openModalAdvancedFilters", null);
SearchNotFoundResults = __decorate([
    Component({
        name: 'SearchNotFoundResults',
    })
], SearchNotFoundResults);
export default SearchNotFoundResults;
