var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconRdStation from '@/components/Autoplay/icons/IconRdStation.vue';
import IconHubspot from '@/components/Autoplay/icons/IconHubspot.vue';
import IconPipedrive from '@/components/Autoplay/icons/IconPipedrive.vue';
import IconDoc from '@/components/Autoplay/icons/IconDoc.vue';
import IconCompanies from '@/components/Autoplay/icons/IconCompanies.vue';
import IconMakeDecisors from '@/components/Autoplay/icons/IconMakeDecisors.vue';
import ExpansiveListItem from '@/components/Autoplay/ExpansiveListItem.vue';
import { getfieldFilters } from '@/shared/utils/Infosets.utils';
import { mixpanelTracking } from '@/services/mixpanel';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import InfosetExportErrorMessage from 'src/modules/infosets/components/Exportations/InfosetExportErrorMessage.vue';
import { connect } from '@/overmind';
let ExportListModalContent = class ExportListModalContent extends Vue {
    constructor() {
        super(...arguments);
        this.open = true;
        this.expansive = false;
        this.saveConfig = false;
        this.exportListBy = 'companies';
        this.exportType = '';
        this.fieldsToExportSelected = {};
        this.levelDecisionSelected = {};
        this.departmentDecisionSelected = {};
        this.exportTypeValues = [
            {
                label: 'Uma empresa por linha',
                type: 'companies',
                value: true,
            },
            {
                label: 'Um decisor por linha',
                type: 'decisors',
                value: false,
            },
            {
                label: 'Um e-mail por linha',
                type: 'emails',
                value: false,
            },
        ];
        this.fieldsToExport = [];
        this.levelDecision = [
            { label: 'Selecionar todos', value: false },
            { label: 'Não classificado', value: false },
            { label: 'Alto', value: false },
            { label: 'Médio', value: false },
            { label: 'Baixo', value: false },
        ];
        this.departamentDecision = [
            { label: 'Selecionar todos', value: false },
            { label: 'Administrativo', value: false },
            { label: 'Comercial', value: false },
            { label: 'Financeiro', value: false },
            { label: 'Gestão', value: false },
            { label: 'Industrial', value: false },
            { label: 'Jurídico', value: false },
            { label: 'Marketing', value: false },
            { label: 'Proprietários', value: false },
            { label: 'Recursos Humanos', value: false },
            { label: 'Suprimento', value: false },
            { label: 'TI', value: false },
            { label: 'Operacional', value: false },
            { label: 'Outros', value: false },
        ];
        this.totalEmployees = 0;
        this.data = { Idhistory: '', selectedCount: 0 };
        this.load = false;
    }
    handleDisable() {
        if (this.menssageErrorvisible())
            return true;
        const csvOrExcel = this.crm == 'csv' || this.crm == 'excel';
        if (csvOrExcel) {
            return this.exportListBy == '';
        }
    }
    async mounted() {
        this.data.selectedCount = this.selectedCount;
        this.data.Idhistory = this.history.id ? this.history.id : '';
        this.load = false;
        //@ts-ignore
        const res = await this.actionsSearch.getDecisorsHistorySearch(this.data);
        this.totalEmployees = res.data.value;
        this.load = true;
        mixpanelTracking(`autoplay: Abriu as configurações de exportação para ${this.crm}`);
        this.getSheetFields();
        if (!this.configs)
            return;
        // @ts-ignore
        this.saveConfig = this.configs.keep_redundancy;
        // @ts-ignore
        this.fieldsToExportSelected = this.configs.fields;
        let newFieldsToExport = [];
        // @ts-ignore
        for (const label in this.configs.fields) {
            // @ts-ignore
            const value = this.configs.fields[label];
            //@ts-ignore
            newFieldsToExport.push({ label: label, value: value });
        }
        // @ts-ignore
        this.fieldsToExport = newFieldsToExport;
        // @ts-ignore
        Object.entries(this.configs.filters).forEach(([key, filter]) => {
            // @ts-ignore
            this.levelDecision = this.levelDecision.map(level => {
                if (level.label == key) {
                    //@ts-ignore
                    level.value = filter;
                }
                //@ts-ignore
                return { label: level.label, value: level.value };
            });
            // @ts-ignore
            this.departamentDecision = this.levelDecision.map(departament => {
                if (departament.label == key) {
                    //@ts-ignore
                    departament.value = filter;
                }
                //@ts-ignore
                return { label: departament.label, value: departament.value };
            });
        });
        // @ts-ignore
        this.exportTypeValues = this.exportTypeValues.map(content => {
            // @ts-ignore
            if (content.type == this.configs.service.split('-')[1]) {
                content.value = true;
            }
            return content;
        });
        // @ts-ignore
        this.setExportListBy(this.configs.service.split('-')[1]);
    }
    setExportListBy(value) {
        this.exportTypeValues.map(exportValue => {
            exportValue.value = false;
            if (exportValue.type == value) {
                exportValue.value = true;
            }
            return exportValue;
        });
        this.exportListBy = value;
    }
    menssageErrorvisible() {
        const csvOrExcel = this.crm == 'csv' || this.crm == 'excel';
        if (this.totalEmployees > 10000 && !csvOrExcel) {
            return true;
        }
        else if (csvOrExcel && this.totalEmployees > 100000) {
            return true;
        }
        else {
            return false;
        }
    }
    setFieldsToExport(value) {
        const fields = {};
        for (let i = 0; i < value.length; i++) {
            const item = value[i];
            fields[item.label] = item.value;
        }
        this.fieldsToExportSelected = fields;
    }
    setLevelDecision(value) {
        const fields = {};
        for (let i = 0; i < value.length; i++) {
            const item = value[i];
            fields[item.label] = item.value;
        }
        this.levelDecisionSelected = fields;
    }
    setDepartamentDecision(value) {
        const fields = {};
        for (let i = 0; i < value.length; i++) {
            const item = value[i];
            fields[item.label] = item.value;
        }
        this.departmentDecisionSelected = fields;
    }
    getSheetFields() {
        const crm = ['excel', 'csv'].includes(this.crm)
            ? 'sheet'
            : this.crm == 'rd station'
                ? 'rdstation'
                : this.crm;
        this.fieldsToExport = getfieldFilters(crm).map(r => {
            return { label: r, value: true };
        });
        this.fieldsToExport.unshift({ label: 'Selecionar todos', value: true });
    }
    saveInformation() {
        mixpanelTracking(`autoplay: Salvou a configuração da exportação automática para ${this.crm}`);
        const service_name = this.crm.replaceAll(' ', '');
        const exportation_configs = {
            [service_name]: {
                fields: this.fieldsToExportSelected,
                filters: {
                    ...this.levelDecisionSelected,
                    ...this.departmentDecisionSelected,
                },
                service: `${service_name}-${this.exportListBy}`,
                keep_redundancy: this.saveConfig,
            },
        };
        this.open = false;
        return exportation_configs;
    }
    cancelInformations() {
        mixpanelTracking(`autoplay: Cancelou a configuração da exportação automática para ${this.crm}`);
        this.open = false;
    }
};
__decorate([
    Prop({ default: '' })
], ExportListModalContent.prototype, "crm", void 0);
__decorate([
    Prop({ default: '' })
], ExportListModalContent.prototype, "crmTitle", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportListModalContent.prototype, "configs", void 0);
__decorate([
    Prop({ default: () => { } })
], ExportListModalContent.prototype, "history", void 0);
__decorate([
    Prop({ default: null })
], ExportListModalContent.prototype, "selectedCount", void 0);
__decorate([
    Emit('saveInformation')
], ExportListModalContent.prototype, "saveInformation", null);
__decorate([
    Emit('cancelInformations')
], ExportListModalContent.prototype, "cancelInformations", null);
ExportListModalContent = __decorate([
    Component(connect(({ state, actions }) => ({
        actionsSearch: actions.search,
    }), {
        name: 'ExportListModalContent',
        components: {
            IconRdStation,
            IconCompanies,
            IconMakeDecisors,
            ExpansiveListItem,
            IconHubspot,
            IconPipedrive,
            IconDoc,
            InfosetExportErrorMessage,
            IconSpeedioSpinner,
        },
    }))
], ExportListModalContent);
export default ExportListModalContent;
