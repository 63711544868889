var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
let ManagementFeature = class ManagementFeature extends Vue {
    constructor() {
        super(...arguments);
        this.chartOptions = {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },
            xAxis: {
                title: '',
                categories: [
                    '1 a 80K',
                    '80k +',
                    '360k +',
                    '1M +',
                    '5M +',
                    '10M +',
                    '30M +',
                    '100M +',
                    '500M +',
                ],
            },
            yAxis: {
                title: '',
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    pointPadding: -0.2,
                },
            },
            series: [
                {
                    name: '',
                    // @ts-ignore TS2349: property inexistent
                    data: json(this.managementFeature),
                    color: '#3f48cc',
                },
            ],
            credits: false,
        };
    }
    managementFeatureChanged(current, old) {
        this.chartOptions.series[0].data = json(current);
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], ManagementFeature.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], ManagementFeature.prototype, "managementFeature", void 0);
__decorate([
    Prop({ default: 1 })
], ManagementFeature.prototype, "page", void 0);
__decorate([
    Watch('managementFeature')
], ManagementFeature.prototype, "managementFeatureChanged", null);
ManagementFeature = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], ManagementFeature);
export default ManagementFeature;
