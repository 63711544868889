var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { uniq, isEmpty } from 'lodash';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
let SearchFilterNegativeCnpjs = class SearchFilterNegativeCnpjs extends Vue {
    constructor() {
        super(...arguments);
        this.selectedValues = [];
        this.options = [];
    }
    addCnpj(text) {
        text.split(' ').forEach(spaceSplit => {
            spaceSplit.split(',').forEach(commaSplit => {
                commaSplit.split(';').forEach(semicolonSplit => {
                    semicolonSplit.split('\n').forEach(tag => {
                        const onlyNumbers = tag.replace(/\D+/g, '');
                        if (!onlyNumbers)
                            return false;
                        const cnpjs = this.lJustify(onlyNumbers, 14, '0');
                        if (!this.selectedValues.includes(cnpjs)) {
                            this.selectedValues.push(cnpjs);
                        }
                    });
                });
            });
        });
        this.selectedValues = this.sanitizeCnpjs(this.selectedValues);
        this.options = this.selectedValues;
        if (this.selectedValues.length > 0) {
            this.$emit('setNegativeCnpjs', this.selectedValues);
        }
    }
    removedCnpj(cnpjs) {
        this.selectedValues = cnpjs;
        this.options = cnpjs;
        this.$emit('setNegativeCnpjs', this.selectedValues);
    }
    lJustify(str, targetLength, paddingString = ' ') {
        let newString = str;
        while (newString.length < targetLength) {
            newString = paddingString + newString;
        }
        return newString;
    }
    sanitizeCnpjs(cnpjs) {
        return uniq(cnpjs).filter(cnpj => !isEmpty(cnpj));
    }
    onNegativeCnpjsChange(curr) {
        if (!isEmpty(curr)) {
            this.selectedValues = curr;
            this.options = curr;
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], SearchFilterNegativeCnpjs.prototype, "negativeCnpjs", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterNegativeCnpjs.prototype, "showHeaderTable", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterNegativeCnpjs.prototype, "loadingGetCnpjs", void 0);
__decorate([
    Prop({ default: '' })
], SearchFilterNegativeCnpjs.prototype, "userRole", void 0);
__decorate([
    Watch('negativeCnpjs')
], SearchFilterNegativeCnpjs.prototype, "onNegativeCnpjsChange", null);
SearchFilterNegativeCnpjs = __decorate([
    Component({
        name: 'SearchFilterNegativeCnpjs',
        components: { Multiselect },
    })
], SearchFilterNegativeCnpjs);
export default SearchFilterNegativeCnpjs;
