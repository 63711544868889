var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { mixpanelUserSetData } from '@/services/mixpanel';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import Banner from '@/components/Banner/Banner.vue';
import LoadingDots from '@/components/LoadingDots.vue';
import { isEmpty } from 'lodash';
let OnboardingUpdateInfoForm = class OnboardingUpdateInfoForm extends Vue {
    constructor() {
        super(...arguments);
        this.emptyFieldsErrorMessage = false;
        this.whatsaapNumberActually = '';
        this.fieldsValidation = {
            full_name: false,
            whatsapp_number: false,
        };
        this.inputValues = {
            full_name: '',
            whatsapp_number: '',
        };
        this.inputMasks = {
            whatsapp_number: '+55 (##) # ####-####',
        };
    }
    mounted() {
        this.updateFormFields(this.formData);
        if (this.cacheData && !isEmpty(this.cacheData)) {
            this.inputValues = {
                full_name: this.cacheData.full_name,
                whatsapp_number: '',
            };
        }
    }
    onInput(val) {
        if (val !== this.whatsaapNumberActually) {
            this.fieldsValidation.whatsapp_number = false;
            this.errorWhatsappNumber = false;
        }
        this.fieldsValidation = {
            full_name: false,
            whatsapp_number: false,
        };
        this.emptyFieldsErrorMessage = false;
    }
    whatsappMask(input) {
        const number = this.inputValues.whatsapp_number;
        const replace = number.replace(/\D/g, '');
        return (input == 'whatsapp_number' &&
            (replace.length < 13 ? '+55 (##) ####-#####' : '+55 (##) # ####-####'));
    }
    get renderInputsFields() {
        return ['full_name', 'whatsapp_number'];
    }
    getFormData(target) {
        const data = {};
        Object.values(target).forEach(el => {
            if (el && el.type !== 'submit' && el.value !== undefined) {
                data[el.name] = el.value;
            }
        });
        return {
            ...data,
            flags: [
                'primeira_busca_realizada',
                'primeira_exportacao_interna_realizada',
            ],
        };
    }
    getEmptyFields(data) {
        const baseFields = ['full_name', 'whatsapp_number'];
        const requiredFields = [...baseFields];
        return requiredFields.some(field => this.fieldsValidation[field]);
    }
    setFieldValidation(field, data) {
        const isFieldEmpty = field => !data[field];
        const validationConditions = {
            full_name: isFieldEmpty,
            whatsapp_number: isFieldEmpty,
        };
        if (field in validationConditions && validationConditions[field](field)) {
            this.fieldsValidation[field] = true;
        }
    }
    handleSubmit(e) {
        this.emptyFieldsErrorMessage = false;
        const target = e.target;
        const data = this.getFormData(target);
        const emptyFields = this.getEmptyFields(data);
        if (isEmpty(this.cacheData) && emptyFields) {
            return (this.emptyFieldsErrorMessage = emptyFields);
        }
        this.whatsaapNumberActually = data['whatsapp_number'];
        this.$emit('updateFormValidate', data);
        this.setDataInMixPanel(data);
    }
    setDataInMixPanel(data) {
        const { flags, ...dataWithoutFlags } = data;
        const renamedKeysData = {};
        for (const key in dataWithoutFlags) {
            switch (key) {
                default:
                    renamedKeysData[`$${key}`] = dataWithoutFlags[key];
            }
        }
        mixpanelUserSetData(renamedKeysData);
    }
    updateFormFields(data) {
        for (let each in data) {
            this.inputValues[each] = data[each];
        }
    }
    get disableSubmitBtn() {
        return [
            this.inputValues.full_name,
            this.inputValues.whatsapp_number,
        ].includes('');
    }
};
__decorate([
    PropSync('data', { default: () => { } })
], OnboardingUpdateInfoForm.prototype, "formData", void 0);
__decorate([
    Prop({ default: () => { } })
], OnboardingUpdateInfoForm.prototype, "cacheData", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUpdateInfoForm.prototype, "loadingUpdateForm", void 0);
__decorate([
    Prop({ default: false })
], OnboardingUpdateInfoForm.prototype, "smallFormFields", void 0);
__decorate([
    PropSync('validateErrorWhatsappNumber', { default: false })
], OnboardingUpdateInfoForm.prototype, "errorWhatsappNumber", void 0);
__decorate([
    PropSync('token')
], OnboardingUpdateInfoForm.prototype, "errorToken", void 0);
OnboardingUpdateInfoForm = __decorate([
    Component({
        name: 'OnboardingUpdateInfoForm',
        components: {
            Banner,
            LoadingDots,
        },
    })
], OnboardingUpdateInfoForm);
export default OnboardingUpdateInfoForm;
