var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, PropSync, Vue, } from 'vue-property-decorator';
import IconAutoPlay from '@/components/Autoplay/icons/IconAutoPlay.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import { showNotify } from '@/shared/utils/helpers';
let SearchSaveModal = class SearchSaveModal extends Vue {
    constructor() {
        super(...arguments);
        this.loadingSaveModal = false;
        this.saveSearchName = '';
        this.infoHistory = { nameHistory: '', totalSelcted: 0 };
        this.totalSelcted = 0;
    }
    exitWithoutSave() {
        return true;
    }
    notShowAnymore() {
        const data = new Date();
        data.setDate(data.getDate() + 60);
        localStorage.setItem('notShowSaveSearch', data.toISOString());
        this.$emit('exitWithoutSave');
    }
    async handleSaveHistory() {
        //@ts-ignore
        this.totalSelcted = Object.values(this.filterState).reduce((acc, obj) => {
            if (obj && typeof obj === 'object' && 'total' in obj) {
                //@ts-ignore
                return acc + obj.total;
            }
            return acc;
        }, 0);
        if (this.saveSearchName && this.saveSearchName.replace(/\s+/g, '')) {
            this.infoHistory.nameHistory = this.saveSearchName;
            this.infoHistory.totalSelcted = this.totalSelcted;
            mixpanelTracking('search: Salvou a busca pelo modal de transição do search');
            await this.saveHistory(this.infoHistory);
        }
    }
    async saveHistory(infoHistory) {
        this.loadingSaveModal = true;
        // @ts-ignore TS2349: property inexistent
        if (this.currentId == '') {
            showNotify('Antes de salvar uma nova busca, certifique-se de já ter realizado uma busca!!', 'warning');
        }
        else if (
        //@ts-ignore TS2339: Property 'nameHistory' does not exist on type 'object'.
        !!infoHistory.nameHistory && //@ts-ignore TS2339: Property 'nameHistory' does not exist on type 'object'.
            typeof infoHistory.nameHistory === 'string') {
            const newSearchHistory = {
                //@ts-ignore TS2339: Property 'nameHistory' does not exist on type 'object'.
                name: infoHistory.nameHistory,
                // @ts-ignore TS2349: property inexistent
                totalResults: parseInt(this.summary.totalResult),
                // @ts-ignore TS2349: property inexistent
                totalEmployees: parseInt(
                // @ts-ignore TS2349: property inexistent
                this.advancedGraphics.totalDecisionMakers),
                // @ts-ignore TS2349: property inexistent
                searchId: this.currentId,
                //@ts-ignore
                selected_filters: infoHistory.totalSelcted,
            };
            // @ts-ignore TS2349: property inexistent
            await this.actions.searchHistory.createSearchHistory(newSearchHistory);
            // @ts-ignore TS2349: property inexistent
            this.actions.setSearchFromHistory(true);
            // @ts-ignore TS2349: property inexistent
            this.actions.setShowMakeDecision(false);
            this.$emit('saveHistory', true);
        }
        this.loadingSaveModal = false;
    }
    showSearchHistory() {
        this.show = false;
        //@ts-ignore
        this.actions.toggleShowHistory();
        mixpanelTracking('autoplay: Abriu autoplay pela transição de tela do search');
    }
    closeModal() {
        this.show = false;
    }
    parseToFloat() {
        return this.resultCalcute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    mounted() {
        mixpanelTracking('search: Abriu modal para salvar a busca.');
    }
};
__decorate([
    PropSync('open', { default: false })
], SearchSaveModal.prototype, "show", void 0);
__decorate([
    Prop({ default: true })
], SearchSaveModal.prototype, "hasSearchHistory", void 0);
__decorate([
    Prop({ default: 0 })
], SearchSaveModal.prototype, "resultCalcute", void 0);
__decorate([
    Prop({ default: 0 })
], SearchSaveModal.prototype, "totalSelected", void 0);
__decorate([
    Emit('exitWithoutSave')
], SearchSaveModal.prototype, "exitWithoutSave", null);
__decorate([
    Emit('closeModal')
], SearchSaveModal.prototype, "closeModal", null);
SearchSaveModal = __decorate([
    Component(connect(({ actions, state }) => ({
        filterState: state.filters.totalSelected,
        showAutoPlay: state.search.showAutoPlay,
        actions: actions.search,
        histories: state.search.histories,
        advancedGraphics: state.search.advancedGraphics,
        currentId: state.search.currentSearchId,
        summary: state.search.summary,
    }), {
        name: 'SearchSaveModal',
        components: {
            IconAutoPlay,
            IconSpeedioSpinner,
        },
    }))
], SearchSaveModal);
export default SearchSaveModal;
