var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
let Banner = class Banner extends Vue {
    actionBanner() {
        this.$emit('actionBanner');
    }
    closeBanner() {
        this.$emit('closeBanner');
    }
};
__decorate([
    Prop({ default: () => { } })
], Banner.prototype, "progress", void 0);
__decorate([
    Prop({ default: () => { } })
], Banner.prototype, "show", void 0);
__decorate([
    Prop({ default: () => { } })
], Banner.prototype, "label", void 0);
__decorate([
    Prop({ default: () => { } })
], Banner.prototype, "description", void 0);
__decorate([
    Prop({ default: () => '#c8f2fc' })
], Banner.prototype, "bgColor", void 0);
__decorate([
    Prop({ default: () => '#14AAFF' })
], Banner.prototype, "btnColor", void 0);
__decorate([
    Prop({ default: () => '#5f6a6c' })
], Banner.prototype, "btnTextColor", void 0);
Banner = __decorate([
    Component(connect(({ state, actions }) => ({
        userActions: actions.users,
        hasUpdate: state.users.hasUpdate,
    }), {
        name: 'Banner',
    }))
], Banner);
export default Banner;
