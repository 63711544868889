var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import SearchExportModal from '@/modules/search/components/SearchExportModal/SearchExportModal.vue';
import { json } from 'overmind';
import { connect } from '@/overmind';
import { SearchStatus } from '@/overmind/search/state';
import { mixpanelTracking } from '@/services/mixpanel';
let SearchExportModalContainer = class SearchExportModalContainer extends Vue {
    constructor() {
        super(...arguments);
        this.autoplayActive = false;
    }
    async mounted() {
        // @ts-ignore
        await this.actionsSearch.getExportedCompaniesCount();
    }
    async beforeDestroy() {
        // @ts-ignore
        this.actionsInfosets.setPage(1);
    }
    infosetsList() {
        // @ts-ignore
        return json(Object.values(json(this.stateInfosets.infosets)));
    }
    getExclusiveOption() {
        // @ts-ignore TS2349: property inexistent
        if (this.exclusiveOptions) {
            // @ts-ignore TS2349: property inexistent
            const filter = this.exclusiveOptions.find((item) => item.value === 'eliminate_cnpjs_already_exported_in_account');
            // @ts-ignore TS2349: property inexistent
            const index = this.exclusiveOptions.findIndex((item) => item.value === 'eliminate_cnpjs_already_exported_in_account');
            return { filter, index };
        }
    }
    async getSearchExport(values) {
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.exportations.getSearchExport_(values);
        // await this.actionsSearch.exportations.getSearchExport(values)
    }
    toggleModalExport(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.toggleModalExport(value);
        if (value === false) {
            mixpanelTracking('Fechou exportação para lista');
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.statusTransitionExport(SearchStatus.IDLE);
        }
        else {
            mixpanelTracking('exportação interna: Abriu exportação para lista');
        }
    }
    setReturnSize(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setReturnSize(value);
    }
    toggleExclusiveOption(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.toggleExclusiveOption(event);
    }
    async getNextInfosetPage({ page, searchTerm, }) {
        // @ts-ignore
        await this.actionsInfosets.fetchAllInfosets({
            page,
            aggregateToList: true,
            query: searchTerm,
        });
    }
    async searchInfosets(term) {
        // @ts-ignore
        await this.actionsInfosets.fetchAllInfosets({
            page: 1,
            aggregateToList: false,
            query: term,
        });
    }
    async createInfoset(infosetName, autoplayActive = false) {
        this.autoplayActive = autoplayActive;
        // @ts-ignore
        await this.actionsInfosets.createInfoset({
            name: infosetName,
            autoplayActive: autoplayActive,
        });
    }
    async getExportedCompaniesCount() { }
};
SearchExportModalContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateSearch: state.search,
        stateInfosets: state.infosets,
        exclusiveOptions: state.filters.exclusiveOptions,
        credits: state.search.exportCredits,
        stateModal: state.search.stateModal,
        returnSize: state.search.returnSize,
        exportType: state.search.exportType,
        infosetId: state.search.infosetId,
        exportedCompanies: state.search.exportedCompanies,
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
        actionsInfosets: actions.infosets,
    }), {
        components: {
            SearchExportModal,
        },
    }))
], SearchExportModalContainer);
export default SearchExportModalContainer;
