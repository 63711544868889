var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { json } from 'overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let Neighborhoods = class Neighborhoods extends Vue {
    constructor() {
        super(...arguments);
        this.neighborhoodsName = json(this.selectedNeighborhoods);
    }
    setNeighborhoodsName({ value }, type) {
        return { value, type };
    }
    handleTrackingNeighborhoods() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: () => [] })
], Neighborhoods.prototype, "selectedNeighborhoods", void 0);
__decorate([
    Prop({ default: '' })
], Neighborhoods.prototype, "filterName", void 0);
__decorate([
    Prop({ default: '' })
], Neighborhoods.prototype, "info", void 0);
__decorate([
    Emit('setNeighborhoodsName')
], Neighborhoods.prototype, "setNeighborhoodsName", null);
Neighborhoods = __decorate([
    Component({})
], Neighborhoods);
export default Neighborhoods;
