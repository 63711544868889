var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
let EconomicActivity = class EconomicActivity extends Vue {
    constructor() {
        super(...arguments);
        this.keys = this.economicActivity.map(item => item.key);
        this.values = this.economicActivity.map(item => {
            return {
                name: item.key,
                y: item.doc_count,
            };
        });
        this.chartOptions = {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
            },
            xAxis: {
                title: '',
                categories: json(this.keys),
            },
            yAxis: {
                title: '',
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    pointPadding: -0.2,
                },
            },
            tooltip: {
                headerFormat: '<span>{series.name}</span>',
                pointFormat: '<span style="font-weight: bold">{point.name}</span>: {point.y}',
            },
            series: [
                {
                    name: '',
                    data: json(this.values),
                    color: '#339FFF',
                },
            ],
            credits: false,
        };
    }
    economicActivityChanged(current) {
        if (current) {
            this.chartOptions.xAxis.categories = current.map(item => item.key);
            this.chartOptions.series[0].data = current.map(item => {
                return {
                    name: item.key,
                    y: item.doc_count,
                };
            });
        }
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], EconomicActivity.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], EconomicActivity.prototype, "economicActivity", void 0);
__decorate([
    Prop({ default: 1 })
], EconomicActivity.prototype, "page", void 0);
__decorate([
    Watch('economicActivity')
], EconomicActivity.prototype, "economicActivityChanged", null);
EconomicActivity = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], EconomicActivity);
export default EconomicActivity;
