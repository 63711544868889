var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { mixpanelTracking } from '@/services/mixpanel';
import Input from 'components/Input.vue';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
let Filial = class Filial extends Vue {
    constructor() {
        super(...arguments);
        this.hasError = false;
    }
    inputsValidations() {
        let reseted = false;
        reseted =
            (this.selectedInputRange.value_start ===
                this.selectedInputRange.value_end &&
                this.selectedInputRange.value_end === null) ||
                this.selectedInputRange.value_end === '';
        if (reseted)
            this.resetValidationInputs();
        const inputInitialValidate = 
        // @ts-ignore
        this.$refs.inputInitialValue.$refs.qinput.validate();
        // @ts-ignore
        const inputEndValidate = this.$refs.inputEndValue.$refs.qinput.validate();
        this.hasError = (!inputInitialValidate || !inputEndValidate) && !reseted;
        return reseted || (inputInitialValidate && inputEndValidate);
    }
    resetValidationInputs() {
        // @ts-ignore
        this.$refs.inputInitialValue.$refs.qinput.resetValidation();
        // @ts-ignore
        this.$refs.inputEndValue.$refs.qinput.resetValidation();
    }
    get selectedInfo() {
        if (this.selected)
            if (!this.hasError) {
                return `(selecionado: ${this.selectedInputRange.value_start} a ${this.selectedInputRange.value_end})`;
            }
            else
                return '<span class="font-12 text-red-8 text-weight-bold">Erro no filtro</span>';
        return '';
    }
    updated() {
        if (this.inputsValidations())
            this.$emit('setInputsRange', this.selectedInputRange);
    }
    valueIsNotEmpty(val) {
        return val !== undefined && val !== null && val !== '';
    }
    handleTrackingFilial() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: '' })
], Filial.prototype, "filterName", void 0);
__decorate([
    PropSync('selectedInputRangeValues', { default: {} })
], Filial.prototype, "selectedInputRange", void 0);
__decorate([
    Prop({ default: 0 })
], Filial.prototype, "selected", void 0);
Filial = __decorate([
    Component({
        components: {
            Input,
        },
    })
], Filial);
export default Filial;
