var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { SearchStatus } from '@/overmind/search/state';
import { json } from 'overmind';
import { isEmpty } from 'lodash';
let AnnualBilling = class AnnualBilling extends Vue {
    constructor() {
        super(...arguments);
        this.annualCompanyBillingNotSelected = [];
        this.graphicsType = true;
        this.mappedAnnualBilling = [];
        this.colorPalette = [
            '#14AAFF',
            '#C1CB33',
            '#FDD836',
            '#A970EB',
            '#F47C67',
        ];
        this.chartOptions = {
            chart: {
                type: 'treemap',
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        color: 'black',
                        style: {
                            textOutline: '0px',
                            fontWeight: 'none',
                        },
                    },
                },
            },
            series: [
                {
                    type: 'treemap',
                    layoutStartingDirection: 'horizontal',
                    title: {
                        text: '',
                    },
                    data: [],
                },
            ],
            title: {
                text: '',
            },
            credits: false,
        };
    }
    handleAnnualBilling(values) {
        const totalValue = this.getTotalValue();
        const percentageValue = this.calculatePercentage(totalValue);
        let mappedAnnualBilling = values
            .filter(item => item.doc_count > parseInt(percentageValue.toFixed()))
            .map(value => {
            return {
                name: value.key,
                value: value.doc_count,
                color: '',
            };
        });
        return this.addColors(mappedAnnualBilling);
    }
    addColors(mappedAnnualBilling) {
        this.colorPalette.forEach((color, indexColor) => {
            mappedAnnualBilling.forEach((item, index) => {
                if (index === indexColor)
                    item['color'] = color;
            });
        });
        return mappedAnnualBilling;
    }
    calculatePercentage(totalValue) {
        return totalValue * (parseFloat('1.1') / 100);
    }
    getTotalValue() {
        let count = 0;
        if (!isEmpty(this.annualBilling)) {
            json(this.annualBilling).forEach((item) => {
                count += item.doc_count;
            });
        }
        return count;
    }
    onAnnualCompanyBillinggChanged() {
        this.chartOptions.series[0].data = this.handleAnnualBilling(this.annualCompanyBilling);
    }
    async onAnnualCompanyBillinggNotChanged(val) {
        const annualCompanyBilling = json(val);
        const numEmployees = annualCompanyBilling.filter(el => {
            return !this.chartOptions.series[0].data
                .map(num => num.name)
                .includes(el.key);
        });
        this.annualCompanyBillingNotSelected = numEmployees;
    }
    onCreateSearchStateChanged() {
        if (!this.graphicsType)
            this.graphicsType = true;
    }
    changeGraphicsType() {
        this.graphicsType = !this.graphicsType;
        if (this.graphicsType) {
            this.chartOptions.series[0].data = this.handleAnnualBilling(this.annualCompanyBilling);
        }
        else {
            this.chartOptions.series[0].data = this.handleAnnualBilling(this.annualBilling);
        }
    }
    mounted() {
        this.graphicsType = true;
        this.chartOptions.series[0].data = this.handleAnnualBilling(this.annualCompanyBilling);
    }
};
__decorate([
    Prop({ default: () => [] })
], AnnualBilling.prototype, "annualBilling", void 0);
__decorate([
    Prop({ default: () => [] })
], AnnualBilling.prototype, "annualCompanyBilling", void 0);
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], AnnualBilling.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: 1 })
], AnnualBilling.prototype, "page", void 0);
__decorate([
    Watch('annualCompanyBilling')
], AnnualBilling.prototype, "onAnnualCompanyBillinggChanged", null);
__decorate([
    Watch('annualCompanyBilling')
], AnnualBilling.prototype, "onAnnualCompanyBillinggNotChanged", null);
__decorate([
    Watch('createSearchState')
], AnnualBilling.prototype, "onCreateSearchStateChanged", null);
AnnualBilling = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], AnnualBilling);
export default AnnualBilling;
