var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let ProconAverageComplaintsSixMonths = class ProconAverageComplaintsSixMonths extends Vue {
    constructor() {
        super(...arguments);
        this.hasError = false;
    }
    resetValidation() {
        // @ts-ignore
        this.$refs.inputBeginValue.$refs.qinput.resetValidation();
        // @ts-ignore
        this.$refs.inputEndValue.$refs.qinput.resetValidation();
    }
    validate() {
        let reseted = false;
        reseted =
            this.inputValues.value_start === this.inputValues.value_end &&
                (this.inputValues.value_end === null ||
                    this.inputValues.value_end.toString() === '');
        // @ts-ignore
        const startIsValid = this.$refs.inputBeginValue.$refs.qinput.validate();
        // @ts-ignore
        const endIsValid = this.$refs.inputEndValue.$refs.qinput.validate();
        if (reseted)
            this.resetValidation();
        this.hasError = (!startIsValid || !endIsValid) && !reseted;
        return reseted || (startIsValid && endIsValid);
    }
    get selectedInfo() {
        if (this.selected)
            if (!this.hasError) {
                return `(selecionado: ${this.inputValues.value_start} a ${this.inputValues.value_end})`;
            }
            else
                return '<span class="font-12 text-red-8 text-weight-bold">Erro no filtro</span>';
        return '';
    }
    updated() {
        if (this.validate()) {
            this.$emit('update', this.inputValues);
        }
    }
    handleTrackingPACSM() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.title);
    }
};
__decorate([
    PropSync('value', { default: {} })
], ProconAverageComplaintsSixMonths.prototype, "inputValues", void 0);
__decorate([
    Prop({ default: '' })
], ProconAverageComplaintsSixMonths.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], ProconAverageComplaintsSixMonths.prototype, "info", void 0);
__decorate([
    Prop({ default: 0 })
], ProconAverageComplaintsSixMonths.prototype, "selected", void 0);
ProconAverageComplaintsSixMonths = __decorate([
    Component({
        components: { Input },
    })
], ProconAverageComplaintsSixMonths);
export default ProconAverageComplaintsSixMonths;
