var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import SelectedLabelFilter from '@/components/SelectedLabelFilter.vue';
import { json } from 'overmind';
import { Component, Emit, Prop, Vue, Watch, } from 'vue-property-decorator';
let SimplePageSelectedFiltersXD = class SimplePageSelectedFiltersXD extends Vue {
    constructor() {
        super(...arguments);
        this.showButton = false;
        this.totalLimitSize = 0;
        this.componentLimitSize = 0;
        this.items = [];
        this.offsetWidthWhenPadding = 116;
        this.filterHeightOffset = 0;
    }
    openAllPageSelectedFilters() {
        return true;
    }
    onOffSetWidth(currVal) {
        if (currVal && currVal > 0) {
            const totalLimitSize = Math.floor((currVal - 32) / this.offsetWidthWhenPadding) *
                this.offsetWidthWhenPadding *
                2;
            const multiple = Math.floor(totalLimitSize / this.offsetWidthWhenPadding);
            this.componentLimitSize =
                multiple * this.offsetWidthWhenPadding - this.offsetWidthWhenPadding;
        }
    }
    onFilterOptions(currVal) {
        currVal.forEach(filter => {
            this.lengthLimit(filter);
        });
    }
    filterExclusiveOptionsIsFull(index) {
        const itemsLengthLimit = this.items.length + index + 1;
        return itemsLengthLimit <= this.lengthLimitFilterOptions;
    }
    get sumFilters() {
        const exclusiveOptions = json(this.exclusiveOptions);
        return ((this.items.length + exclusiveOptions.filter(e => e.isSelected).length) *
            this.offsetWidthWhenPadding);
    }
    get lengthLimitFilterOptions() {
        return this.componentLimitSize / this.offsetWidthWhenPadding;
    }
    lengthLimit(filter) {
        const itemsLabel = this.items.map(i => `${i.value}${i.queryField}`);
        if (filter && !itemsLabel.includes(`${filter.value}${filter.queryField}`)) {
            this.items.push(filter);
        }
        return this.sumFilters <= this.componentLimitSize;
    }
    getValidFilters(items //@ts-ignore
    ) {
        if (items && items.length > 0) {
            return items.filter(f => f.label !== '0 a +500 funcionários' &&
                f.label !== '0 a +500M faturamento');
        }
        return [];
    }
    removeSelectedFilter(exclusiveOptionValue) {
        const groupTag = 'potentialResults';
        return { groupTag, exclusiveOptionValue };
    }
    removeSelectedFilterByFilterOptionModel(filterOption, index) {
        return { filterOption, index };
    }
    getTotalSelectedFilter(mount, originalMount) {
        if (!mount || mount != mount - 1) {
            return setTimeout(() => this.getFilterHeight(), 1000);
        }
    }
    getFilterHeight() {
        if (this.$refs.filterHeightOffset &&
            this.$refs.filterHeightOffset.offsetHeight) {
            return this.$refs.filterHeightOffset.offsetHeight;
        }
    }
    getLabelIndex(filter) {
        return Math.random() + filter.value.length;
    }
};
__decorate([
    Prop({ default: () => [] })
], SimplePageSelectedFiltersXD.prototype, "filterOptions", void 0);
__decorate([
    Prop({ default: 0 })
], SimplePageSelectedFiltersXD.prototype, "totalSelectedFilters", void 0);
__decorate([
    Prop({ default: () => [] })
], SimplePageSelectedFiltersXD.prototype, "exclusiveOptions", void 0);
__decorate([
    Prop({ default: 0 })
], SimplePageSelectedFiltersXD.prototype, "offsetWidth", void 0);
__decorate([
    Prop({ default: '' })
], SimplePageSelectedFiltersXD.prototype, "selectedFilteredType", void 0);
__decorate([
    Emit('openAllPageSelectedFilters')
], SimplePageSelectedFiltersXD.prototype, "openAllPageSelectedFilters", null);
__decorate([
    Watch('offsetWidth', { immediate: true })
], SimplePageSelectedFiltersXD.prototype, "onOffSetWidth", null);
__decorate([
    Watch('filterOptions', { immediate: true, deep: true })
], SimplePageSelectedFiltersXD.prototype, "onFilterOptions", null);
__decorate([
    Emit('removeSelectedFilter')
], SimplePageSelectedFiltersXD.prototype, "removeSelectedFilter", null);
__decorate([
    Emit('removeSelectedFilterByFilterOptionModel')
], SimplePageSelectedFiltersXD.prototype, "removeSelectedFilterByFilterOptionModel", null);
__decorate([
    Watch('totalSelectedFilters')
], SimplePageSelectedFiltersXD.prototype, "getTotalSelectedFilter", null);
__decorate([
    Emit('filterOffsetHeight')
], SimplePageSelectedFiltersXD.prototype, "getFilterHeight", null);
SimplePageSelectedFiltersXD = __decorate([
    Component({
        components: {
            SelectedLabelFilter,
        },
    })
], SimplePageSelectedFiltersXD);
export default SimplePageSelectedFiltersXD;
