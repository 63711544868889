var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { connect } from '@/overmind';
let InputWithBtn = class InputWithBtn extends Vue {
    constructor() {
        super(...arguments);
        this.name = '';
    }
    renameHistory() {
        if (this.name != '') {
            const data = {
                //@ts-ignore
                nameHistory: this.name,
                //@ts-ignore
                idHistory: this.history.id,
            };
            //@ts-ignore
            this.actions.renameHistory(data);
            //@ts-ignore
            this.history.name = this.name;
            this.renameSucessfull();
        }
    }
    renameSucessfull() {
        return true;
    }
};
__decorate([
    Prop({ default: '' })
], InputWithBtn.prototype, "history", void 0);
__decorate([
    Emit('renameSucessfull')
], InputWithBtn.prototype, "renameSucessfull", null);
InputWithBtn = __decorate([
    Component(connect(({ state, actions }) => ({
        actions: actions.search,
    }), {
        name: 'InputWithBtn',
    }))
], InputWithBtn);
export default InputWithBtn;
