var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Input from 'components/Input.vue';
let ContractsAndGovernmentPanel = class ContractsAndGovernmentPanel extends Vue {
    constructor() {
        super(...arguments);
        this.valueCheckbox = false;
    }
    get selectedCount() {
        return (groupTag) => {
            const filter = this.filters.find(filter => filter.groupTag === groupTag);
            if (filter) {
                return filter.filterOptions.filter(option => option.isSelected).length;
            }
            else {
                return 0;
            }
        };
    }
    setCheckbox(filterGroupId, filterIndex, filterOptionIndex, value) {
        return { filterGroupId, filterIndex, filterOptionIndex, value };
    }
};
__decorate([
    Prop({ default: () => [] })
], ContractsAndGovernmentPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: 0 })
], ContractsAndGovernmentPanel.prototype, "filterGroupId", void 0);
__decorate([
    Emit('setCheckbox')
], ContractsAndGovernmentPanel.prototype, "setCheckbox", null);
ContractsAndGovernmentPanel = __decorate([
    Component({
        name: 'ContractsAndGovernmentPanel',
        components: {
            Input,
        },
    })
], ContractsAndGovernmentPanel);
export default ContractsAndGovernmentPanel;
