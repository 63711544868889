var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import SearchHistoryTable from './SearchHistoryTable.vue';
import { connect, overmind } from '@/overmind';
import { json } from 'overmind';
import { showNotify } from '@/shared/utils/helpers';
let SearchHistoryDrawer = class SearchHistoryDrawer extends Vue {
    constructor() {
        super(...arguments);
        this.histories = [];
        this.nameHistory = '';
        this.showInputContent = false;
        this.tableKey = 0;
        this.totalSelcted = 0;
        this.search = {};
        this.infoHistory = { nameHistory: '', totalSelcted: 0 };
        this.btnVisible = false;
    }
    mounted() {
        overmind.reaction(({ search }) => search.histories, v => {
            this.histories = json(v);
        });
    }
    remountComponent() {
        this.tableKey += 1;
        this.remountHeaderKey();
    }
    remountHeaderKey() { }
    confirmRemove(id) {
        return id;
    }
    getSearch(searchId) {
        return searchId;
    }
    closeHistory() {
        return true;
    }
    async saveHistory() {
        //@ts-ignore
        this.totalSelcted = Object.values(this.filterState).reduce((acc, obj) => {
            if (obj && typeof obj === 'object' && 'total' in obj) {
                //@ts-ignore
                return acc + obj.total;
            }
            return acc;
        }, 0);
        //@ts-ignore
        if (this.nameHistory && this.nameHistory.replace(/\s+/g, '')) {
            this.showInputContent = false;
            this.infoHistory.nameHistory = this.nameHistory;
            this.infoHistory.totalSelcted = this.totalSelcted;
            // @ts-ignore TS2349: property inexistent
            await this.actionsSearch.searchHistory.getSearchHistory();
            this.nameHistory = '';
            this.btnVisible = false;
            return this.infoHistory;
        } //@ts-ignore
        // this.remountComponent()
    }
    statusNotification(newStatus) {
        if (newStatus === 'created') {
            showNotify('Histórico de busca criado com sucesso!!', 'success');
        }
        else if (newStatus === 'deleted') {
            showNotify('Histórico de busca removido com sucesso!!', 'success-deleted');
        }
        else if (newStatus === 'error') {
            showNotify('Algo deu errado, tente novamente', 'error');
        }
    }
    onStatusChanged(newStatus) {
        let newValueStatus = newStatus;
        this.statusNotification(newValueStatus);
    }
};
__decorate([
    Prop({ default: () => { } })
], SearchHistoryDrawer.prototype, "status", void 0);
__decorate([
    Emit('remountHeaderKey')
], SearchHistoryDrawer.prototype, "remountHeaderKey", null);
__decorate([
    Emit('confirmRemove')
], SearchHistoryDrawer.prototype, "confirmRemove", null);
__decorate([
    Emit('getSearch')
], SearchHistoryDrawer.prototype, "getSearch", null);
__decorate([
    Emit('closeHistory')
], SearchHistoryDrawer.prototype, "closeHistory", null);
__decorate([
    Emit('saveHistory')
], SearchHistoryDrawer.prototype, "saveHistory", null);
__decorate([
    Watch('status')
], SearchHistoryDrawer.prototype, "onStatusChanged", null);
SearchHistoryDrawer = __decorate([
    Component(connect(({ state, actions }) => ({
        filterState: state.filters.totalSelected,
        actionsSearch: actions.search,
    }), {
        name: 'SearchHistoryDrawer',
        components: {
            Input,
            SearchHistoryTable,
        },
    }))
], SearchHistoryDrawer);
export default SearchHistoryDrawer;
