var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
let EmphasesOfCompanies = class EmphasesOfCompanies extends Vue {
    constructor() {
        super(...arguments);
        this.values = this.emphasesOfCompanies.map(item => {
            return {
                name: this.handlerKey(item),
                weight: item.doc_count,
            };
        });
        this.chartOptions = {
            chart: {
                type: 'wordcloud',
                spiral: 'archimedean',
            },
            title: {
                text: null,
            },
            tooltip: {
                headerFormat: '<span>{series.name}</span>',
                pointFormat: '<span style="font-weight: bold">{point.name}</span>: {point.weight}',
            },
            series: [
                {
                    name: '',
                    data: json(this.values),
                },
            ],
            credits: false,
        };
    }
    emphasesOfCompaniesChanged(current) {
        this.chartOptions.series[0].data = json(current.map(item => {
            return {
                name: this.handlerKey(item),
                weight: item.doc_count,
            };
        }));
    }
    handlerKey(value) {
        const newKey = value.key[0].toUpperCase() + value.key.slice(1).toLowerCase();
        return newKey.replace(/_/g, ' ');
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], EmphasesOfCompanies.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], EmphasesOfCompanies.prototype, "emphasesOfCompanies", void 0);
__decorate([
    Prop({ default: 1 })
], EmphasesOfCompanies.prototype, "page", void 0);
__decorate([
    Watch('emphasesOfCompanies')
], EmphasesOfCompanies.prototype, "emphasesOfCompaniesChanged", null);
EmphasesOfCompanies = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], EmphasesOfCompanies);
export default EmphasesOfCompanies;
