var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { isEmpty, uniq } from 'lodash';
import { separateValuesWithSymbols, lJustify, } from '@/shared/utils/MultiSelect.utils';
import { json } from 'overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let CEPs = class CEPs extends Vue {
    constructor() {
        super(...arguments);
        this.CEPsValue = json(this.selectedCEPs);
        this.selectedValues = [];
        this.options = [];
    }
    setSelectedFilterCep(value, type) {
        return { value, type };
    }
    addValue(text) {
        text = text.replace(/[^0-9\s]/g, '');
        if (text.length < 8)
            return;
        const handleValue = separateValuesWithSymbols(text);
        const value = handleValue.map(r => lJustify(r, 8, '0'));
        value.forEach(r => this.selectedValues.push(r));
        this.selectedValues = this.removeSymbolsValues(this.sanitizeValues(this.selectedValues));
        this.options = this.selectedValues;
        if (this.options.length > 0)
            this.setSelectedFilterCep(this.options, 'add');
    }
    removeValue(value) {
        this.options = this.selectedValues.filter(r => r !== value);
        this.setSelectedFilterCep(value, 'remove');
    }
    removeSymbolsValues(values) {
        return values.map(v => v.replace('-', ''));
    }
    // handleCep(value: string) {
    //   const firstPart = value.slice(0, 5)
    //   const seconPart = value.slice(5, 8)
    //   return value.includes('-') ? value : `${firstPart}-${seconPart}`
    // }
    sanitizeValues(values) {
        return uniq(values).filter(value => !isEmpty(value));
    }
    handleTrackingCEPS() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
    mounted() {
        if (this.CEPsValue) {
            this.selectedValues = this.CEPsValue;
            this.options = this.CEPsValue;
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], CEPs.prototype, "selectedCEPs", void 0);
__decorate([
    Prop({ default: '' })
], CEPs.prototype, "filterName", void 0);
__decorate([
    Prop({ default: '' })
], CEPs.prototype, "info", void 0);
__decorate([
    Emit('setSelectedFilterCep')
], CEPs.prototype, "setSelectedFilterCep", null);
CEPs = __decorate([
    Component({
        components: {
            Multiselect,
        },
    })
], CEPs);
export default CEPs;
