var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProconNumberOfComplaints from '@/modules/search/components/filterPanels/ComplaintsPanel/ProconNumberOfComplaints.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
let ProconNumberOfComplaintsContainer = class ProconNumberOfComplaintsContainer extends Vue {
    constructor() {
        super(...arguments);
        // @ts-ignore
        this.values = json(this.inputValues);
    }
    onValuesChanged(newVal) {
        this.values = newVal;
        // @ts-ignore
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore
        this.setInputRangeProconNumberOfComplaints(newVal);
    }
};
__decorate([
    Prop({ default: () => { } })
], ProconNumberOfComplaintsContainer.prototype, "filter", void 0);
ProconNumberOfComplaintsContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        selected: state.totalSelected.complaints.proconNumberOfComplaints,
        inputValues: state.selectedInputRange['reclamacoes_procon.quantidade_reclamacao'],
        setInputRangeProconNumberOfComplaints: actions.filters.setInputRangeProconNumberOfComplaints,
        actions: actions.filters,
        actionsSearch: actions.search,
    }), {
        components: { ProconNumberOfComplaints },
    }))
], ProconNumberOfComplaintsContainer);
export default ProconNumberOfComplaintsContainer;
