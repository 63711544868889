var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-list',_vm._l((_vm.filteredFilters),function(filter,filterIndex){return _c('q-expansion-item',{key:filterIndex,staticClass:"border-bottom",attrs:{"group":"legal","expand-separator":"","expand-icon-class":"q-pr-none"},on:{"before-hide":function($event){return _vm.onHideFilter(filterIndex)},"show":function($event){return _vm.onShowFilter(filterIndex, filter.title)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12 text-grey-7"},[_vm._v("\n          "+_vm._s(_vm.formatTitle(filter.title))+"\n        ")]),(_vm.selectedCount(filter) === 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionado)\n        ")]):(_vm.selectedCount(filter) > 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionados)\n        ")]):_vm._e(),(filter.info)?_c('q-icon',{attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[(filter.info)?_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(" "+_vm._s(filter.info)+" ")])]):_vm._e()],1):_vm._e()],1)]},proxy:true}],null,true)},[_c('q-card',[_c('q-card-section',{staticClass:"q-pb-xs q-pt-sm"},[_c('div',{staticClass:"column"},[(
              ((_vm.fullTextTerm === '' || _vm.fullTextTerm == null) &&
                filter.filterOptions.length > 5) ||
              _vm.searchTerm
            )?_c('div',{staticClass:"col"},[_c('Input',{staticClass:"q-mb-sm",attrs:{"dense":"","rounded":"","outlined":"","placeholder":"Pesquise aqui...","debounceTime":200},on:{"input":function($event){return _vm.onSearchFilters(filter, $event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(filter.queryType === 'multiSelect')?_c('div',{staticClass:"col q-md-sm"},[_c('q-virtual-scroll',{staticClass:"overflow-auto",staticStyle:{"max-height":"229px"},attrs:{"items":filter.filterOptions,"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('div',{key:index,staticClass:"col"},[_c('q-checkbox',{attrs:{"label":item.label,"value":item.isSelected},on:{"input":function($event){return _vm.setCheckbox(
                        _vm.filterGroupId,
                        filter.id,
                        filterIndex,
                        index,
                        $event,
                        item.value
                      )}}}),(item.info)?_c('span',{staticClass:"q-ml-sm"},[_c('q-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(_vm._s(item.info))])])],1)],1):_vm._e()],1)]}}],null,true)})],1):_vm._e(),(filter.queryType === 'rangeDate')?_c('div',{staticClass:"col q-mb-xs"},[_c('div',{staticClass:"row justify-around"},[_c('div',{staticClass:"col-auto"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY","value-type":"format","type":"date","placeholder":"Data de Início","inputClass":_vm.warnBeginDateField(filter.queryField)
                      ? 'date-picker-error'
                      : 'mx-input'},on:{"input":function($event){return _vm.setDateValue(filter.queryField)}},model:{value:(_vm.valueFilter[filter.queryField].inDate),callback:function ($$v) {_vm.$set(_vm.valueFilter[filter.queryField], "inDate", $$v)},expression:"valueFilter[filter.queryField].inDate"}})],1),_c('div',{staticClass:"col-auto"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY","value-type":"format","type":"date","placeholder":"Data de Fim","inputClass":_vm.warnEndDateField(filter.queryField)
                      ? 'date-picker-error'
                      : 'mx-input'},on:{"input":function($event){return _vm.setDateValue(filter.queryField)}},model:{value:(_vm.valueFilter[filter.queryField].toDate),callback:function ($$v) {_vm.$set(_vm.valueFilter[filter.queryField], "toDate", $$v)},expression:"valueFilter[filter.queryField].toDate"}})],1)])]):_vm._e()])])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }