var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-expansion-item',{staticClass:"border-bottom",attrs:{"group":"porte","expand-separator":"","expand-icon-class":"q-pr-none","header-class":"text-grey-7"},on:{"show":_vm.handleTrackingCapitalSocial},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12"},[_vm._v("\n        "+_vm._s(_vm.filterName)+"\n      ")]),_c('span',{staticClass:"font-12 text-primary text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.selectedInfo)}}),(_vm.hasError)?_c('q-icon',{staticClass:"q-pl-sm",attrs:{"color":"red-6","name":"icon-info-new","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v("\n            Este filtro não está selecionado corretamente! "),_c('br'),_vm._v("\n            Corrija seus valores ou resete o filtro.\n          ")])])],1):_vm._e()],1)]},proxy:true}])},[_c('div',{staticClass:"row items-center q-pa-md"},[_c('div',{staticClass:"col-5"},[_c('Input',{ref:"inputInitialValue",attrs:{"dense":"","type":"number","placeholder":"Valor inicial","rules":[
          function (val) { return _vm.valueIsNotEmpty(val) || 'Este valor não pode ser vazio'; },
          function (val) { return val >= 0 || 'Este valor não pode ser menor que 0'; },
          function (val) { return val <= _vm.selectedInputRange.value_end ||
            'Este valor de ser menor ou igual ao valor final'; } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("R$")])]},proxy:true}]),model:{value:(_vm.selectedInputRange.value_start),callback:function ($$v) {_vm.$set(_vm.selectedInputRange, "value_start", _vm._n($$v))},expression:"selectedInputRange.value_start"}})],1),_c('div',{staticClass:"col-2 text-center"},[_c('p',{staticClass:"q-ma-none"},[_vm._v("até")])]),_c('div',{staticClass:"col-5"},[_c('Input',{ref:"inputEndValue",attrs:{"dense":"","type":"number","placeholder":"Valor final","rules":[
          function (val) { return _vm.valueIsNotEmpty(val) || 'Este valor não pode ser vazio'; },
          function (val) { return val >= 0 || 'Este valor não pode ser menor que 0'; },
          function (val) { return val >= _vm.selectedInputRange.value_start ||
            'Este valor de ser maior ou igual ao valor inicial'; } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("R$")])]},proxy:true}]),model:{value:(_vm.selectedInputRange.value_end),callback:function ($$v) {_vm.$set(_vm.selectedInputRange, "value_end", _vm._n($$v))},expression:"selectedInputRange.value_end"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }