var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { formatCnpjsToSessionStorage } from '@/shared/utils/helpers';
import { uniq, isEmpty } from 'lodash';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
let SendCNPJS = class SendCNPJS extends Vue {
    constructor() {
        super(...arguments);
        this.selectedValues = [];
        this.options = [];
        this.toggleInput = false;
    }
    mounted() {
        let cnpjs;
        cnpjs =
            this.filter === 'cnpjs'
                ? sessionStorage.getItem('filters_cnpj')
                : sessionStorage.getItem('filters_similar_cnpj');
        if (cnpjs !== null) {
            this.selectedValues = formatCnpjsToSessionStorage(cnpjs);
            this.options = this.selectedValues;
        }
    }
    addCnpj(text) {
        text.split(' ').forEach(spaceSplit => {
            spaceSplit.split(',').forEach(commaSplit => {
                commaSplit.split(';').forEach(semicolonSplit => {
                    semicolonSplit.split('\n').forEach(tag => {
                        const onlyNumbers = tag.replace(/\D+/g, '');
                        if (!onlyNumbers)
                            return false;
                        const cnpj = this.lJustify(onlyNumbers, 14, '0');
                        this.selectedValues.push(cnpj);
                    });
                });
            });
        });
        this.selectedValues = this.sanitizeCnpjs(this.selectedValues);
        this.options = this.selectedValues;
        if (this.selectedValues.length > 0 && this.filter == 'cnpjs')
            this.$emit('setCnpjs', true);
        if (this.filter == 'cnpjs')
            sessionStorage.setItem('filters_cnpj', JSON.stringify(this.selectedValues));
        else
            sessionStorage.setItem('filters_similar_cnpj', JSON.stringify(this.selectedValues));
    }
    removedCnpj(cnpj) {
        let cnpjs;
        cnpjs =
            this.filter === 'cnpjs'
                ? sessionStorage.getItem('filters_cnpj')
                : sessionStorage.getItem('filters_similar_cnpj');
        if (cnpjs !== null && cnpjs.length > 0) {
            cnpjs = formatCnpjsToSessionStorage(cnpjs);
            const index = cnpjs.findIndex(item => item === cnpj);
            if (index !== -1) {
                cnpjs.splice(index, 1);
                if (this.filter == 'cnpjs')
                    sessionStorage.setItem('filters_cnpj', JSON.stringify(cnpjs));
                else
                    sessionStorage.setItem('filters_similar_cnpj', JSON.stringify(cnpjs));
                this.selectedValues = cnpjs;
                this.options = cnpjs;
            }
        }
        if (this.selectedValues.length === 0 && this.filter == 'cnpjs') {
            this.$emit('setCnpjs', false);
        }
    }
    lJustify(str, targetLength, paddingString = ' ') {
        let newString = str;
        while (newString.length < targetLength) {
            newString = paddingString + newString;
        }
        return newString;
    }
    sanitizeCnpjs(cnpjs) {
        return uniq(cnpjs).filter(cnpj => !isEmpty(cnpj));
    }
    openInput() {
        if (this.toggleInput !== true)
            this.toggleInput = true;
    }
};
__decorate([
    Prop({ default: () => [] })
], SendCNPJS.prototype, "filters", void 0);
__decorate([
    Prop({ default: 'cnpjs' })
], SendCNPJS.prototype, "filter", void 0);
SendCNPJS = __decorate([
    Component({
        name: 'SendCNPJS',
        components: {
            Input,
            Multiselect,
        },
    })
], SendCNPJS);
export default SendCNPJS;
