import { flatten, isEmpty, isEqual, sum } from 'lodash';
import { json } from 'overmind';
export const getFilterOptionBySelectedInputRange = (filterGroups, selectedInputRange) => {
    const filterOptions = [];
    if (selectedInputRange) {
        Object.keys(selectedInputRange).forEach(key => {
            const item = selectedInputRange[key];
            if (typeof item.value_end === 'number' &&
                typeof item.value_start === 'number' &&
                item.value_end >= item.value_start) {
                const filter = getFilterByKey(filterGroups, key) || {};
                const label = ` ${item.value_start} a ${item.value_end} ${filter.title}`;
                filterOptions.push({
                    value: key,
                    label,
                    queryField: key,
                    special: filter.special,
                });
            }
        });
    }
    return filterOptions;
};
export const getFilterByKey = (filteredFilterGroups, key) => {
    const filterOptions = flatten(filteredFilterGroups.map(filter => filter.filters));
    return filterOptions.find(f => f.queryField === key);
};
export const getFilterOptionByDateFilter = (filteredFilterGroups, dateFilter) => {
    const newFiltersOptions = [];
    const keyDateFilter = Object.keys(dateFilter || {});
    keyDateFilter.forEach(key => {
        const filter = getFilterByKey(filteredFilterGroups, key);
        if (dateFilter[key].inDate && dateFilter[key].toDate && filter) {
            newFiltersOptions.push({
                value: dateFilter[key].queryField,
                queryField: dateFilter[key].queryField,
                label: `${filter.title} (${dateFilter[key].inDate} até ${dateFilter[key].toDate})`,
                special: filter.special,
            });
        }
    });
    return newFiltersOptions;
};
export const getFilterOptionByStateSliderFilters = (stateSliderFilter, totalSelected) => {
    const filter = [];
    if (!isEmpty(stateSliderFilter) && !isEmpty(totalSelected)) {
        const { cep_distance, employees, revenues } = stateSliderFilter;
        const { porte } = totalSelected;
        if (!isEmpty(cep_distance) && cep_distance.zip) {
            filter.push({
                label: `CEP: ${cep_distance.zip || cep_distance.cep} - ${cep_distance.distance} (Distância de uma localização)`,
                value: cep_distance.queryField || cep_distance['cep_distance'],
                queryField: cep_distance.queryField || 'cep_distance',
                special: false,
            });
        }
        if (!isEmpty(porte) && porte.employee) {
            const isInitialRange = isEqual(employees.value, ['0', '+500']);
            if (!isInitialRange) {
                filter.push({
                    label: `${employees.value[0]} a ${employees.value[1]} funcionários`,
                    value: 'total_employees_r2016_company',
                    queryField: 'total_employees_r2016_company',
                    special: false,
                });
            }
        }
        if (!isEmpty(porte) && porte.revenues) {
            const isInitialRange = isEqual(revenues.value, ['0', '+500M']);
            if (!isInitialRange) {
                filter.push({
                    label: `${revenues.value[0]} a ${revenues.value[1]} faturamento`,
                    value: 'revenue_a32_cnpj_code',
                    queryField: 'revenue_a32_cnpj_code',
                    special: false,
                });
            }
        }
    }
    return filter;
};
export const getFilterOptionByStateSliderFiltersXD = (stateSliderFilter, totalSelected) => {
    const filters = [];
    if (!isEmpty(stateSliderFilter) && !isEmpty(totalSelected)) {
        const { cep_distance, employees, revenues } = stateSliderFilter;
        const { porte } = totalSelected;
        if (!isEmpty(cep_distance) && cep_distance.zip) {
            filters.push({
                label: `CEP: ${cep_distance.zip || cep_distance.cep} - ${cep_distance.distance} (Distância de uma localização)`,
                value: cep_distance.queryField || cep_distance['cep_distance'],
                queryField: cep_distance.queryField || 'cep_distance',
                special: false,
            });
        }
        if (!isEmpty(porte) && porte.employee) {
            filters.push({
                label: `${employees.value[0]} a ${employees.value[1]}`,
                value: employees.queryField,
                queryField: employees.queryField,
                special: false,
            });
        }
        if (!isEmpty(porte) && porte.revenues) {
            filters.push({
                label: `${revenues.value[0]} a ${revenues.value[1]}`,
                value: revenues.queryField,
                queryField: revenues.queryField,
                special: false,
            });
        }
    }
    return filters;
};
export const getFilterOptionsByStateInputFilters = (filteredFilterGroups, stateInputFilters) => {
    const filterOptions = [];
    Object.keys(stateInputFilters || {}).forEach(key => {
        if (stateInputFilters[key].value) {
            const filter = getFilterByKey(filteredFilterGroups, stateInputFilters[key].queryField) || {};
            const label = `${stateInputFilters[key].value} (${filter.title})`;
            filterOptions.push({
                value: stateInputFilters[key].queryField,
                label,
                queryField: stateInputFilters[key].queryField,
                special: filter.special,
            });
        }
    });
    return filterOptions;
};
export const getFilterOptionsByCNPJ = (tag = 'filters_cnpj') => {
    const listCNPJ = JSON.parse(sessionStorage.getItem(tag) || '[]');
    if (listCNPJ.length > 0) {
        return [
            {
                label: `${listCNPJ.length} CNPJs`,
                value: 'nu_cnpj',
                queryField: tag === 'filters_cnpj' ? 'nu_cnpj' : 'nu_cnpj@where_not',
                special: false,
            },
        ];
    }
    return [];
};
export const getCountTotalSelected = totalSelected => sum(
// @ts-ignore
Object.keys(totalSelected).map(
// @ts-ignore
item => totalSelected[item].total));
export const getSelectedCheckbox = (selectedCheckbox, selectedFilteredType, simplePage = false) => {
    const filters = json(selectedCheckbox || []).flatMap((filter) => {
        return filter.filterOptions.map(f => ({
            label: f.label,
            value: f.value,
            queryField: filter.queryField,
            special: filter.special,
        }));
    });
    if (simplePage) {
        const hasDepartment = filters.find(f => {
            return f.queryField == 'decision_makers_lkd_m5.department';
        });
        const hasLevelSelected = filters.find(f => {
            return f.queryField == 'decision_makers_lkd_m5.level';
        });
        if (hasDepartment && hasLevelSelected) {
            filters.unshift({
                queryField: 'Decisores lógica OU/E',
                // @ts-ignore
                label: `Decisores: lógica "${selectedFilteredType}"`,
                value: '',
                special: false,
            });
        }
    }
    return filters;
};
export const getSelectedInputRange = (selectedInputRange) => {
    return Object.keys(selectedInputRange)
        .filter(item => typeof selectedInputRange[item].value_end === 'number' &&
        typeof selectedInputRange[item].value_start === 'number' &&
        selectedInputRange[item].value_end >=
            selectedInputRange[item].value_start)
        .map(item => ({
        queryField: item,
        label: `${selectedInputRange[item].value_start} a ${selectedInputRange[item].value_end}`,
        value: item,
        special: false,
    }));
};
export const getFilterDateFilter = (dateFilter) => {
    return Object.keys(dateFilter)
        .filter(item => dateFilter[item].inDate && dateFilter[item].toDate)
        .map(item => ({
        queryField: item,
        label: `${dateFilter[item].inDate} a ${dateFilter[item].toDate}`,
        value: item,
        special: false,
    }));
};
export const getStateSliderFilters = (sliderFilters, totalSelected) => {
    return getFilterOptionByStateSliderFilters(json(sliderFilters), json(totalSelected));
};
export const getSelectedFilterOracle = (selectedFilter) => {
    return (selectedFilter || []).map(filterName => ({
        label: `Termo: ${filterName}`,
        value: filterName,
        queryField: 'client_flags_array',
        special: false,
    }));
};
export const getSelectedFilterGPT = (selectedFilter) => {
    return (selectedFilter || []).map(filterName => {
        const handleFirterName = filterName.charAt(0).toUpperCase() + filterName.slice(1);
        return {
            label: handleFirterName,
            value: filterName,
            queryField: 'additional_data_atlas.data_gpt.product_service',
            special: false,
        };
    });
};
export const getRemoveFilterOracle = (exclusionFilters) => {
    return (exclusionFilters || []).map(filterName => ({
        label: `Sem o termo: ${filterName}`,
        value: filterName,
        queryField: 'client_flags_array@where_not',
        special: false,
    }));
};
export const getRemoveFilterCompanyName = (exclusionFilter) => {
    return (exclusionFilter || []).map(str => ({
        label: str,
        value: str,
        queryField: 'no_empresa@where_not',
        special: false,
    }));
};
export const getFilterCNPJsWherenot = () => {
    const cnpjs = getFilterOptionsByCNPJ('filters_deleted_cnpj');
    return cnpjs.length > 0
        ? [{ ...cnpjs[0], label: `${cnpjs[0].label} excluídos` }]
        : [];
};
export const getFilterCep = (selectedFilters = []) => {
    return (selectedFilters || []).map(str => {
        const str1 = str.substring(0, 5);
        const str2 = str.substring(5, 9);
        return {
            label: `CEP: ${str1}-${str2}`,
            value: str,
            queryField: 'nu_cep',
            special: false,
        };
    });
};
export const getFilterNeighborhoods = (selectedFilters) => {
    return (selectedFilters || []).map(str => ({
        label: str,
        value: str,
        queryField: 'no_bairro',
        special: false,
    }));
};
export const getStateInputFilters = (stateInputFilters) => {
    return Object.keys(stateInputFilters)
        .filter(item => item !== 'additional_data_atlas.data_gpt.product_service')
        .map(item => {
        return {
            queryField: item,
            label: stateInputFilters[item].value,
            value: stateInputFilters[item].value,
            special: false,
        };
    })
        .filter(item => item.value);
};
