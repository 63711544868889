import { render, staticRenderFns } from "./SearchExportModal.container.vue?vue&type=template&id=56a2b0da&scoped=true&"
import script from "./SearchExportModal.container.vue?vue&type=script&lang=ts&"
export * from "./SearchExportModal.container.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56a2b0da",
  null
  
)

export default component.exports