var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit, Watch, } from 'vue-property-decorator';
import LocalizationPanel from '@/modules/search/components/filterPanels/LocalizationPanel/LocalizationPanel.vue';
import DecisionMakersPanel from '../components/filterPanels/DecisionMakersPanel.vue';
import SendCNPJSPanel from '../components/filterPanels/SendCNPJSPanel.vue';
import ActivityPanel from '../components/filterPanels/ActivityPanel.vue';
import PortePanel from './filterPanels/PortePanel/PortePanel.vue';
import LegalAndTaxPanel from '../components/filterPanels/LegalAndTaxPanel.vue';
import ExcludeFromResultPanel from '../components/filterPanels/ExcludeFromResultPanel.vue';
import GoodsAndPropertiesPanel from '../components/filterPanels/GoodsAndPropertiesPanel.vue';
import FinancialAndInvestimentsPanel from '../components/filterPanels/FinancialAndInvestimentsPanel.vue';
import ContractsAndGovernmentPanel from '../components/filterPanels/ContractsAndGovernmentPanel.vue';
import SectorSpecificDataPanel from '../components/filterPanels/SectorSpecificDataPanel.vue';
import RecordsAndLicensePanel from '../components/filterPanels/RecordsAndLicensePanel.vue';
import TechnologyAndToolsPanel from '../components/filterPanels/TechnologyAndToolsPanel.vue';
import ManagementAndAdministrationPanel from './filterPanels/ManagementAndAdministrationPanel/ManagementAndAdministrationPanel.vue';
import ComplaintsPanel from './filterPanels/ComplaintsPanel/ComplaintsPanel.vue';
import CompetitionAndMarketPanel from '../components/filterPanels/CompetitionAndMarketPanel.vue';
import InnerLoading from '@/components/InnerLoading.vue';
import AdvancedFiltersModalAlert from '@/components/AdvancedFiltersModal/Alert.vue';
import AdvancedFiltersModalUnlockFilter from '@/components/AdvancedFiltersModal/UnlockFilter.vue';
import Modal from 'components/Modal.vue';
import NewModal from '@/components/NewModal.vue';
import Input from 'components/Input.vue';
import { sum } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
import { SearchStatus } from '@/overmind/search/state';
let AdvancedFiltersModal = class AdvancedFiltersModal extends Vue {
    constructor() {
        super(...arguments);
        this.currentTab = 'atividade';
        this.searchFiltersTerm = '';
        this.localCnpjsData = [];
        this.keySendCnpjs = false;
        this.loadingMessage = "Buscando CNPJ's semelhantes...";
        this.iconFilters = {
            similarcnpjs: 'img:statics/images/search-briefcase.svg',
            atividade: 'icon-activity',
            porte: 'img:statics/images/diagram.svg',
            localização: 'icon-location',
            decisores: 'img:statics/images/followers.svg',
            legais: 'icon-legal',
            cnpjs: 'img:statics/images/paste.svg',
            where_not: 'img:statics/images/cancel.svg',
            management_and_administration: 'img:statics/images/briefcase.svg',
            tecnology_and_tools: 'img:statics/images/tools.svg',
            complaints: 'img:statics/images/dislike.svg',
            goods_and_properties: 'img:statics/images/tag.svg',
            financial: 'img:statics/images/bar-chart.svg',
            government_contracts: 'img:statics/images/eye.svg',
            sectoral_data: 'img:statics/images/information.svg',
            licenses_data: 'img:statics/images/medal.svg',
            competition_and_markets: 'img:statics/images/pie-chart.svg',
        };
        this.thumbStyleScrool = {
            right: '2px',
            borderRadius: '5px',
            backgroundColor: '#CFD8DC',
            width: '5px',
            opacity: 0.75,
        };
    }
    handleLoadingFiltersState() {
        return this.filterState && this.filterState === SearchStatus.RECEIVED;
    }
    usuallyUsedFilters(index) {
        const usuallyUsedFiltersList = [
            'atividade',
            'porte',
            'localização',
            'decisores',
        ];
        if (usuallyUsedFiltersList.includes(index))
            return true;
        else
            return false;
    }
    disabledFilters(index) {
        const disabledFilterList = [
            'BENS E PROPRIEDADES',
            'FINANCEIRO E INVESTIMENTOS',
            'CONTRATOS E GOVERNO',
            'DADOS ESPECÍFICOS DE SETORES',
            'REGISTROS E LICENÇAS',
            'CONCORRÊNCIA E MERCADO',
        ];
        if (disabledFilterList.includes(index))
            return true;
        else
            return false;
    }
    onChangeShowAdvancedFilterModal(currVal) {
        this.showModal = currVal;
    }
    getCountTotalSelected() {
        return sum(Object.keys(this.totalSelected).map(item => this.totalSelected[item].total));
    }
    getCnpjsforSimilarSearch() {
        const cnpjs = sessionStorage.getItem('filters_similar_cnpj');
        if (cnpjs !== null) {
            return JSON.parse(cnpjs).length;
        }
        else {
            return 0;
        }
    }
    setInputFilter(event) {
        return event;
    }
    selectAllFilterOptionsWhereNot(event) {
        return { ...event, filterGroupId: 8, filterId: 27 };
    }
    selectAllFilterOptionsActivity(event) {
        return { ...event, filterGroupId: 1, filterId: 1 };
    }
    createSimilarSearch() {
        if (this.getCnpjsforSimilarSearch() !== 0) {
            mixpanelTracking('Buscou CNPJs semelhantes');
            this.$emit('createSimilarSearch', true);
        }
    }
    getKey(filterGroup) {
        return `${filterGroup.id}${this.searchTerm}`;
    }
    handleUpdateModalOpen(event) {
        if (event === false) {
            return this.closeModal();
        }
    }
    setDateValue(event) {
        return event;
    }
    resetFilters() {
        mixpanelTracking('filtros avançados: Limpou todos os filtros');
        return true;
    }
    setCnpjs(value) {
        this.keySendCnpjs ? (this.keySendCnpjs = false) : (this.keySendCnpjs = true);
        return value;
    }
    setCnpjsRemoved(value) {
        this.keySendCnpjs ? (this.keySendCnpjs = false) : (this.keySendCnpjs = true);
        return value;
    }
    setRemoveCompanyName(event) {
        return event;
    }
    onSearchFilters() {
        return this.searchFiltersTerm;
    }
    setSelectedFilteredType(selectedFilteredType) {
        return selectedFilteredType;
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        return {
            filterGroupId,
            filterId,
            filterIndex,
            filterOptionIndex,
            value,
            itemId,
        };
    }
    toggleExclusiveOption(index, data) {
        // if (data) mixpanelTracking(`Selecionou o filtro: ${data.label}`)
        setTimeout(() => {
            if (data.isSelected) {
                mixpanelTracking(`filtros avançados: Marcou o filtro exclusivo: ${data.label}`);
            }
            else {
                mixpanelTracking(`filtros avançados: Desmarcou o filtro exclusivo: ${data.label}`);
            }
        }, 200);
        return index;
    }
    setSelectedRadioButton(event) {
        return event;
    }
    closeModal() {
        mixpanelTracking('filtros avançados: Fechou os filtros avançados');
        this.searchFiltersTerm = '';
        return false;
    }
    createSearch() {
        if (this.currentTab == 'similarcnpjs') {
            this.createSimilarSearch();
            return;
        }
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'busca_filtros_avancados' });
        mixpanelTracking('filtros avançados: Clicou em buscar pelo modal de filtros avançados');
        this.$emit('createSearch');
        this.closeModal();
        return true;
    }
    setSelectedFilterOracle(event) {
        return event;
    }
    setRemoveFilterOracle(event) {
        return event;
    }
    resetSpecialFilters() {
        return true;
    }
    onFilterGroupsChange(val, oldVal) {
        if (oldVal && oldVal.length > 0) {
            if (val && val.length > 0) {
                if (val[0].groupTag !== oldVal[0].groupTag) {
                    this.currentTab = val[0].groupTag;
                }
                else {
                    if (val.length !== oldVal.length) {
                        this.currentTab = val[0].groupTag;
                    }
                }
            }
        }
    }
    formatNumber(value) {
        if (value || value === 0) {
            return value.toLocaleString('pt-BR', {
                minimumIntegerDigits: 1,
            });
        }
    }
    setSelectFilteredType(value) {
        return value;
    }
    selectedCount(groupTag) {
        switch (groupTag) {
            case 'atividade':
                return this.totalSelected.activity.total;
            case 'localização':
                return this.totalSelected.localization.total;
            case 'porte':
                return this.totalSelected.porte.total;
            case 'legais':
                return this.totalSelected.legais.total;
            case 'where_not':
                return this.totalSelected.where_not.total;
            case 'cnpjs':
                return this.totalSelected.cnpjs.total;
            case 'similarcnpjs':
                return this.totalSelected.cnpjs.total;
            case 'decisores':
                return this.totalSelected.decisores.total;
            case 'management_and_administration':
                return this.totalSelected.management.total;
            case 'complaints':
                return this.totalSelected.complaints.total;
            case 'tecnology_and_tools':
                return this.totalSelected.technology.total;
            default:
                return 0;
        }
    }
    trackingGroupFilterOnMixpanel(value) {
        return mixpanelTracking('filtros avançados: Abriu o seção de filtros: ' + value);
    }
    setSelectedGPTKeyWord(event) {
        return event;
    }
};
__decorate([
    PropSync('open', { default: false })
], AdvancedFiltersModal.prototype, "showModal", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "exclusiveOptions", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvancedFiltersModal.prototype, "totalSelected", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvancedFiltersModal.prototype, "stateInputFilters", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvancedFiltersModal.prototype, "selectedRadioButton", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "filterGroups", void 0);
__decorate([
    Prop({ default: () => { } })
], AdvancedFiltersModal.prototype, "dateFilter", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "filterOracle", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "selectedGPTFilter", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "removeFilterOracle", void 0);
__decorate([
    Prop({ default: () => [] })
], AdvancedFiltersModal.prototype, "removeFilterCompanyName", void 0);
__decorate([
    Prop({ default: 0 })
], AdvancedFiltersModal.prototype, "resultCalcute", void 0);
__decorate([
    Prop({ default: '' })
], AdvancedFiltersModal.prototype, "searchTerm", void 0);
__decorate([
    Prop({ default: 'idle' })
], AdvancedFiltersModal.prototype, "radioButtonState", void 0);
__decorate([
    Prop({ default: false })
], AdvancedFiltersModal.prototype, "loadingPotentialResults", void 0);
__decorate([
    Prop({ default: false })
], AdvancedFiltersModal.prototype, "loadingFilter", void 0);
__decorate([
    Prop({ default: true })
], AdvancedFiltersModal.prototype, "canSearch", void 0);
__decorate([
    Prop({ default: 'not_receive' })
], AdvancedFiltersModal.prototype, "filterState", void 0);
__decorate([
    Prop({ default: '' })
], AdvancedFiltersModal.prototype, "selectedFilteredType", void 0);
__decorate([
    PropSync('filterGPTKeyWord', { default: '' })
], AdvancedFiltersModal.prototype, "selectedFilterGPTKeyWord", void 0);
__decorate([
    Watch('showAdvancedFilterModal')
], AdvancedFiltersModal.prototype, "onChangeShowAdvancedFilterModal", null);
__decorate([
    Emit('setInputFilter')
], AdvancedFiltersModal.prototype, "setInputFilter", null);
__decorate([
    Emit('selectAllFilterOptions')
], AdvancedFiltersModal.prototype, "selectAllFilterOptionsWhereNot", null);
__decorate([
    Emit('selectAllFilterOptions')
], AdvancedFiltersModal.prototype, "selectAllFilterOptionsActivity", null);
__decorate([
    Emit('setDateValue')
], AdvancedFiltersModal.prototype, "setDateValue", null);
__decorate([
    Emit('resetFilters')
], AdvancedFiltersModal.prototype, "resetFilters", null);
__decorate([
    Emit('setCnpjs')
], AdvancedFiltersModal.prototype, "setCnpjs", null);
__decorate([
    Emit('setCnpjsRemoved')
], AdvancedFiltersModal.prototype, "setCnpjsRemoved", null);
__decorate([
    Emit('setRemoveCompanyName')
], AdvancedFiltersModal.prototype, "setRemoveCompanyName", null);
__decorate([
    Emit('onSearchFilters')
], AdvancedFiltersModal.prototype, "onSearchFilters", null);
__decorate([
    Emit('setSelectedFilteredType')
], AdvancedFiltersModal.prototype, "setSelectedFilteredType", null);
__decorate([
    Emit('setCheckbox')
], AdvancedFiltersModal.prototype, "setCheckbox", null);
__decorate([
    Emit('toggleExclusiveOption')
], AdvancedFiltersModal.prototype, "toggleExclusiveOption", null);
__decorate([
    Emit('setSelectedRadioButton')
], AdvancedFiltersModal.prototype, "setSelectedRadioButton", null);
__decorate([
    Emit('closeModal')
], AdvancedFiltersModal.prototype, "closeModal", null);
__decorate([
    Emit('setSelectedFilterOracle')
], AdvancedFiltersModal.prototype, "setSelectedFilterOracle", null);
__decorate([
    Emit('setRemoveFilterOracle')
], AdvancedFiltersModal.prototype, "setRemoveFilterOracle", null);
__decorate([
    Emit('resetSpecialFilters')
], AdvancedFiltersModal.prototype, "resetSpecialFilters", null);
__decorate([
    Watch('filterGroups')
], AdvancedFiltersModal.prototype, "onFilterGroupsChange", null);
__decorate([
    Emit('setSelectedFilteredType')
], AdvancedFiltersModal.prototype, "setSelectFilteredType", null);
__decorate([
    Emit('setSelectedGPTKeyWord')
], AdvancedFiltersModal.prototype, "setSelectedGPTKeyWord", null);
AdvancedFiltersModal = __decorate([
    Component({
        name: 'AdvancedFiltersModal',
        components: {
            Modal,
            NewModal,
            Input,
            LocalizationPanel,
            SendCNPJSPanel,
            ActivityPanel,
            PortePanel,
            LegalAndTaxPanel,
            ExcludeFromResultPanel,
            GoodsAndPropertiesPanel,
            FinancialAndInvestimentsPanel,
            ContractsAndGovernmentPanel,
            SectorSpecificDataPanel,
            RecordsAndLicensePanel,
            TechnologyAndToolsPanel,
            CompetitionAndMarketPanel,
            ManagementAndAdministrationPanel,
            ComplaintsPanel,
            DecisionMakersPanel,
            InnerLoading,
            AdvancedFiltersModalAlert,
            AdvancedFiltersModalUnlockFilter,
        },
    })
], AdvancedFiltersModal);
export default AdvancedFiltersModal;
