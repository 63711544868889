var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { CompanyStatus, SearchStatus } from '@/overmind/search/state';
import Pagination from 'components/Pagination.vue';
import Input from '@/components/Input.vue';
import { json } from 'overmind';
import SearchCompanyCard from '@/modules/search/components/SearchCompanyCard.vue';
let SearchResultsFilters = class SearchResultsFilters extends Vue {
    constructor() {
        super(...arguments);
        this.searchTermPagination = '';
        this.currentIndex = null;
        this.pageNotFound = false;
        this.currentCompanyPage = undefined;
    }
    mounted() {
        setTimeout(() => {
            if (this.showUpdateAccountDataModal)
                this.openCompanyDrawer(this.companies[0].cnpj, 0, true);
        }, 2000);
    }
    selectCompany(company, selected) {
        return { company, selected };
    }
    companyIsActive(index) {
        return (index === this.currentIndex &&
            this.currentCompanyCNPJ &&
            this.currentCompanyPage == this.page);
    }
    handleBadges(company, type) {
        if (company.badges === null) {
            return false;
        }
        else {
            const index = json(company.badges.available_data).findIndex(item => item === type);
            if (index !== -1) {
                return true;
            }
            else {
                return false;
            }
        }
    }
    openCompanyDrawer(cnpj, index, btnClicked = false) {
        // Verificar aqui porque pode ter um bug no companyStatus pois ela só existe como prop
        // porem o searchbody não passa o companystatus para este componente
        if (this.companyStatus !== CompanyStatus.FETCHING) {
            this.currentIndex = index;
            this.currentCompanyPage = this.page;
            this.$emit('setDoneFirstSearch');
            if (cnpj !== this.currentCompanyCNPJ) {
                // O btnClicked 'força' o modal abrir sem time
                this.$emit('openCompanyDrawer', cnpj, this.userJourney, btnClicked);
            }
        }
    }
    setPageNumber(pageNumber) {
        return pageNumber;
    }
    searchPagination(searchTerm) {
        const parseSearchTermToNumber = Number(searchTerm);
        this.searchTermPagination = '';
        if (parseSearchTermToNumber > this.totalPage) {
            this.$q.notify({
                message: `O valor inserido excedeu o limite de páginas! Insira um número de página menor ou equivalente a ${this.totalPage}`,
                type: 'warning',
            });
            return 1;
        }
        else if (parseSearchTermToNumber <= this.totalPage)
            return parseSearchTermToNumber;
    }
};
__decorate([
    Prop({ default: () => [] })
], SearchResultsFilters.prototype, "companies", void 0);
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], SearchResultsFilters.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], SearchResultsFilters.prototype, "companyStatus", void 0);
__decorate([
    Prop({ default: '' })
], SearchResultsFilters.prototype, "currentCompanyCNPJ", void 0);
__decorate([
    Prop({ default: [] })
], SearchResultsFilters.prototype, "selectedCompanies", void 0);
__decorate([
    Prop({ default: null })
], SearchResultsFilters.prototype, "userJourney", void 0);
__decorate([
    Prop({ default: false })
], SearchResultsFilters.prototype, "showUpdateAccountDataModal", void 0);
__decorate([
    Prop()
], SearchResultsFilters.prototype, "totalPage", void 0);
__decorate([
    Prop()
], SearchResultsFilters.prototype, "page", void 0);
__decorate([
    Emit('toggleSelectCompany')
], SearchResultsFilters.prototype, "selectCompany", null);
__decorate([
    Emit('setPageNumber')
], SearchResultsFilters.prototype, "setPageNumber", null);
__decorate([
    Emit('searchPagination')
], SearchResultsFilters.prototype, "searchPagination", null);
SearchResultsFilters = __decorate([
    Component({
        name: 'SearchResultsFilters',
        components: {
            Pagination,
            Input,
            SearchCompanyCard,
        },
    })
], SearchResultsFilters);
export default SearchResultsFilters;
