var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import DistanceFromALocationContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/DistanceFromALocation.container.vue';
import StreetNameContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/StreetName.container.vue';
import NeighborhoodsContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/Neighborhoods.container.vue';
import CEPsContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/CEPs.container.vue';
import CountriesContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/Countries.container.vue';
import StatesContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/States.container.vue';
import MicroregionContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/Microregion.container.vue';
import MesoregionContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/Mesoregion.container.vue';
import RegionContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/Region.container.vue';
import AbroadCityContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/AbroadCity.container.vue';
import CompanyCountryOriginContainer from '@/modules/search/containers/filterPanels/LocalizationPanel/CompanyCountryOrigin.container.vue';
let LocalizationPanel = class LocalizationPanel extends Vue {
    constructor() {
        super(...arguments);
        this.States = {};
        this.Countries = {};
        this.Mesoregion = {};
        this.Region = {};
        this.Microregion = {};
        this.DistanceFromALocation = {};
        this.CEPs = {};
        this.Neighborhoods = {};
        this.StreetName = {};
        this.AbroadCity = {};
        this.CompanyCountryOrigin = {};
    }
    verifyPositions() {
        this.filters.forEach(f => {
            switch (f.id) {
                case 21:
                    this.States = f;
                    break;
                case 7:
                    this.Countries = f;
                    break;
                case 990655:
                    this.Region = f;
                    break;
                case 9:
                    this.Mesoregion = f;
                    break;
                case 8:
                    this.Microregion = f;
                    break;
                case 4:
                    this.DistanceFromALocation = f;
                    break;
                case 6:
                    this.CEPs = f;
                    break;
                case 5555:
                    this.Neighborhoods = f;
                    break;
                case 5:
                    this.StreetName = f;
                    break;
                case 11:
                    this.AbroadCity = f;
                    break;
                case 12:
                    this.CompanyCountryOrigin = f;
                    break;
                default:
                    break;
            }
        });
    }
    beforeMount() {
        this.verifyPositions();
    }
};
__decorate([
    Prop({ default: () => [] })
], LocalizationPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: true })
], LocalizationPanel.prototype, "canSearch", void 0);
LocalizationPanel = __decorate([
    Component({
        components: {
            DistanceFromALocationContainer,
            StreetNameContainer,
            NeighborhoodsContainer,
            CEPsContainer,
            CountriesContainer,
            StatesContainer,
            MicroregionContainer,
            MesoregionContainer,
            AbroadCityContainer,
            CompanyCountryOriginContainer,
            RegionContainer,
        },
    })
], LocalizationPanel);
export default LocalizationPanel;
