var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import CEPs from '@/modules/search/components/filterPanels/LocalizationPanel/CEPs.vue';
import { connect } from '@/overmind';
let CEPsContainer = class CEPsContainer extends Vue {
    setSelectedFilterCep({ value, type, }) {
        // @ts-ignore TS2349: property inexistent
        this.actions.setSelectedFilterCep({ value, type });
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
};
__decorate([
    Prop({ default: () => { } })
], CEPsContainer.prototype, "filter", void 0);
CEPsContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        actions: actions.filters,
        actionsSearch: actions.search,
        selectedCEPs: state.selectedFilterCeps,
    }), {
        components: {
            CEPs,
        },
    }))
], CEPsContainer);
export default CEPsContainer;
