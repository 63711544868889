var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { deburr, escapeRegExp, uniqBy } from 'lodash';
import { json } from 'overmind';
import Input from 'components/Input.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let ManagementAndAdministrationPanel = class ManagementAndAdministrationPanel extends Vue {
    constructor() {
        super(...arguments);
        this.filterOptions = this.filters.map(f => f.filterOptions);
        this.filteredFilters = json(this.filters);
        this.currentFilterIndex = 0;
        this.searchTerm = '';
    }
    onFullTextSearchChange(curr, old) {
        this.searchTerm = this.fullTextTerm;
        this.filters.forEach(f => {
            this.onSearchFilters(f, this.fullTextTerm);
            this.currentFilterIndex += 1;
        });
        this.currentFilterIndex -= 1;
    }
    async onFilterIndexChange(curr, old) {
        this.filterOptions[curr] =
            this.filters[this.currentFilterIndex].filterOptions;
    }
    onFiltersChange(curr, old) {
        if (curr) {
            // @ts-ignore
            this.filters = curr;
            //this.filters = Object.freeze(json(this.filters));
        }
    }
    selectedCount(filter) {
        if (filter.queryType === 'multiSelect') {
            return filter
                ? filter.filterOptions.filter(option => option.isSelected).length
                : 0;
        }
        return 0;
    }
    onSearchFilters(filter, event) {
        if (event == null) {
            this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
            return;
        }
        let filterOptions = json(this.filterOptions[this.currentFilterIndex].filter(o => {
            const deburedTerm = escapeRegExp(deburr(this.searchTerm));
            if (o.tags) {
                const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                if (deburedMatch)
                    return deburedMatch;
            }
            const deburedLabel = o.label ? deburr(o.label) : undefined;
            return o.label && deburedLabel
                ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                : false;
        }));
        filterOptions = uniqBy(filterOptions, 'value');
        this.filteredFilters[this.currentFilterIndex].filterOptions =
            json(filterOptions);
    }
    onHideFilter(filterIndex) {
        if (this.fullTextTerm === '') {
            this.filteredFilters[filterIndex].filterOptions = json(this.filters[filterIndex].filterOptions);
        }
    }
    onShowFilter(filterIndex, filterTitle) {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + filterTitle);
        this.currentFilterIndex = filterIndex;
        this.searchTerm = '';
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        const option = this.filteredFilters[filterIndex].filterOptions.find(o => o.value === itemId);
        if (option) {
            this.filteredFilters[this.currentFilterIndex].filterOptions[filterOptionIndex].isSelected = value;
        }
        this.$emit('setCheckbox', filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId);
    }
    beforeDestroy() {
        this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
    }
    mounted() {
        this.searchTerm = this.fullTextTerm;
        if (this.fullTextTerm !== '') {
            this.filters.forEach(f => {
                this.onSearchFilters(f, this.fullTextTerm);
                this.currentFilterIndex += 1;
            });
            this.currentFilterIndex -= 1;
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], ManagementAndAdministrationPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: 1 })
], ManagementAndAdministrationPanel.prototype, "filterGroupId", void 0);
__decorate([
    Prop({ default: '' })
], ManagementAndAdministrationPanel.prototype, "fullTextTerm", void 0);
__decorate([
    Watch('fullTextTerm')
], ManagementAndAdministrationPanel.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], ManagementAndAdministrationPanel.prototype, "onFilterIndexChange", null);
__decorate([
    Watch('filters')
], ManagementAndAdministrationPanel.prototype, "onFiltersChange", null);
ManagementAndAdministrationPanel = __decorate([
    Component({
        components: {
            Input,
        },
    })
], ManagementAndAdministrationPanel);
export default ManagementAndAdministrationPanel;
