var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { deburr, escapeRegExp, uniqBy } from 'lodash';
import { json } from 'overmind';
import Input from 'components/Input.vue';
import ProconAverageComplaintsSixMonthsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconAverageComplaintsSixMonths.container.vue';
import ProconNumberOfComplaintsSixMonthsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconNumberOfComplaintsSixMonths.container.vue';
import ProconNumberOfComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconNumberOfComplaints.container.vue';
import ReclameAquiNumberOfComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiNumberOfComplaints.container.vue';
import ReclameAquiPercentageOfComplaintsResolvedContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiPercentageOfComplaintsResolved.container.vue';
import ReclameAquiAverageMonthlyComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiAverageMonthlyComplaints.container.vue';
import ReclameAquiComplaintScoreContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiComplaintScore.container.vue';
import MultipleChoiceFilter from '@/modules/search/components/MultipleChoiceFilter.vue';
let ComplaintsPanel = class ComplaintsPanel extends Vue {
    constructor() {
        super(...arguments);
        this.filterOptions = this.filters.map(f => f.filterOptions);
        this.filteredFilters = json(this.filters);
        this.currentFilterIndex = this.fullTextTerm ? 0 : 7;
        this.searchTerm = '';
    }
    onFullTextSearchChange(curr, old) {
        this.searchTerm = this.fullTextTerm;
        this.currentFilterIndex = 0;
        this.filters.forEach(f => {
            this.onSearchFilters(f, this.fullTextTerm);
            this.currentFilterIndex += 1;
        });
        this.currentFilterIndex -= 1;
    }
    async onFilterIndexChange(curr, old) {
        if (this.fullTextTerm)
            this.currentFilterIndex = 0;
        this.filterOptions[curr] =
            this.filters[this.currentFilterIndex].filterOptions;
    }
    onFiltersChange(curr, old) {
        if (curr) {
            // @ts-ignore
            this.filters = curr;
            //this.filters = Object.freeze(json(this.filters));
        }
    }
    get allowedFilters() {
        const isProconFilter = (tag, queryType) => tag.match(/procon/) && queryType.match(/multiSelect/);
        return this.filteredFilters.filter(item => [
            'reclame aqui categoria problemas',
            'reclame aqui tipo problemas',
            'reclame aqui produtos',
            'reclame aqui selo RA1000',
        ].includes(item.groupTag) ||
            isProconFilter(item.groupTag, item.queryType));
    }
    findQueryFieldBy(queryField) {
        return this.filters.find(f => f.queryField === queryField);
    }
    onSearchFilters(filter, event) {
        if (this.fullTextTerm) {
            this.currentFilterIndex = 0;
        }
        if (!event) {
            // if (this.filteredFilters && this.filteredFilters.length >= 0) {
            this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
            // }
            return;
        }
        let filterOptions = json(this.filterOptions[this.currentFilterIndex].filter(o => {
            const deburedTerm = escapeRegExp(deburr(this.searchTerm));
            if (o.tags) {
                const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                if (deburedMatch)
                    return deburedMatch;
            }
            const deburedLabel = o.label ? deburr(o.label) : undefined;
            return o.label && deburedLabel
                ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                : false;
        }));
        filterOptions = uniqBy(filterOptions, 'value');
        this.filteredFilters[this.currentFilterIndex].filterOptions =
            json(filterOptions);
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        const filter = this.filteredFilters.find(f => f.id === filterId);
        if (filter) {
            const option = filter.filterOptions.find(o => o.value === itemId);
            if (option) {
                option.isSelected = value;
            }
            this.$emit('setCheckbox', filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId);
        }
    }
    mounted() {
        this.searchTerm = this.fullTextTerm;
    }
};
__decorate([
    Prop({ default: () => [] })
], ComplaintsPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: 1 })
], ComplaintsPanel.prototype, "filterGroupId", void 0);
__decorate([
    Prop({ default: '' })
], ComplaintsPanel.prototype, "fullTextTerm", void 0);
__decorate([
    Watch('fullTextTerm')
], ComplaintsPanel.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], ComplaintsPanel.prototype, "onFilterIndexChange", null);
__decorate([
    Watch('filters')
], ComplaintsPanel.prototype, "onFiltersChange", null);
ComplaintsPanel = __decorate([
    Component({
        components: {
            Input,
            ReclameAquiNumberOfComplaintsContainer,
            ReclameAquiPercentageOfComplaintsResolvedContainer,
            ReclameAquiComplaintScoreContainer,
            ReclameAquiAverageMonthlyComplaintsContainer,
            ProconAverageComplaintsSixMonthsContainer,
            ProconNumberOfComplaintsSixMonthsContainer,
            ProconNumberOfComplaintsContainer,
            MultipleChoiceFilter,
        },
    })
], ComplaintsPanel);
export default ComplaintsPanel;
