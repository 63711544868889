var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Microregion from '@/modules/search/components/filterPanels/LocalizationPanel/Microregion.vue';
import { connect } from '@/overmind';
let MicroregionContainer = class MicroregionContainer extends Vue {
    setMicroregionCheckbox({ filterOptionIndex, value, itemId, }) {
        // @ts-ignore TS2349: property inexistent
        this.actions.updateFilterOption({
            filterGroupId: 2,
            filterId: 8,
            filterOptionIndex,
            value,
            itemId,
        });
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    get selectedMicroregion() {
        // @ts-ignore TS2349: property inexistent
        return this.selectedCheckbox.filter((f) => f.queryField === 'co_microrregiao_ibge');
    }
};
__decorate([
    Prop({ default: () => { } })
], MicroregionContainer.prototype, "filter", void 0);
MicroregionContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        actions: actions.filters,
        actionsSearch: actions.search,
        searchTerm: state.searchTerm,
        selectedCheckbox: state.selectedCheckbox,
    }), {
        components: {
            Microregion,
        },
    }))
], MicroregionContainer);
export default MicroregionContainer;
