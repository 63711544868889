var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EmployeesQueryFieldOptions, RevenuesQueryFieldOptions, } from '@/interfaces/filters.interfaces';
import PageAllFiltersSelected from '@/modules/search/components/PageAllFiltersSelected.vue';
import { connect } from '@/overmind';
import { getCountTotalSelected, getFilterCep, getFilterCNPJsWherenot, getFilterDateFilter, getFilterNeighborhoods, getFilterOptionsByCNPJ, getRemoveFilterOracle, getRemoveFilterCompanyName, getSelectedCheckbox, getSelectedFilterOracle, getSelectedInputRange, getStateInputFilters, getStateSliderFilters, getSelectedFilterGPT, } from '@/shared/utils/SelectedFilters.utils';
import { concat } from 'lodash';
import { json } from 'overmind';
import { Component, Emit, Vue } from 'vue-property-decorator';
let PageAllFiltersSelectedContainer = class PageAllFiltersSelectedContainer extends Vue {
    totalSelectedFilters() {
        //@ts-ignore
        return json(this.totalSelected);
    }
    closeAllSelectedFilters() {
        return false;
    }
    resetAllFilters() {
        return true;
    }
    resultTotalSelections() {
        //@ts-ignore TS2349: property inexistent
        return getCountTotalSelected(this.totalSelected);
    }
    getSelected() {
        return [
            // @ts-ignore
            ...getSelectedCheckbox(this.selectedCheckbox, this.selectedFilteredType),
            // @ts-ignore
            ...getSelectedInputRange(this.selectedInputRange),
            // @ts-ignore
            ...getFilterDateFilter(this.dateFilter),
            // @ts-ignore
            ...getStateSliderFilters(this.stateSliderFilters, this.totalSelected),
            // @ts-ignore
            ...getSelectedFilterOracle(this.selectedFilterOracle),
            ...getSelectedFilterGPT(
            // @ts-ignore
            this.stateInputFilters['additional_data_atlas.data_gpt.product_service']
                .value),
            // @ts-ignore
            ...getRemoveFilterOracle(this.removeFilterOracle),
            // @ts-ignore
            ...getRemoveFilterCompanyName(this.removeFilterCompanyName),
            // @ts-ignore
            ...getStateInputFilters(this.stateInputFilters),
            ...getFilterOptionsByCNPJ(),
            // @ts-ignore
            ...getFilterNeighborhoods(this.selectedFilterNeighborhoods),
            // @ts-ignore
            ...getFilterCep(this.selectedFilterCeps),
            ...getFilterCNPJsWherenot(),
        ];
    }
    departmentAndLevelIsSelected() {
        const department = this.getSelected().filter(f => f.queryField.match('department')).length >
            0;
        const level = this.getSelected().filter(f => f.queryField.match('level')).length > 0;
        return department && level;
    }
    async removeLogicAndOR() {
        const decisorsFilter = this.getSelected().filter(f => f.queryField.match('decision_makers_lkd_m5'));
        decisorsFilter.forEach(f => {
            //@ts-ignore
            const decisorFilter = this.filterGroups.find(f => f.id == 701);
            const filterIndx = f.queryField == 'decision_makers_lkd_m5.department' ? 0 : 1;
            let filterOPt;
            decisorFilter.filters[filterIndx].filterOptions.find((filt, i) => {
                if (filt.label == f.label)
                    filterOPt = i;
            });
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.updateFilterOption({
                filterGroupId: 701,
                filterId: f.queryField == 'decision_makers_lkd_m5.department' ? 2501 : 2502,
                filterIndex: filterIndx,
                filterOptionIndex: filterOPt,
                value: false,
                itemId: f.value,
            });
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.setSelectedFilteredType('');
        });
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowCompany(false);
        await this.createNewSearch();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    selectedFilterGroups() {
        let groups = this.buildSelectedFilterGroups();
        const selectedItems = this.getSelected();
        const getCNPJselected = selectedItems.find(r => r.queryField === 'nu_cnpj');
        if (getCNPJselected) {
            groups[6].filters = [
                {
                    ...getCNPJselected,
                    id: 9,
                    groupTag: 'cnpjs',
                    queryType: 'inputText',
                    title: 'CNPJs',
                    items: [],
                },
            ];
        }
        // List all queryfields for activity filter items
        const activitySectorAndCnaeQueryFields = [
            'main_segment',
            'main_cnae_group_code',
            'cnae1',
            'cnae2',
            'smart_cnae1',
            'cnae_all',
        ];
        const itemHasActivityQueryfields = (filter) => {
            if (filter.queryField === '_multiple_activity_') {
                return selectedItems.find(item => activitySectorAndCnaeQueryFields.includes(item.queryField));
            }
        };
        const itemHasMunicipioQueryfield = (filter) => {
            if (filter.queryField === '_multiple_municipios_') {
                return selectedItems.find(item => item.queryField === 'co_municipio_ibge');
            }
        };
        const itemHasStateQueryfield = (filter) => {
            if (filter.queryField === '_multiple_estados_') {
                return selectedItems.find(item => item.queryField === 'sg_uf');
            }
        };
        const itemHasSameQueryField = (filter) => {
            return selectedItems.find(item => item.queryField == filter.queryField);
        };
        const itemHasQueryFieldInWhiteList = (filter) => {
            return [
                '_multiple_redes_social_',
                '_multiple_redes_social_@where_not',
                '_multiple_decisors_with_',
            ].includes(filter.queryField);
        };
        // Retirar dos groups os filters que não possuem items selecionados
        groups = groups.map(group => ({
            ...group,
            filters: group.filters.filter(f => itemHasSameQueryField(f) ||
                itemHasQueryFieldInWhiteList(f) ||
                itemHasActivityQueryfields(f) ||
                itemHasMunicipioQueryfield(f) ||
                itemHasStateQueryfield(f)),
        }));
        groups = groups.filter(group => group.filters.length);
        // Coloque os itens selecionados dentro de cada filter para cada group
        return groups.map(group => ({
            ...group,
            filters: group.filters.map(filter => ({
                ...filter,
                items: selectedItems.filter(item => {
                    if (item.queryField === filter.queryField)
                        return true;
                    if (filter.queryField === '_multiple_activity_' &&
                        activitySectorAndCnaeQueryFields.includes(item.queryField))
                        return true;
                    if (filter.queryField === '_multiple_municipios_' &&
                        item.queryField === 'co_municipio_ibge')
                        return true;
                    if (filter.queryField === '_multiple_estados_' &&
                        item.queryField === 'sg_uf')
                        return true;
                    if (filter.queryField === '_multiple_decisors_with_' &&
                        /^decision_makers_lkd_m5.*@exists$/.test(item.queryField))
                        return true;
                    if (filter.queryField === '_multiple_redes_social_' &&
                        [
                            'additional_data_atlas.website_secondary.data.type',
                            'contact_data_consolidated.phones.validations.whatsapp.result',
                        ].includes(item.queryField))
                        return true;
                    if (filter.queryField === '_multiple_redes_social_@where_not' &&
                        [
                            'website_secondary.data.type@where_not',
                            'phones.validations.whatsapp.result@where_not',
                        ].some(f => item.queryField.includes(f)))
                        return true;
                    return false;
                }),
            })),
        }));
    }
    buildSelectedFilterGroups() {
        return json(
        // @ts-ignore
        this.filterGroups.map(g => ({
            id: g.id,
            groupName: g.groupName,
            groupTag: g.groupTag,
            filters: g.filters.map(f => ({
                id: f.id,
                title: f.title,
                groupTag: f.groupTag,
                queryField: f.queryField,
                queryType: f.queryType,
                items: [],
                exclusiveOptionValue: '',
            })),
        })));
    }
    removeSelectedFilterGPT(event) {
        if (event) {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setSelectedFilterGPT({
                value: event,
                type: 'remove',
            });
        }
    }
    async removeExclusiveOptionSelected(selectedFilter) {
        //@ts-ignore TS2349: property inexistent
        this.actionsFilters.removeSelectedFilter(selectedFilter);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowCompany(false);
        await this.createNewSearch();
    }
    async removeSelectedFilterByFilterOptionModel({ filterOption, filter, filterGroup, }) {
        //@ts-ignore TS2349: property inexistent
        const filterGroups = json(this.filterGroups);
        if (filter.queryType === 'multiSelect') {
            this.setCheckbox({
                filterGroupId: filterGroup.id,
                filterId: filter.id,
                itemId: filterOption.value,
            });
        }
        else if (filter.queryField === 'additional_data_atlas.data_gpt.product_service') {
            this.removeSelectedFilterGPT(filterOption.value);
        }
        else if (filter.queryField === 'client_flags_array' ||
            filter.queryField === 'client_flags_array@where_not') {
            this.removeSelectedFilterOracle({
                value: filterOption.value,
                wherenot: filter.queryField.includes('where_not'),
            });
        }
        else if (filter.queryField === 'no_empresa@where_not') {
            this.removeSelectedFilterCompanyName(filterOption.value);
        }
        else if (filter.queryType === 'rangeText') {
            const value = {
                groupTag: filterGroup.groupTag,
                queryField: filterOption.value,
                startDate: '',
                endDate: '',
            };
            this.setInputRange(value);
        }
        else if (filter.queryField === 'nu_cnpj') {
            this.removeCnpj(filter.groupTag, filter.queryField);
        }
        else if (filter.queryField === 'nu_cnpj@where_not') {
            this.removeCnpj(filterGroup.groupTag, filter.queryField, 'filters_deleted_cnpj');
        }
        else if (filter.queryType === 'inputText') {
            this.setInputFilter({ queryField: filterOption.queryField, value: '' });
        }
        else if (filter.queryField === 'nu_cep') {
            // @ts-ignore
            this.actionsFilters.setSelectedFilterCep({
                value: filterOption.label,
                type: 'remove',
            });
        }
        else if (filter.queryField === 'no_bairro') {
            // @ts-ignore
            this.actionsFilters.setSelectedFilterNeighborhood({
                value: [filterOption.label],
                type: 'remove',
            });
        }
        else if (filterOption.queryField === 'cep_distance') {
            // @ts-ignore
            this.actionsFilters.clearDistanceFilter();
            // @ts-ignore
            this.actionsFilters.removeTotalSelected({
                groupTag: 'localização',
                queryField: 'cep_distance',
            });
        }
        else if (concat(EmployeesQueryFieldOptions, RevenuesQueryFieldOptions).includes(filter.queryField)) {
            // @ts-ignore
            this.actionsFilters.resetRangeFilterBy(filter.queryField);
        }
        else if (filter.queryType === 'rangeDate') {
            // @ts-ignore
            this.actionsFilters.setFilterDate({
                queryField: filter.queryField,
                inDate: '',
                toDate: '',
            });
        }
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowCompany(false);
        await this.createNewSearch();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    async createNewSearch() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.currentSearchId },
        })
            .catch(err => err);
    }
    removeSelectedFilterOracle(event) {
        if (event.wherenot) {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setRemoveFilterOracle({
                value: event.value,
                type: 'remove',
            });
        }
        else {
            // @ts-ignore TS2349: property inexistent
            this.actionsFilters.setSelectedFilterOracle({
                value: event.value,
                type: 'remove',
            });
        }
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
    }
    getLocaleFilterCheckboxModelByFilterOptionModel(filterGroups, filterGroup, filterModel, filterOption) {
        const filterIndex = filterGroups.findIndex(item => item.id === filterGroup.id);
        const filterOptionIndex = filterModel.filterOptions.findIndex(item => item.value === filterOption.value);
        return {
            filterGroupId: filterGroup.id,
            filterId: filterModel.id,
            value: false,
            itemId: filterOption.value,
            filterIndex,
            filterOptionIndex,
        };
    }
    removeCnpj(groupTag = 'cnpjs', queryField = '', item = 'filters_cnpj') {
        sessionStorage.setItem(item, '[]');
        //@ts-ignore TS2349: property inexistent
        this.actionsFilters.removeTotalSelected({
            groupTag,
            queryField,
        });
    }
    setCheckbox({ filterGroupId, filterId, itemId, }) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.updateFilterOption({
            filterGroupId,
            filterId,
            filterIndex: null,
            filterOptionIndex: null,
            value: false,
            itemId,
        });
    }
    setInputRange(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputRange(event);
    }
    setInputFilter(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputFilters(event);
    }
    removeSelectedFilterCompanyName(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setWhereNotNoEmpresa({
            value: value,
            type: 'remove',
        });
    }
};
__decorate([
    Emit('closeAllSelectedFilters')
], PageAllFiltersSelectedContainer.prototype, "closeAllSelectedFilters", null);
__decorate([
    Emit('resetAllFilters')
], PageAllFiltersSelectedContainer.prototype, "resetAllFilters", null);
PageAllFiltersSelectedContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
        selectedCheckbox: state.filters.selectedCheckbox,
        filterGroups: state.filters.filterGroups,
        selectedInputRange: state.filters.selectedInputRange,
        totalSelected: state.filters.totalSelected,
        exclusiveOptions: state.filters.exclusiveOptions,
        dateFilter: state.filters.dateFilter,
        stateSliderFilters: state.filters.stateSliderFilters,
        selectedFilterOracle: state.filters.selectedFilterOracle,
        selectedGPTFilter: state.filters.selectedGPTFilter,
        removeFilterOracle: state.filters.removeFilterOracle,
        stateInputFilters: state.filters.stateInputFilters,
        selectedFilterCeps: state.filters.selectedFilterCeps,
        selectedFilterNeighborhoods: state.filters.selectedFilterNeighborhoods,
        currentSearchId: state.search.currentSearchId,
        removeFilterCompanyName: state.filters.removeEmpresa,
        selectedFilteredType: state.filters.selectedFilteredType,
    }), {
        components: {
            PageAllFiltersSelected,
        },
    }))
], PageAllFiltersSelectedContainer);
export default PageAllFiltersSelectedContainer;
