/* eslint-disable @typescript-eslint/indent */
export const separateValuesWithSymbols = (value) => {
    const arrVal = [];
    value.split(' ').forEach(spaceSplit => spaceSplit.split(',').forEach(commaSplit => commaSplit.split(';').forEach(semicolonSplit => semicolonSplit.split('\n').forEach(tag => {
        arrVal.push(tag.replace(/\D+/g, ''));
    }))));
    return arrVal ? arrVal : [];
};
export const lJustify = (str, targetLength, paddingString = ' ') => {
    let newString = str;
    while (newString.length < targetLength) {
        newString = paddingString + newString;
    }
    return newString;
};
