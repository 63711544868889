var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { json } from 'overmind';
import { deburr, escapeRegExp } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let CompanyCountryOrigin = class CompanyCountryOrigin extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.currentFilterIndex = 0;
        this.filteredFilters = json(this.filterOptions);
    }
    setCompanyCountryOriginCheckbox(filterOptionIndex, value, itemId) {
        const option = this.filteredFilters.find(o => o.value === itemId);
        if (option) {
            this.filteredFilters[filterOptionIndex].isSelected = value;
        }
        return { filterOptionIndex, value, itemId };
    }
    countItemsSelected() {
        return this.filterOptions
            ? this.filterOptions.filter(option => option.isSelected).length
            : 0;
    }
    onSearchFilters(filter, event) {
        if (event == null) {
            this.filteredFilters[this.currentFilterIndex] = json(this.filterOptions[this.currentFilterIndex]);
            return;
        }
        const filterOptions = json(this.filterOptions.filter(o => {
            const deburedTerm = escapeRegExp(deburr(this.searchTerm));
            if (o.tags) {
                const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                if (deburedMatch)
                    return deburedMatch;
            }
            const deburedLabel = o.label ? deburr(o.label) : undefined;
            return o.label && deburedLabel
                ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                : false;
        }));
        this.filteredFilters = json(filterOptions);
    }
    handleTrackingCompanyCountryOrigin() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: () => [] })
], CompanyCountryOrigin.prototype, "filterOptions", void 0);
__decorate([
    Prop({ default: () => [] })
], CompanyCountryOrigin.prototype, "companyCountryOriginSelected", void 0);
__decorate([
    Prop({ default: '' })
], CompanyCountryOrigin.prototype, "fullTextTerm", void 0);
__decorate([
    Prop({ default: '' })
], CompanyCountryOrigin.prototype, "filterName", void 0);
__decorate([
    Emit('setCompanyCountryOriginCheckbox')
], CompanyCountryOrigin.prototype, "setCompanyCountryOriginCheckbox", null);
CompanyCountryOrigin = __decorate([
    Component({
        components: {
            Input,
        },
    })
], CompanyCountryOrigin);
export default CompanyCountryOrigin;
