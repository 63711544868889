var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
let InterestsOfDecisionMakers = class InterestsOfDecisionMakers extends Vue {
    constructor() {
        super(...arguments);
        this.values = this.interestsOfDecisionMakers
            .map(value => {
            return {
                name: value.key,
                weight: value.doc_count,
            };
        })
            .filter(item => item.name !== 'Não há Páginas para mostrar.');
        this.chartOptions = {
            chart: {
                type: 'wordcloud',
                spiral: 'archimedean',
            },
            title: {
                text: null,
            },
            tooltip: {
                headerFormat: '<span>{series.name}</span>',
                pointFormat: '<span style="font-weight: bold">{point.name}</span>: {point.weight}',
            },
            series: [
                {
                    name: '',
                    data: json(this.values),
                },
            ],
            credits: false,
        };
    }
    interestsOfDecisionMakersChanged(current) {
        this.chartOptions.series[0].data = json(current
            .map(value => {
            return {
                name: value.key,
                weight: value.doc_count,
            };
        })
            .filter(item => item.name !== 'Não há Páginas para mostrar.'));
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], InterestsOfDecisionMakers.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], InterestsOfDecisionMakers.prototype, "interestsOfDecisionMakers", void 0);
__decorate([
    Prop({ default: 1 })
], InterestsOfDecisionMakers.prototype, "page", void 0);
__decorate([
    Watch('interestsOfDecisionMakers')
], InterestsOfDecisionMakers.prototype, "interestsOfDecisionMakersChanged", null);
InterestsOfDecisionMakers = __decorate([
    Component({
        components: {
            SearchNotFoundHeader,
        },
    })
], InterestsOfDecisionMakers);
export default InterestsOfDecisionMakers;
