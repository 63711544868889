var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import NumberOfEmployees from '@/modules/search/components/charts/NumberOfEmployees.vue';
import EconomicActivity from '@/modules/search/components/charts/EconomicActivity.vue';
import TaxationModality from '@/modules/search/components/charts/TaxationModality.vue';
import AnnualBilling from '@/modules/search/components/charts/AnnualBilling.vue';
import Sectors from '@/modules/search/components/charts/Sectors.vue';
import State from '@/modules/search/components/charts/State.vue';
import CloundProvider from '@/modules/search/components/charts/advanced/CloundProvider.vue';
import CompanyPractices from '@/modules/search/components/charts/advanced/CompanyPractices.vue';
import DecisionMakingDepartment from '@/modules/search/components/charts/advanced/DecisionMakingDepartment.vue';
import EmphasesOfCompanies from '@/modules/search/components/charts/advanced/EmphasesOfCompanies.vue';
import FormationOfDecisionMakers from '@/modules/search/components/charts/advanced/FormationOfDecisionMakers.vue';
import InterestsOfDecisionMakers from '@/modules/search/components/charts/advanced/InterestsOfDecisionMakers.vue';
import ManagementFeature from '@/modules/search/components/charts/advanced/ManagementFeature.vue';
import SkillOfDecisionMakers from '@/modules/search/components/charts/advanced/SkillOfDecisionMakers.vue';
import UseOfTechnology from '@/modules/search/components/charts/advanced/UseOfTechnology.vue';
import TotalDecisionMakers from '@/modules/search/components/charts/advanced/TotalDecisionMakers.vue';
import WithPhones from '@/modules/search/components/charts/advanced/WithPhones.vue';
import WithEmails from '@/modules/search/components/charts/advanced/WithEmails.vue';
import WithDebts from '@/modules/search/components/charts/advanced/WithDebts.vue';
import { connect } from '@/overmind';
let SearchStatsBodyContainer = class SearchStatsBodyContainer extends Vue {
    creditsPercentage() {
        return {
            //@ts-ignore
            clicks: this.accountCredits.clicks.percentage,
            //@ts-ignore
            export: this.accountCredits.export.percentage,
        };
    }
};
SearchStatsBodyContainer = __decorate([
    Component(connect(({ state: { search: state, users: userState } }) => ({
        page: state.page,
        graphics: state.graphics,
        createSearchState: state.createSearchState,
        advanced: state.query.search.aggregation_type,
        advancedGraphics: state.advancedGraphics,
        accountCredits: userState.accountCredits,
        currentCompanyCNPJ: state.currentCompanyCNPJ,
    }), {
        components: {
            AnnualBilling,
            EconomicActivity,
            TaxationModality,
            NumberOfEmployees,
            Sectors,
            State,
            UseOfTechnology,
            SkillOfDecisionMakers,
            ManagementFeature,
            InterestsOfDecisionMakers,
            FormationOfDecisionMakers,
            EmphasesOfCompanies,
            DecisionMakingDepartment,
            CompanyPractices,
            CloundProvider,
            WithPhones,
            WithEmails,
            WithDebts,
            TotalDecisionMakers,
        },
    }))
], SearchStatsBodyContainer);
export default SearchStatsBodyContainer;
