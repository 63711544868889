var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import CompanyDrawerHeader from '@/components/CompanyDrawerHeader.vue';
import CompanyDrawerApplets from 'components/CompanyDrawerApplets.vue';
import CompanyDetailsBody from 'components/CompanyDetailsBody.vue';
import CompanyInformationAvailable from 'components/CompanyInformationAvailable.vue';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { CompanyStatus } from '@/overmind/search/state';
import { mixpanelIncrement, mixpanelTracking } from '@/services/mixpanel';
let CompanyDetails = class CompanyDetails extends Vue {
    constructor() {
        super(...arguments);
        this.tab = 'summary';
    }
    handleMixPanelTracking(value) {
        const replacements = {
            summary: 'resumo',
            register: 'registro',
            contact: 'contatos',
            decisors: 'decisores',
            'additional-data': 'dados adicionais',
        };
        return mixpanelTracking(`search: Clicou na aba ${replacements[value]}`);
    }
    closeSection() {
        this.$emit('closeSection');
    }
    searchCompanyCEP(cep) {
        return cep;
    }
    searchCompanyCNAE(event) {
        return event;
    }
    searchCompanyCNPJ(cnpj) {
        return cnpj;
    }
    openModalExport() {
        return true;
    }
    handleTab(tab) {
        return tab;
    }
    getCompanyCnpjsNextPage(page) {
        this.$emit('getCompanyCnpjsNextPage', page);
    }
    mounted() {
        mixpanelTracking('search: Acessou informações de uma empresa');
        mixpanelIncrement('search: Acessou informações de uma empresa');
        this.handleMixPanelTracking(this.tab);
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDetails.prototype, "company", void 0);
__decorate([
    Prop({ default: '' })
], CompanyDetails.prototype, "additionalDataState", void 0);
__decorate([
    Prop({ default: false })
], CompanyDetails.prototype, "noCreditsToGetCompany", void 0);
__decorate([
    Prop({ default: false })
], CompanyDetails.prototype, "isConsultHistory", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyDetails.prototype, "companyStatus", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyDetails.prototype, "hyperbolicData", void 0);
__decorate([
    Watch('tab')
], CompanyDetails.prototype, "handleMixPanelTracking", null);
__decorate([
    Emit('searchCompanyCEP')
], CompanyDetails.prototype, "searchCompanyCEP", null);
__decorate([
    Emit('searchCompanyCNAE')
], CompanyDetails.prototype, "searchCompanyCNAE", null);
__decorate([
    Emit('searchCompanyCNPJ')
], CompanyDetails.prototype, "searchCompanyCNPJ", null);
__decorate([
    Emit('openModalExport')
], CompanyDetails.prototype, "openModalExport", null);
__decorate([
    Emit('changeTab')
], CompanyDetails.prototype, "handleTab", null);
CompanyDetails = __decorate([
    Component({
        components: {
            CompanyDrawerHeader,
            CompanyDrawerApplets,
            CompanyDetailsBody,
            CompanyInformationAvailable,
            SearchNotFoundHeader,
        },
    })
], CompanyDetails);
export default CompanyDetails;
