var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { Chart } from 'highcharts-vue';
import { json } from 'overmind';
let State = class State extends Vue {
    constructor() {
        super(...arguments);
        this.values = this.state.map(item => {
            return {
                name: item.key,
                y: item.doc_count,
            };
        });
        this.chartOptions = {
            chart: {
                type: 'pie',
            },
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                },
            },
            tooltip: {
                headerFormat: '<span>{series.name}</span>',
                pointFormat: '<span style="font-weight: bold">{point.name}</span>: {point.y}',
            },
            series: [
                {
                    name: '',
                    colorByPoint: true,
                    data: this.handleColorValues(json(this.values)),
                },
            ],
            credits: false,
        };
    }
    stateChanged(current) {
        this.chartOptions.series[0].data = this.handleColorValues(json(current).map(value => {
            return {
                name: value.key,
                y: value.doc_count,
            };
        }));
    }
    handleColorValues(current) {
        const colorPalette = [
            '#14AAFF',
            '#C1CB33',
            '#FDD836',
            '#A970EB',
            '#F47C67',
        ];
        current = current.sort((a, b) => {
            return b.y - a.y;
        });
        colorPalette.forEach((color, indexColor) => {
            current.forEach((item, index) => {
                if (index === indexColor)
                    item['color'] = color;
            });
        });
        return current;
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], State.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: () => [] })
], State.prototype, "state", void 0);
__decorate([
    Prop({ default: 1 })
], State.prototype, "page", void 0);
__decorate([
    Watch('state')
], State.prototype, "stateChanged", null);
State = __decorate([
    Component({
        components: {
            highcharts: Chart,
            SearchNotFoundHeader,
        },
    })
], State);
export default State;
