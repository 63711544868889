var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { deburr, uniqBy } from 'lodash';
import { json } from 'overmind';
import Input from 'components/Input.vue';
import ProconAverageComplaintsSixMonthsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconAverageComplaintsSixMonths.container.vue';
import ProconNumberOfComplaintsSixMonthsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconNumberOfComplaintsSixMonths.container.vue';
import ProconNumberOfComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ProconNumberOfComplaints.container.vue';
import ReclameAquiNumberOfComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiNumberOfComplaints.container.vue';
import ReclameAquiPercentageOfComplaintsResolvedContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiPercentageOfComplaintsResolved.container.vue';
import ReclameAquiAverageMonthlyComplaintsContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiAverageMonthlyComplaints.container.vue';
import ReclameAquiComplaintScoreContainer from '@/modules/search/containers/filterPanels/ComplaintsPanel/ReclameAquiComplaintScore.container.vue';
import { escapeRegExp } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let MultipleChoiceFilter = class MultipleChoiceFilter extends Vue {
    constructor() {
        super(...arguments);
        this.searchTerm = '';
        this.filterOptions = json(this.filter.filterOptions);
    }
    onFullTextSearchChange(curr, old) {
        this.searchTerm = this.fullTextTerm;
        this.onSearchFilters(this.filter, this.fullTextTerm);
    }
    async onFilterIndexChange(curr, old) {
        // this.filterOptions[curr] = this.filters[
        //   this.currentFilterIndex
        // ].filterOptions;
    }
    onFiltersChange(curr, old) {
        if (curr) {
            // @ts-ignore
            this.filters = curr;
            //this.filters = Object.freeze(json(this.filters));
        }
    }
    // get allowedFilters(): FilterModel[] {
    //   const isProconFilter = (tag: string, queryType) => tag.match(/procon/) && queryType.match(/multiSelect/)
    //   return this.filteredFilters.filter(item =>
    //     [
    //       'reclame aqui categoria problemas',
    //       'reclame aqui tipo problemas',
    //       'reclame aqui produtos',
    //       'reclame aqui selo RA1000'
    //     ].includes(item.groupTag)
    //     || isProconFilter(item.groupTag, item.queryType)
    //   )
    // }
    selectedCount(filter) {
        if (filter.queryType === 'multiSelect') {
            return filter
                ? filter.filterOptions.filter(option => option.isSelected).length
                : 0;
        }
        return 0;
    }
    onSearchFilters(filter, event) {
        if (!event) {
            // this.filteredFilters[this.currentFilterIndex].filterOptions = json(
            //   this.filterOptions[this.currentFilterIndex]
            // );
            this.filterOptions = json(this.filter.filterOptions);
            return;
        }
        let filterOptions = json(this.filter.filterOptions.filter(o => {
            const deburedTerm = escapeRegExp(deburr(this.searchTerm));
            if (o.tags) {
                const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                if (deburedMatch)
                    return deburedMatch;
            }
            const deburedLabel = o.label ? deburr(o.label) : undefined;
            return o.label && deburedLabel
                ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                : false;
        }));
        this.filterOptions = uniqBy(filterOptions, 'value');
        // this.filteredFilters[this.currentFilterIndex].filterOptions = json(
        //   filterOptions
        // );
    }
    onHideFilter(filterIndex) {
        if (this.fullTextTerm === '') {
            // this.filteredFilters[filterIndex].filterOptions = json(
            //   this.filters[filterIndex].filterOptions
            // );
        }
    }
    onShowFilter(filterIndex) {
        // this.currentFilterIndex = filterIndex;
        this.searchTerm = '';
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId) {
        // const filter = this.filteredFilters.find(f => f.id === filterId)
        // if (filter) {
        const option = this.filter.filterOptions.find(o => o.value === itemId);
        if (option) {
            option.isSelected = value;
        }
        this.$emit('setCheckbox', filterGroupId, filterId, null, filterOptionIndex, value, itemId);
        // }
    }
    handleTrackingMultipleChoiceFilter(filterName) {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + filterName);
    }
    mounted() {
        this.searchTerm = this.fullTextTerm;
        if (this.fullTextTerm !== '') {
            this.onSearchFilters(this.filter, this.fullTextTerm);
        }
    }
};
__decorate([
    Prop({ default: 0 })
], MultipleChoiceFilter.prototype, "filterGroupId", void 0);
__decorate([
    Prop({ default: () => [] })
], MultipleChoiceFilter.prototype, "filter", void 0);
__decorate([
    Prop({ default: '' })
], MultipleChoiceFilter.prototype, "context", void 0);
__decorate([
    Prop({ default: '' })
], MultipleChoiceFilter.prototype, "fullTextTerm", void 0);
__decorate([
    Watch('fullTextTerm')
], MultipleChoiceFilter.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], MultipleChoiceFilter.prototype, "onFilterIndexChange", null);
__decorate([
    Watch('filters')
], MultipleChoiceFilter.prototype, "onFiltersChange", null);
MultipleChoiceFilter = __decorate([
    Component({
        components: {
            Input,
            ReclameAquiNumberOfComplaintsContainer,
            ReclameAquiPercentageOfComplaintsResolvedContainer,
            ReclameAquiComplaintScoreContainer,
            ReclameAquiAverageMonthlyComplaintsContainer,
            ProconAverageComplaintsSixMonthsContainer,
            ProconNumberOfComplaintsSixMonthsContainer,
            ProconNumberOfComplaintsContainer,
        },
    })
], MultipleChoiceFilter);
export default MultipleChoiceFilter;
