var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Input from '@/components/Input.vue';
import { json } from 'overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let AbroadCity = class AbroadCity extends Vue {
    constructor() {
        super(...arguments);
        this.abroadCity = json(this.abroadCitySelected.value) || '';
    }
    setInputAbroadCity(abroadCity) {
        return { queryField: 'no_municipio_exterior', value: abroadCity };
    }
    handleTrackingAbroadCity() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: '' })
], AbroadCity.prototype, "filterName", void 0);
__decorate([
    Prop({ default: 0 })
], AbroadCity.prototype, "selected", void 0);
__decorate([
    Prop({ default: () => { } })
], AbroadCity.prototype, "abroadCitySelected", void 0);
__decorate([
    Emit('setInputAbroadCity')
], AbroadCity.prototype, "setInputAbroadCity", null);
AbroadCity = __decorate([
    Component({
        components: {
            Input,
        },
    })
], AbroadCity);
export default AbroadCity;
