var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Emit } from 'vue-property-decorator';
import UpdateForm from './OnboardingUpdateInfoForm.vue';
import ValidateForm from '../Forms/ValidateForm.vue';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let OnboardingUpdateInfoContent = class OnboardingUpdateInfoContent extends Vue {
    constructor() {
        super(...arguments);
        this.hasPhoneValidated = false;
        this.showPhoneValidation = false;
        this.validateErrorWhatsappNumber = false;
        this.loadingUpdateForm = false;
        this.formData = {};
        this.errorToken = false;
        this.cacheData = {};
        this.data = {};
        this.alertMessage = 'Valide sua conta para continuar acessando seu período de teste gratuito na Speedio.';
        this.btnLoading = false;
        this.statusError = true;
        this.hideBtns = false;
        this.changeTokenPhrase = false;
    }
    whatsaapNumberValidateError() {
        this.showPhoneValidation = false;
        this.validateErrorWhatsappNumber = true;
    }
    get smallFormFields() {
        const isOnboardingSmallFormFields = localStorage.getItem('isOnboardingSmallFormFields') || 'false';
        if (JSON.parse(isOnboardingSmallFormFields))
            return isOnboardingSmallFormFields;
        //@ts-ignore
        return this.userState.isOnboardingSmallFormFields;
    }
    mounted() {
        //@ts-ignore
        const { current_data } = this.userState.userJourney;
        const data = {};
        data['full_name'] = current_data.name;
        data['whatsapp_number'] = current_data.number;
        this.formData = data;
        mixpanelTracking('onboarding: Abriu modal de dados faltantes');
    }
    receiveFormInfo(data) {
        this.loadingUpdateForm = true;
        this.data = data;
        this.cacheData = data;
        // verify if need phone validation
        if (data.whatsapp_number) {
            this.showPhoneValidation = true;
            return;
        }
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'formulario_updateinfo_submetido' });
        // update CRM with account_preferences
        this.updateCRMInfo();
    }
    async updateCRMInfo(confirmation_code) {
        const paramsRoute = this.$route.query['whatsapp_validate_speedio_team'];
        // @ts-ignore
        const tryUpdateUser = await this.actions.updateOnboardingUserJorney({
            confirmation_code,
            ...this.data,
            whatsapp_validate_speedio_team: paramsRoute,
        });
        if (tryUpdateUser.data.success) {
            localStorage.removeItem('isOnboardingSmallFormFields');
            //@ts-ignore
            await this.actions.checkUserCurrentJorney();
            //@ts-ignore
            this.actionsSearch.openCreateAutomaticListModal();
            this.$emit('completeUpdateInfo', {
                confirmation_code,
                ...this.data,
                whatsapp_validate_speedio_team: paramsRoute,
            });
            this.$emit('openExportModal', true);
            this.showModal = false;
            this.validateErrorWhatsappNumber = false;
        }
        if (tryUpdateUser.status == 422) {
            this.errorToken = true;
            this.validateErrorWhatsappNumber = true;
            this.phoneValidationError();
        }
    }
    async goBack(changed) {
        this.showPhoneValidation = false;
        this.validateErrorWhatsappNumber = true;
        this.errorToken = true;
        if (changed) {
            this.changeTokenPhrase = false;
            this.hideBtns = false;
        }
    }
    handleAlertMessage(message) {
        this.alertMessage = message;
    }
    handleBtnLoading(state) {
        this.btnLoading = state;
    }
    phoneValidationError() {
        if (this.hideBtns == true) {
            this.changeTokenPhrase = true;
        }
        this.hideBtns = true;
        this.handleAlertMessage('Ops! O token digitado está incorreto. Digite novamente ou clique em <b>“Reenviar token de validação”</b>.');
        this.btnLoading = false;
        this.hideBtns = true;
    }
    closeModal() {
        this.showModal = false;
    }
};
__decorate([
    PropSync('open', { default: false })
], OnboardingUpdateInfoContent.prototype, "showModal", void 0);
__decorate([
    Emit('closeModal')
], OnboardingUpdateInfoContent.prototype, "closeModal", null);
OnboardingUpdateInfoContent = __decorate([
    Component(connect(({ actions, state }) => ({
        actions: actions.users,
        actionsSearch: actions.search,
        userState: state.users,
    }), {
        components: {
            UpdateForm,
            ValidateForm,
        },
    }))
], OnboardingUpdateInfoContent);
export default OnboardingUpdateInfoContent;
