var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-expansion-item',{staticClass:"border-bottom",attrs:{"group":"legal","expand-separator":"","expand-icon-class":"q-pr-none","data-cy":"search-advanced-filters-namber-of-complaints-body","header-class":"text-grey-7"},on:{"hide":_vm.resetValidation,"show":_vm.handleTrackingRACS},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_c('span',{staticClass:"font-12 text-primary text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.selectedInfo)}}),(_vm.hasError)?_c('q-icon',{staticClass:"q-pl-sm",attrs:{"color":"red-6","name":"icon-info-new","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v("\n            Este filtro não está selecionado corretamente! "),_c('br'),_vm._v("\n            Corrija seus valores ou resete o filtro.\n          ")])])],1):_vm._e(),(_vm.info)?_c('q-icon',{staticClass:"q-pl-sm",attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[(_vm.info)?_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.info)+" ")])]):_vm._e()],1):_vm._e()],1)]},proxy:true}])},[_c('div',{staticClass:"col q-mb-md"},[_c('div',{staticClass:"row items-center q-ma-md"},[_c('div',{staticClass:"col-5"},[_c('Input',{ref:"inputBeginValue",attrs:{"type":"number","placeholder":"Valor inicial","dense":"","min":"0","debounceTime":"800","rules":[
            function (val) { return val || val === 0 || 'Este valor não pode ser vazio'; },
            function (val) { return val <= 10 || 'Este valor não pode ser maior que 10'; },
            function (val) { return val <= _vm.inputValues.value_end ||
              'Este valor deve ser menor ou igual ao valor final'; },
            function (val) { return val >= 0 || 'Este valor não pode ser menor que 0'; } ]},model:{value:(_vm.inputValues.value_start),callback:function ($$v) {_vm.$set(_vm.inputValues, "value_start", _vm._n($$v))},expression:"inputValues.value_start"}})],1),_c('div',{staticClass:"col-2 text-center"},[_c('p',{staticClass:"q-ma-none"},[_vm._v("até")])]),_c('div',{staticClass:"col-5"},[_c('Input',{ref:"inputEndValue",attrs:{"placeholder":"Valor final","type":"number","min":"0","debounceTime":"800","dense":"","rules":[
            function (val) { return val || val === 0 || 'Este valor não pode ser vazio'; },
            function (val) { return val <= 10 || 'Este valor não pode ser maior que 10'; },
            function (val) { return val >= _vm.inputValues.value_start ||
              'Este valor deve ser maior ou igual ao valor inicial'; },
            function (val) { return val >= 0 || 'Este valor não pode ser menor que 0'; } ]},model:{value:(_vm.inputValues.value_end),callback:function ($$v) {_vm.$set(_vm.inputValues, "value_end", _vm._n($$v))},expression:"inputValues.value_end"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }