var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import OnboardingUpdateInfo from '@/components/SignUp/UpdateUserPreferences/OnboardingUpdateInfoContent.vue';
import Tooltip from '@/components/Tooltip.vue';
import SearchResultsFilters from '@/modules/search/components/SearchResultsFilters.vue';
import CompanyDetailsContainer from '@/modules/search/containers/CompanyDetails.container.vue';
import PageAllFiltersSelectedContainer from '@/modules/search/containers/PageAllFiltersSelected.container.vue';
import SearchHistoryDrawerContainer from '@/modules/search/containers/SearchHistoryDrawer.container.vue';
import SearchStatsBodyContainer from '@/modules/search/containers/SearchStatsBody.container.vue';
import SimplePageSelectedFiltersContainer from '@/modules/search/containers/SimplePageSelectedFilters.container.vue';
import StartNewSearchPageContainer from '@/modules/search/containers/StartNewSearchPage.container.vue';
import { connect } from '@/overmind';
import { ActiveModal } from '@/overmind/search/state';
import Jivo from '@/shared/utils/JivoChat.utils';
import { sum } from 'lodash';
import SearchNotFoundHeader from 'modules/search/components/SearchNotFoundHeader.vue';
import { json } from 'overmind';
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import SearchNotFoundResults from '../components/SearchNotFoundResults.vue';
import AdvancedFilterContainer from '../containers/AdvancedFilter.container.vue';
let SearchBodyContainer = class SearchBodyContainer extends Vue {
    constructor() {
        super(...arguments);
        this.emptyText = 'Não encontramos empresas com os critérios selecionados';
        this.noCreditsText = 'Ops você não possui créditos suficientes';
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.dispose = () => { };
        // @ts-ignore
        this.hasNoCredits = this.noCredits;
        // @ts-ignore
        this.avoidShowWarning = this.silentWarningCredits;
        // @ts-ignore
        this.searchStatus = this.createSearchState;
        this.showAllSelectedFilters = false;
        this.offsetWidth = 0;
        this.filtersSelectedOffsetHeight = 57;
        this.filtersSelected = 0;
        this.openModalUpgradeAccount = false;
        this.storeCNPJ = '';
    }
    remountHeaderKey() { }
    totalSelectedFilters() {
        // @ts-ignore TS2349: property inexistent
        return json(this.totalSelected);
    }
    get userPlgOrigin() {
        //@ts-ignore
        return !this.actionsUsers.getAuthPermissions();
    }
    showStartNewPage() {
        return (
        // @ts-ignore TS2349: property inexistent
        this.companies.length <= 0 &&
            // @ts-ignore TS2349: property inexistent
            this.emptyReturn &&
            // @ts-ignore TS2349: property inexistent
            this.createSearchState === 'received' &&
            // @ts-ignore TS2349: property inexistent
            !this.noCredits);
    }
    selectCompany({ company, selected, }) {
        //@ts-ignore
        this.actionsSearch.exportations.setCompanyToExport({ company, selected });
    }
    setPageNumber(pageNumber) {
        // @ts-ignore TS2349: property inexistent
        if (pageNumber !== this.page) {
            // @ts-ignore TS2349: property inexistent
            if (this.isConsultHistory) {
                // @ts-ignore TS2349: property inexistent
                this.actionsSearch.searches.getConsultHistory(pageNumber);
            }
            else {
                // @ts-ignore TS2349: property inexistent
                this.actionsSearch.searches.createSearch({ page: pageNumber });
            }
        }
    }
    getCountTotalSelected() {
        return sum(
        //@ts-ignore
        Object.keys(this.totalSelected).map(
        //@ts-ignore
        item => this.totalSelected[item].total));
    }
    async createNewSearch() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setEmptyReturn(false);
        // @ts-ignore TS2349: property inexistent
        // this.actionsSearch.setShowCompany(false)
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputSearchTerm('');
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setMultiMatch('');
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        // @ts-ignore TS2349: property inexistent
        if (this.currentSearchId) {
            this.$router
                .push({
                name: 'searchId',
                // @ts-ignore TS2349: property inexistent
                params: { searchId: this.currentSearchId },
            })
                .catch(err => err);
        }
    }
    getUserHasUpdatedAccount(cnpj, userJourney, btnClicked) {
        this.storeCNPJ = cnpj;
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.handleActiveModal({
            modal: ActiveModal.updateAccountDataModal,
        });
        const didFirstSearch = userJourney &&
            userJourney.user_journey.includes('primeira_busca_realizada');
        const userPlg = this.userPlgOrigin;
        const cardCompanyClicked = btnClicked;
        if (userJourney.is_new_account && !didFirstSearch && userPlg) {
            if (!cardCompanyClicked) {
                setTimeout(() => {
                    //@ts-ignore TS2349: property inexistent
                    this.openModalUpgradeAccount = true;
                }, 1000);
            }
            else {
                this.openModalUpgradeAccount = true;
            }
            return;
        }
        else if (cardCompanyClicked) {
            this.newOpenCompanyDrawer();
            this.openModalUpgradeAccount = false;
        }
    }
    async newOpenCompanyDrawer() {
        // @ts-ignore TS2349: property inexistent
        // await this.actionsUsers.checkUserCurrentJorney()
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.handleActiveModal({ modal: '' });
        // @ts-ignore TS2349: property inexistent
        await this.actionsCompanies.resetCurrentCompany();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.setCNPJCurrentCompany(this.storeCNPJ);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowCompany(true);
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.getCompanySearch(this.storeCNPJ);
        // @ts-ignore TS2349: property inexistent
        this.actionsCompanies.setHyperbolicData({ nodes: [], links: [] });
        // @ts-ignore TS2349: property inexistent
        if (this.currentCompanyCNPJ) {
            // @ts-ignore TS2349: property inexistent
            await this.actionsCompanies.getCompanyHyperbolicData(
            // @ts-ignore TS2349: property inexistent
            { cnpj: this.currentCompanyCNPJ });
        }
        //@ts-ignore
        const data = await this.actionsUsers.getUserCreditsPercentage();
        //@ts-ignore
        await this.actionsUsers.updateAccountCredits(data);
    }
    async onRouteChanged(to, from) {
        const { searchId: nextId } = to.params;
        // @ts-ignore TS2349: property inexistent
        if (nextId && !this.searchByInteraction) {
            // @ts-ignore TS2349: property inexistent
            await this.actionsSearch.searches.getSearch({ id: nextId });
        }
        if (to.path === '/search') {
            this.resetSearch();
        }
    }
    onHasNoCreditsChange(curr, old) {
        if (curr && !this.avoidShowWarning) {
            // @ts-ignore
            window.jivo_api.open();
            // @ts-ignore
            let noCreditsMessage = document.createTextNode('Seus créditos (pra fazer exportação) acabaram. Se quiser exportar mais, me envie aqui "exportar mais"');
            let welcomeDiv = Jivo.getWelcomeMsg(noCreditsMessage.textContent);
            // @ts-ignore
            this.actionsSearch.setSilentWarningCredits(true);
        }
    }
    closeAllSelectedFilters(val) {
        this.showAllSelectedFilters = val;
    }
    openAllPageSelectedFilters(val) {
        this.showAllSelectedFilters = val;
    }
    async resetAllFilters() {
        this.showAllSelectedFilters = false;
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.resetStateFilters('');
        // @ts-ignore TS2349: property inexistent
        await this.actionsFilters.getFilters();
    }
    async resetSearch() {
        // @ts-ignore
        this.actionsSearch.resetStateSearch();
        // @ts-ignore
        await this.actionsFilters.getFilters();
        // @ts-ignore
        await this.actionsSearch.searches.getSummary();
    }
    setOffsetHeight(filterHeightOffset) {
        this.filtersSelectedOffsetHeight = filterHeightOffset;
    }
    async mounted() {
        // @ts-ignore
        this.offsetWidth =
            this.$refs &&
                this.$refs.containerSelectedFilters && // @ts-ignore
                // @ts-ignore
                this.$refs.containerSelectedFilters.offsetWidth
                ? //
                    // @ts-ignore
                    this.$refs.containerSelectedFilters.offsetWidth
                : 0;
        // @ts-ignore
        if (this.noCredits && !this.silentWarningCredits) {
            // @ts-ignore
            window.jivo_api.open();
            let noCreditsMessage = document.createTextNode('Seus créditos (pra fazer exportação) acabaram. Se quiser exportar mais, me envie aqui "exportar mais"');
            let welcomeDiv = Jivo.getWelcomeMsg(noCreditsMessage.textContent);
            // @ts-ignore
            this.actionsSearch.setSilentWarningCredits(true);
        }
    }
    beforeDestroy() {
        this.dispose();
        // @ts-ignore
        if (window.jivo_api) {
            // @ts-ignore
            window.jivo_api.close();
        }
        const jivoContainer = Jivo.getJivoContainer();
        let welcomeDiv = null;
        if (jivoContainer) {
            welcomeDiv = jivoContainer.querySelector('#jivo-container--welcome');
            if (welcomeDiv)
                welcomeDiv.remove();
        }
    }
    toggleAdvancedFilterModal(val) {
        const { searchId } = this.$route.params;
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.showAdvancedFilterModal({
            show: val,
            searchId,
            clickedBtn: val,
        });
    }
};
__decorate([
    Emit('remountHeaderKey')
], SearchBodyContainer.prototype, "remountHeaderKey", null);
__decorate([
    Watch('$route')
], SearchBodyContainer.prototype, "onRouteChanged", null);
__decorate([
    Watch('hasNoCredits')
], SearchBodyContainer.prototype, "onHasNoCreditsChange", null);
SearchBodyContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        page: state.search.page,
        filterGroups: state.filters.filterGroups,
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
        actionsCompanies: actions.companies,
        actionsUsers: actions.users,
        companies: state.search.companies,
        totalPage: state.search.totalPage,
        noCredits: state.search.noCredits,
        silentWarningCredits: state.search.silentWarningCredits,
        emptyReturn: state.search.emptyReturn,
        createSearchState: state.search.createSearchState,
        showHistory: state.search.showHistory,
        currentCompany: state.search.currentCompany,
        currentCompanyCNPJ: state.search.currentCompanyCNPJ,
        currentSearchId: state.search.currentSearchId,
        searchByInteraction: state.search.searchByInteraction,
        searchTerm: state.filters.inputSearchTerm,
        companiesToExport: state.search.companiesToExport,
        totalSelected: state.filters.totalSelected,
        companyStatus: state.search.companyStatus,
        showAdvancedFilterModal: state.search.showAdvancedFilterModal,
        canSearch: state.search.canSearch,
        isConsultHistory: state.search.isConsultHistory,
        activeModal: state.search.activeModal,
        userJourney: state.users.userJourney,
        updateDataPopUp: state.search.updateDataPopUp,
    }), {
        components: {
            StartNewSearchPageContainer,
            SearchStatsBodyContainer,
            CompanyDetailsContainer,
            SearchHistoryDrawerContainer,
            SearchResultsFilters,
            SearchNotFoundHeader,
            AdvancedFilterContainer,
            SearchNotFoundResults,
            PageAllFiltersSelectedContainer,
            Tooltip,
            SimplePageSelectedFiltersContainer,
            OnboardingUpdateInfo,
        },
        async created() {
            this.dispose = this.overmind.reaction(({ search: state }) => [state.noCredits, state.silentWarningCredits], data => {
                this.hasNoCredits = data[0];
                this.avoidShowWarning = data[1];
            });
            // this.actionsFilters.getFilters();
            const { searchId: id } = this.$route.params;
            let searchIdOrFindByCnpj = id;
            if (id && !this.searchByInteraction) {
                // @ts-ignore TS2349: property inexistent
                await this.actionsSearch.searches.getSearch({
                    id: searchIdOrFindByCnpj,
                });
                const cnpj = this.$route.query.cnpj;
                if (cnpj) {
                    this.storeCNPJ = cnpj;
                    await this.newOpenCompanyDrawer();
                }
            }
        },
    }))
], SearchBodyContainer);
export default SearchBodyContainer;
