var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import StartNewSearchPage from '@/modules/search/components/StartNewSearchPage.vue';
// import { sum } from 'lodash';
import { connect } from '@/overmind';
import { sum } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
let StartNewSearchPageContainer = class StartNewSearchPageContainer extends Vue {
    async createSearch() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowCompany(false);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.stateSearch.currentSearchId },
        })
            .catch(err => err);
    }
    openAdvancedFilters() {
        this.$emit('openAdvancedFilters', true);
    }
    getCountTotalSelected() {
        return sum(
        // @ts-ignore
        Object.keys(this.totalSelected).map(
        // @ts-ignore
        item => this.totalSelected[item].total));
    }
    containerPadding() {
        return {
            paddingTop: this.getCountTotalSelected() ? '6.5rem' : '0',
        };
    }
};
StartNewSearchPageContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateSearch: state.search,
        companies: state.search.companies,
        stateFilters: state.filters,
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
        emptyReturn: state.search.emptyReturn,
        filterState: state.filters.filterState,
        filterGroups: state.filters.filterGroups,
        totalSelected: state.filters.totalSelected,
        inputSearchTerm: state.filters.inputSearchTerm,
        createSearchState: state.search.createSearchState,
    }), {
        components: {
            StartNewSearchPage,
        },
    }))
], StartNewSearchPageContainer);
export default StartNewSearchPageContainer;
