var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CompanyStatus } from '@/overmind/search/state';
let CompanyInformationAvailable = class CompanyInformationAvailable extends Vue {
    constructor() {
        super(...arguments);
        this.showApplets = true;
    }
    get marketDecisors() {
        let arrayDecisors = Object.entries(
        // @ts-ignore TS2349: property inexistent
        this.company.availableInformation.decision_makers);
        return arrayDecisors;
    }
    get countDecisorsMarkets() {
        let count = 0;
        // @ts-ignore TS2349: property inexistent
        for (let i = 0; i < this.marketDecisors.length; i++) {
            // @ts-ignore TS2349: property inexistent
            count = count + this.marketDecisors[i][1];
        }
        return count;
    }
    get fetching() {
        return this.companyStatus === CompanyStatus.FETCHING;
    }
    hasAvailableInformation() {
        return (this.company &&
            this.company.availableInformation &&
            this.company.availableInformation.contact &&
            this.company.availableInformation.additional_information);
    }
};
__decorate([
    Prop({ default: '' })
], CompanyInformationAvailable.prototype, "infosetId", void 0);
__decorate([
    Prop({ default: '' })
], CompanyInformationAvailable.prototype, "companyId", void 0);
__decorate([
    Prop({ default: () => { } })
], CompanyInformationAvailable.prototype, "company", void 0);
__decorate([
    Prop({ default: false })
], CompanyInformationAvailable.prototype, "noCreditsToGetCompany", void 0);
__decorate([
    Prop({ default: CompanyStatus.INITIAL })
], CompanyInformationAvailable.prototype, "companyStatus", void 0);
CompanyInformationAvailable = __decorate([
    Component({
        name: 'CompanyInformationAvailable',
    })
], CompanyInformationAvailable);
export default CompanyInformationAvailable;
