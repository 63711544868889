var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
let Slider = class Slider extends Vue {
    change(e) {
        return e;
    }
};
__decorate([
    PropSync('value')
], Slider.prototype, "valueSlider", void 0);
__decorate([
    Prop({ default: undefined })
], Slider.prototype, "data", void 0);
__decorate([
    Prop({ default: true })
], Slider.prototype, "marks", void 0);
__decorate([
    Prop({ default: '' })
], Slider.prototype, "height", void 0);
__decorate([
    Prop({ default: '' })
], Slider.prototype, "width", void 0);
__decorate([
    Prop({ default: 'none' })
], Slider.prototype, "tooltip", void 0);
__decorate([
    Prop({ default: 0 })
], Slider.prototype, "min", void 0);
__decorate([
    Prop({ default: 100 })
], Slider.prototype, "max", void 0);
__decorate([
    Prop({ default: () => { } })
], Slider.prototype, "railStyle", void 0);
__decorate([
    Prop({ default: () => { } })
], Slider.prototype, "dotStyle", void 0);
__decorate([
    Prop({ default: () => { } })
], Slider.prototype, "labelActiveStyle", void 0);
__decorate([
    Emit('change')
], Slider.prototype, "change", null);
Slider = __decorate([
    Component({
        name: 'Slider',
    })
], Slider);
export default Slider;
