var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { mixpanelTracking } from '@/services/mixpanel';
import { Component, Emit, Vue } from 'vue-property-decorator';
let StartNewSearchPage = class StartNewSearchPage extends Vue {
    openAdvancedFilters() {
        mixpanelTracking('filtros avnçados: Abriu os filtros avançados');
        return true;
    }
};
__decorate([
    Emit('openAdvancedFilters')
], StartNewSearchPage.prototype, "openAdvancedFilters", null);
StartNewSearchPage = __decorate([
    Component
], StartNewSearchPage);
export default StartNewSearchPage;
