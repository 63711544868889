var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-expansion-item',{staticClass:"border-bottom",attrs:{"data-cy":(_vm.context + "-filters-multiple-choice"),"group":_vm.context,"expand-separator":"","expand-icon-class":"q-pr-none"},on:{"show":function($event){return _vm.handleTrackingMultipleChoiceFilter(_vm.filter.title)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12 text-grey-7",attrs:{"data-cy":"complaints-filter-title"}},[_vm._v("\n        "+_vm._s(_vm.filter.title)+"\n      ")]),(_vm.selectedCount(_vm.filter) === 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n        ("+_vm._s(_vm.selectedCount(_vm.filter))+" selecionado)\n      ")]):(_vm.selectedCount(_vm.filter) > 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n        ("+_vm._s(_vm.selectedCount(_vm.filter))+" selecionados)\n      ")]):_vm._e(),(_vm.filter.info)?_c('q-icon',{attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[(_vm.filter.info)?_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',{staticClass:"font-12"},[_vm._v(" "+_vm._s(_vm.filter.info)+" ")])]):_vm._e()],1):_vm._e()],1)]},proxy:true}])},[_c('q-card',[_c('q-card-section',{staticClass:"q-pb-xs q-pt-sm"},[_c('div',[(
            ((_vm.fullTextTerm === '' || _vm.fullTextTerm == null) &&
              _vm.filter.filterOptions.length > 5) ||
            _vm.searchTerm
          )?_c('div',{staticClass:"col"},[_c('Input',{ref:"searchInput",staticClass:"q-mb-sm",attrs:{"data-cy":"complaints-filters-search-input","dense":"","rounded":"","outlined":"","placeholder":"Digite aqui...","debounceTime":200},on:{"input":function($event){return _vm.onSearchFilters(_vm.filter, $event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(_vm.filter.queryType === 'multiSelect')?_c('div',{staticClass:"q-mb-sm"},[_c('q-virtual-scroll',{staticClass:"overflow-auto",staticStyle:{"max-height":"160px"},attrs:{"items":_vm.filterOptions,"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('div',{key:index,staticClass:"col"},[_c('q-checkbox',{attrs:{"data-cy":"complaints-multiple-choice-options-item","label":item.label,"value":item.isSelected},on:{"input":function($event){return _vm.setCheckbox(
                      _vm.filterGroupId,
                      _vm.filter.id,
                      null,
                      index,
                      $event,
                      item.value
                    )}}}),(item.info)?_c('span',{staticClass:"q-ml-sm"},[_c('q-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(_vm._s(item.info))])])],1)],1):_vm._e()],1)]}}],null,false,2003121396)})],1):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }