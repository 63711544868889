var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchStatus } from '@/overmind/search/state';
let WithEmails = class WithEmails extends Vue {
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], WithEmails.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: '' })
], WithEmails.prototype, "withEmails", void 0);
__decorate([
    Prop({ default: 1 })
], WithEmails.prototype, "page", void 0);
WithEmails = __decorate([
    Component({
        components: {},
    })
], WithEmails);
export default WithEmails;
