var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import Revenue from '@/modules/search/components/filterPanels/PortePanel/Revenue.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
import { SearchStatus } from '@/overmind/search/state';
let RevenueContainer = class RevenueContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.stateSliderFiltersValues = json(this.stateSliderFilters);
        this.selectedRadioButton = json(
        //@ts-ignore
        this.selectedRadioButtonRevenue.revenues.queryField);
    }
    setInputsRangeFilter(inputsSelectedValues) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputRange(inputsSelectedValues);
    }
    setSelectedRadioButton(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setRadioButtonState(SearchStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setSelectedRadioButton(event);
    }
    setErrorSliderFilter(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setErrorSlider(event);
    }
    setSliderFilter(event) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setSliderFilters(event);
    }
};
__decorate([
    Prop({ default: () => { } })
], RevenueContainer.prototype, "filter", void 0);
RevenueContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        selectedRadioButtonRevenue: state.selectedRadioButton,
        stateSliderFilters: state.stateSliderFilters,
        selectedInputRange: state.selectedInputRange,
        errorSliderRevenue: state.errorSliderRevenue,
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
    }), {
        components: {
            Revenue,
        },
    }))
], RevenueContainer);
export default RevenueContainer;
