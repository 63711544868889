var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconEdit from '@/components/Icons/IconEdit.vue';
import IconChecked from '@/components/Icons/IconChecked.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { setNoShowModalDate } from '@/shared/utils/helpers';
import { connect } from '@/overmind';
let ListExportsModal = class ListExportsModal extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.haveNotFirstSearch = false;
        this.listName = '';
        this.errorCreateListMsg = 'Este nome já foi utilizado antes. Por favor corrija e tente novamente.';
        this.unexpectedErrorMsg = 'Houve um erro inesperado. Tente novamente ou contate o suporte.';
        this.notEnoughCharsMsg = 'Por favor, insira um nome para a lista.';
    }
    handleExportCreditsConsumed() {
        return (this.accountCredits.export.total - this.accountCredits.export.consumed);
    }
    createInfosetList() {
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'exportou_modal' });
        return this.listName;
    }
    close() {
        setNoShowModalDate('noShowCreateInfosetModal');
        this.$emit('handleActiveCreateAutomaticListModal');
        //@ts-ignore
        this.actions.openCreateAutomaticListModal();
        mixpanelTracking('internal export 2.0: Fechou exportação interna');
    }
    hasCompanies() {
        return this.companies && this.companies.length > 0;
    }
};
__decorate([
    Prop({ default: () => { } })
], ListExportsModal.prototype, "accountCredits", void 0);
__decorate([
    Prop({ default: false })
], ListExportsModal.prototype, "unexpectedError", void 0);
__decorate([
    Prop({ default: false })
], ListExportsModal.prototype, "errorCreateList", void 0);
__decorate([
    Prop({ default: false })
], ListExportsModal.prototype, "notEnoughChars", void 0);
__decorate([
    Prop({ default: false })
], ListExportsModal.prototype, "loading", void 0);
__decorate([
    Prop({ default: () => [] })
], ListExportsModal.prototype, "companies", void 0);
__decorate([
    Emit('createInfosetList')
], ListExportsModal.prototype, "createInfosetList", null);
ListExportsModal = __decorate([
    Component(connect(({ state, actions }) => ({
        showCreateAutomaticExportList: state.search.showCreateAutomaticExportListDerived,
        actions: actions.search,
    }), {
        name: 'ListExportsModal',
        components: {
            IconChecked,
            IconEdit,
        },
    }))
], ListExportsModal);
export default ListExportsModal;
