var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { deburr, uniqBy, isEmpty, escapeRegExp } from 'lodash';
import { json } from 'overmind';
import Input from 'components/Input.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let DecisionMakersPanel = class DecisionMakersPanel extends Vue {
    constructor() {
        super(...arguments);
        this.filterOptions = this.filters.map(f => f.filterOptions);
        this.radioButtonValue = this.radioButtonCnae;
        this.optionsFilteredType = ['OU', 'E'];
        this.selectFilteredType = '';
        this.filteredFilters = json(this.filters);
        this.inputText = json(this.nameSocio);
        this.currentFilterIndex = 0;
        this.searchTerm = '';
    }
    onSelectedFilteredType(curr, old) {
        mixpanelTracking(`Filtros avançados: marcou “${curr == '' ? 'OU' : curr}” na lógica dos filtros de departamento e nível do decisor`);
        if (curr != old)
            this.selectFilteredType = curr;
    }
    get department() {
        return json(this.filters)
            .find(f => f.queryField == 'decision_makers_lkd_m5.department')
            .filterOptions.map(f => {
            if (f && f.isSelected)
                return f.label;
        })
            .filter(f => f && f !== '');
    }
    get level() {
        return json(this.filters)
            .find(f => f.queryField == 'decision_makers_lkd_m5.level')
            .filterOptions.map(f => {
            if (f && f.isSelected)
                return f.label;
        })
            .filter(f => f && f !== '');
    }
    handlePreVisualization() {
        return this.department.length > 0 || this.level.length > 0;
    }
    onFilters(curr, old) {
        if (curr)
            this.filteredFilters = curr;
    }
    handleTitle(title) {
        const newTitle = title != 'Departamento do decisor'
            ? title
            : 'Departamento e nível do decisor';
        return newTitle;
    }
    handleFilteredFilters() {
        return this.filteredFilters.filter(f => f.queryField !== 'decision_makers_lkd_m5.level');
    }
    handleLevelDecisors() {
        return this.filters.filter(f => f.queryField == 'decision_makers_lkd_m5.level');
    }
    onFullTextSearchChange(curr, old) {
        this.searchTerm = this.fullTextTerm;
        this.filters.forEach(f => {
            this.onSearchFilters(f, this.fullTextTerm);
            this.currentFilterIndex += 1;
        });
        this.currentFilterIndex -= 1;
    }
    async onFilterIndexChange(curr, old) {
        this.filterOptions[curr] =
            this.filters[this.currentFilterIndex].filterOptions;
    }
    setInputFilter(event) {
        return event;
    }
    selectedCount(filter) {
        if (filter.queryType === 'multiSelect') {
            if (filter.queryField.match('department')) {
                const departmentCount = filter.filterOptions.filter(option => option.isSelected).length;
                const levelCount = this.filters
                    .find(f => f.queryField == 'decision_makers_lkd_m5.level')
                    .filterOptions.filter(f => f.isSelected).length;
                return departmentCount + levelCount;
            }
            return filter
                ? filter.filterOptions.filter(option => option.isSelected).length
                : 0;
        }
        if (filter.queryType === 'inputText') {
            return !isEmpty(this.inputText) ? 1 : 0;
        }
    }
    onSearchFilters(filter, event) {
        if (event == null) {
            this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
            return;
        }
        let filterOptions = json(this.filterOptions[this.currentFilterIndex].filter(o => {
            const deburedTerm = escapeRegExp(deburr(this.searchTerm));
            if (o.tags) {
                const debured = deburr(Array.isArray(o.tags) ? o.tags.join(', ') : [o.tags].join(', '));
                const deburedMatch = debured.match(new RegExp(deburedTerm, 'gi'));
                if (deburedMatch)
                    return deburedMatch;
            }
            const deburedLabel = o.label ? deburr(o.label) : undefined;
            return o.label && deburedLabel
                ? deburedLabel.match(new RegExp(deburedTerm, 'i'))
                : false;
        }));
        filterOptions = uniqBy(filterOptions, 'value');
        this.filteredFilters[this.currentFilterIndex].filterOptions =
            json(filterOptions);
    }
    onHideFilter(filterIndex) {
        if (this.fullTextTerm === '') {
            this.filteredFilters[filterIndex].filterOptions = json(this.filters[filterIndex].filterOptions);
        }
    }
    onShowFilter(filterIndex, filterTitle) {
        const trackingMessage = filterTitle == 'Departamento do decisor'
            ? 'Filtros avançados: expandiu filtro de departamento e nível do decisor'
            : 'Filtros avançados: Expandiu o grupo de filtros: ' + filterTitle;
        mixpanelTracking(trackingMessage);
        this.currentFilterIndex = filterIndex;
        this.searchTerm = '';
    }
    setCheckbox(filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId, filterName) {
        this.setCheckboxTracking(filterName, value, itemId);
        const option = this.filteredFilters[filterIndex].filterOptions.find(o => o.value === itemId);
        if (option) {
            this.filteredFilters[this.currentFilterIndex].filterOptions[filterOptionIndex].isSelected = value;
        }
        this.$emit('setCheckbox', filterGroupId, filterId, filterIndex, filterOptionIndex, value, itemId);
    }
    formatDecisionMakerLabel(label) {
        switch (label) {
            case 'Alto':
                return 'Alto: proprietários, diretores, sócios e relacionados';
            case 'Médio':
                return 'Médio: gerentes, coordenadores, líderes e relacionados';
            case 'Baixo':
                return 'Baixo: analistas, assistentes, estagiários e relacionados';
            case 'Não classificado':
                return 'Não classificado';
            default:
                return label;
        }
    }
    setCheckboxTracking(filterName, value, itemId) {
        const filterMessage = filterName == 'departamento'
            ? 'no departamento do decisor'
            : 'no nível do decisor';
        const markOrDismark = value ? 'marcou' : 'desmarcou';
        if (itemId) {
            mixpanelTracking(`Filtros avançados: ${markOrDismark} “${itemId}” ${filterMessage} `);
        }
    }
    setSelectFilteredType(value) {
        this.selectFilteredType = value;
        mixpanelTracking(`Filtros avançados: marcou “${value == '' ? 'OU' : value}” na lógica dos filtros de departamento e nível do decisor`);
        return value;
    }
    setSelectedFilteredType() {
        return this.selectedFilteredType;
    }
    setSelectedRadioButton(queryField, from) {
        return { queryField, from };
    }
    beforeDestroy() {
        this.filteredFilters[this.currentFilterIndex].filterOptions = json(this.filterOptions[this.currentFilterIndex]);
    }
    mounted() {
        this.selectFilteredType = this.selectedFilteredType;
        this.handlePreVisualization();
        this.searchTerm = this.fullTextTerm;
        if (this.fullTextTerm !== '') {
            this.filters.forEach(f => {
                this.onSearchFilters(f, this.fullTextTerm);
                this.currentFilterIndex += 1;
            });
            this.currentFilterIndex -= 1;
        }
    }
};
__decorate([
    Prop({ default: () => [] })
], DecisionMakersPanel.prototype, "filters", void 0);
__decorate([
    Prop({ default: '' })
], DecisionMakersPanel.prototype, "radioButtonCnae", void 0);
__decorate([
    Prop({ default: 1 })
], DecisionMakersPanel.prototype, "filterGroupId", void 0);
__decorate([
    Prop({ default: '' })
], DecisionMakersPanel.prototype, "fullTextTerm", void 0);
__decorate([
    Prop({ default: '' })
], DecisionMakersPanel.prototype, "nameSocio", void 0);
__decorate([
    Prop({ default: '' })
], DecisionMakersPanel.prototype, "selectedFilteredType", void 0);
__decorate([
    Watch('selectedFilteredType')
], DecisionMakersPanel.prototype, "onSelectedFilteredType", null);
__decorate([
    Watch('filters', { deep: true })
], DecisionMakersPanel.prototype, "onFilters", null);
__decorate([
    Watch('fullTextTerm')
], DecisionMakersPanel.prototype, "onFullTextSearchChange", null);
__decorate([
    Watch('currentFilterIndex')
], DecisionMakersPanel.prototype, "onFilterIndexChange", null);
__decorate([
    Emit('setInputFilter')
], DecisionMakersPanel.prototype, "setInputFilter", null);
__decorate([
    Emit('setSelectFilteredType')
], DecisionMakersPanel.prototype, "setSelectFilteredType", null);
__decorate([
    Emit('setSelectedFilteredType')
], DecisionMakersPanel.prototype, "setSelectedFilteredType", null);
__decorate([
    Emit('setSelectedRadioButton')
], DecisionMakersPanel.prototype, "setSelectedRadioButton", null);
DecisionMakersPanel = __decorate([
    Component({
        name: 'DecisionMakersPanel',
        components: {
            Input,
        },
    })
], DecisionMakersPanel);
export default DecisionMakersPanel;
