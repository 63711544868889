var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import DistanceFromALocation from '@/modules/search/components/filterPanels/LocalizationPanel/DistanceFromALocation.vue';
import { connect } from '@/overmind';
import { SearchCepStatus } from '@/overmind/filters/state';
import { json } from 'overmind';
let DistanceFromALocationContainer = class DistanceFromALocationContainer extends Vue {
    async searchCep(cep, distance) {
        const zip = json(typeof cep === 'object' ? cep.zip : cep);
        const zipDistance = json(typeof cep === 'object' ? cep.distance : distance);
        // @ts-ignore TS2349: property inexistent
        this.actions.setSearchCepStatus(SearchCepStatus.RECEIVING);
        // @ts-ignore TS2349: property inexistent
        await this.actions.setSliderFilters({
            queryField: 'cep_distance',
            groupTagFilter: 'distância',
            zip,
            distance: zipDistance,
        });
        // @ts-ignore TS2349: property inexistent
        await this.actions.searchCEP();
    }
    async clearFilter(value = '') {
        // @ts-ignore TS2349: property inexistent
        await this.actions.setSliderFilters({
            queryField: 'cep_distance',
            groupTagFilter: 'distância',
            zip: '',
            distance: '',
        });
        if (value == 'noAddress')
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.searches.buildPotentialResults();
    }
};
__decorate([
    Prop({ default: () => { } })
], DistanceFromALocationContainer.prototype, "filter", void 0);
DistanceFromALocationContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        selected: state.totalSelected.localization.distance,
        dataCep: state.dataCEP,
        searchCepStatus: state.searchCepStatus,
        actions: actions.filters,
        actionsSearch: actions.search,
        showDistanceSlider: state.showDistanceSlider,
        stateSliderFilters: state.stateSliderFilters,
    }), {
        components: {
            DistanceFromALocation,
        },
    }))
], DistanceFromALocationContainer);
export default DistanceFromALocationContainer;
