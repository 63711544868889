var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import CompanyDetails from '@/modules/search/components/CompanyDetails.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getSearchDrawerWidth } from '@/shared/utils/helpers';
import { SearchStatus } from '@/overmind/search/state';
import { connect } from '@/overmind';
import { isEmpty } from 'lodash';
let CompanyDetailContainer = class CompanyDetailContainer extends Vue {
    constructor() {
        super(...arguments);
        this.currentCompany = {};
        this.loadingDrawer = false;
    }
    created() {
        // @ts-ignore TS2349: property inexistent
        this.actions.setAdvancedDataState(SearchStatus.NOT_RECEIVED);
        // @ts-ignore TS2349: property inexistent
        if (this.showHistory) {
            // @ts-ignore TS2349: property inexistent
            this.actions.setShowHistory(false);
        }
    }
    mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
    }
    getWidth() {
        return getSearchDrawerWidth();
    }
    hasDecisorsData() {
        // @ts-ignore
        return !isEmpty(this.currentCompany.availableInformation.decision_makers);
    }
    hasAdditionalData() {
        return !isEmpty(
        // @ts-ignore
        this.currentCompany.availableInformation.additional_information);
    }
    async searchCompanyCEP(cep) {
        this.loadingDrawer = true;
        this.setStateFiltersInLocalStorage();
        this.cleanFilters();
        // @ts-ignore
        await this.filtersActions.getFiltersbyDrawer();
        cep = cep.replace(/\D/g, '');
        // @ts-ignore
        this.filtersActions.setSelectedFilterCep({ value: cep, type: 'add' });
        await this.redirectSearch();
        // @ts-ignore
        await this.filtersActions.restoreStateFilters();
        await this.unsetStateFiltersInLocalStorage();
        this.loadingDrawer = false;
    }
    async searchCompanyCNAE(event) {
        this.loadingDrawer = true;
        this.setStateFiltersInLocalStorage();
        this.cleanFilters();
        // @ts-ignore
        await this.filtersActions.getFiltersbyDrawer();
        // @ts-ignore
        await this.filtersActions.setSelectedCheckboxByDrawer({
            groupTag: 'atividade',
            groupTagFilter: 'setores e cnaes',
            queryField: event.type,
            label: event.label,
            value: event.activity,
        });
        // @ts-ignore
        this.filtersActions.setSelectedRadioButtonByDrawer(event.type);
        await this.redirectSearch();
        // @ts-ignore
        await this.filtersActions.restoreStateFilters();
        await this.unsetStateFiltersInLocalStorage();
        this.loadingDrawer = false;
    }
    async searchCompanyCNPJ(cnpj) {
        this.loadingDrawer = true;
        await this.setStateFiltersInLocalStorage();
        this.cleanFilters();
        // @ts-ignore
        await this.filtersActions.getFiltersbyDrawer();
        cnpj = cnpj.replace(/\D/g, '');
        // // @ts-ignore
        sessionStorage.setItem('filters_cnpj', JSON.stringify([cnpj]));
        await this.redirectSearch();
        // @ts-ignore
        await this.filtersActions.restoreStateFilters();
        // @ts-ignore
        await this.filtersActions.restoreStateFilters();
        await this.unsetStateFiltersInLocalStorage();
        this.loadingDrawer = false;
    }
    async setStateFiltersInLocalStorage() {
        // @ts-ignore
        //localStorage.setItem('stateFilters', JSON.stringify(this.filters))
        await this.filtersActions.setStateFiltersInStorage();
    }
    async unsetStateFiltersInLocalStorage() {
        // @ts-ignore
        await this.filtersActions.unsetStateFiltersInStorage();
    }
    cleanFilters() {
        // @ts-ignore
        this.filtersActions.resetStateFilters('');
    }
    async redirectSearch() {
        // @ts-ignore
        const id = await this.actions.searches.createSearchbyDrawer({});
        // @ts-ignore
        window.open(`${window.location.origin}/search/${id}`, '_blank');
    }
    closeCompanyDrawer() {
        // @ts-ignore TS2349: property inexistent
        this.companiesActions.setHyperbolicData({ nodes: [], links: [] });
        // @ts-ignore TS2349: property inexistent
        this.actions.setShowCompany(false);
        // @ts-ignore TS2349: property inexistent
        this.actions.setAdditionalDataState(SearchStatus.NOT_RECEIVED);
        // @ts-ignore TS2349: property inexistent
        this.actions.setNoCreditsToGetCompany(false);
        // @ts-ignore TS2349: property inexistent
        this.actions.resetCurrentCompany();
    }
    async openModalExport() {
        // @ts-ignore TS2349: property inexistent
        await this.actions.getCredits();
        // @ts-ignore TS2349: property inexistent
        this.actions.exportations.modalExportOnlyCompany();
        //@ts-ignore
        await this.actionsInfosets.fetchAllInfosets({
            page: 1,
            aggregateToList: false,
            query: '',
        });
    }
    getCompanyCnpjsNextPage(page) {
        // @ts-ignore
        this.companiesActions.getCompanyCnpjs({
            // @ts-ignore
            cnpj: this.currentCompanyCNPJ,
            page,
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], CompanyDetailContainer.prototype, "hyperbolicData", void 0);
CompanyDetailContainer = __decorate([
    Component(connect(({ state: { search: state, filters: stateFilters, companies: stateCompanies, users: stateUsers, }, actions, }) => ({
        showCompany: state.showCompany,
        currentSearchId: state.currentSearchId,
        companies: state.companies,
        filters: stateFilters,
        currentCompany: stateCompanies.currentCompany,
        currentCompanyCNPJ: state.currentCompanyCNPJ,
        actions: actions.search,
        additionalDataState: state.additionalDataState,
        searchStatus: state.createSearchState,
        showHistory: state.showHistory,
        noCreditsToGetCompany: state.noCreditsToGetCompany,
        companiesActions: actions.companies,
        filtersActions: actions.filters,
        companyStatus: state.companyStatus,
        companiesState: stateCompanies,
        isConsultHistory: state.isConsultHistory,
        actionsInfosets: actions.infosets,
    }), { components: { CompanyDetails } }))
], CompanyDetailContainer);
export default CompanyDetailContainer;
