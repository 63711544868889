var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-expansion-item',{staticClass:"border-bottom",attrs:{"expand-separator":"","group":"localization","expand-icon-class":"q-pr-none","data-cy":"states-filter"},on:{"show":_vm.handleTrackingStates},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12 text-grey-7"},[_vm._v("\n        "+_vm._s(_vm.filterName)+"\n      ")]),(_vm.countItemsSelected() === 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n        ("+_vm._s(_vm.countItemsSelected())+" selecionado)\n      ")]):(_vm.countItemsSelected() > 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n        ("+_vm._s(_vm.countItemsSelected())+" selecionados)\n      ")]):_vm._e()])]},proxy:true}])},[_c('q-card',[_c('q-card-section',{staticClass:"column q-pb-xs q-pt-none"},[_c('div',{staticClass:"col"},[(
            ((_vm.fullTextTerm === '' || _vm.fullTextTerm == null) &&
              _vm.filterOptions.length > 5) ||
            _vm.searchTerm
          )?_c('div',{staticClass:"col"},[_c('Input',{staticClass:"q-mb-sm q-mt-sm",attrs:{"dense":"","rounded":"","outlined":"","placeholder":"Pesquise aqui...","data-cy":"states-search-input","debounceTime":200},on:{"input":function($event){return _vm.onSearchFilters(_vm.filteredFilters, $event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),_c('q-virtual-scroll',{staticClass:"overflow-auto",staticStyle:{"max-height":"240px"},attrs:{"items":_vm.filteredFilters,"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [_c('div',{key:index,staticClass:"col"},[_c('div',{staticClass:"row fit"},[_c('div',{staticClass:"col-auto",attrs:{"data-cy":"states-checkboxs"}},[_c('q-checkbox',{attrs:{"data-cy":"states-checkbox","label":item.label,"value":item.isSelected},on:{"input":function($event){return _vm.setStatesCheckbox(index, $event, item.value, item.label)}}})],1),_c('div',{staticClass:"col-grow self-center q-ml-md text-weight-thin text-grey-6"},[_c('span',{staticClass:"text-caption full-height"},[_vm._v(_vm._s(item.subline))])])])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }