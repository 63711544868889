var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import Filial from '@/modules/search/components/filterPanels/PortePanel/Filial.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
let FilialContainer = class FilialContainer extends Vue {
    constructor() {
        super(...arguments);
        //@ts-ignore
        this.selectedInputRangeValues = json(this.inputValues);
    }
    setInputsRangeFilter(inputsSelectedValues) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setInputRange({
            ...inputsSelectedValues,
            queryField: 'nu_filiais',
            groupTag: 'porte',
        });
    }
};
__decorate([
    Prop({ default: () => { } })
], FilialContainer.prototype, "filter", void 0);
FilialContainer = __decorate([
    Component(connect(({ state: { filters: state }, actions }) => ({
        selected: state.totalSelected.porte.branches,
        inputValues: state.selectedInputRange['nu_filiais'],
        actionsSearch: actions.search,
        actionsFilters: actions.filters,
    }), {
        components: {
            Filial,
        },
    }))
], FilialContainer);
export default FilialContainer;
